import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { getHomeHeader } from "../../../api/axios";
// const id = 2;

export default function HeaderView() {

  const { id } = useParams();

  const [header, setHeader] = useState([]);

  useEffect(() => {
    getHomeHeader(id)
      .then((response) => {
        setHeader(response.data.data[0]);
        console.log(response.data.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  return (
    <ContentWrapper>
      <HomeTitle>Header Section</HomeTitle>
      <TableWrapper>
        <Table>
          <thead>
            <TableRow>
              <TableHeader>Part Name</TableHeader>
              <TableHeader>Content</TableHeader>
            </TableRow>
          </thead>
          <TableBody>
            <TableRow>
              <TableCell>Header Paragraph - part 1</TableCell>
              <TableCell>{header.header_paragraph_1}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Header Paragraph - part 2</TableCell>
              <TableCell>{header.header_paragraph_2}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Header Paragraph - part 3</TableCell>
              <TableCell>{header.header_paragraph_3}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Header Paragraph - part 4</TableCell>
              <TableCell>{header.header_paragraph_4}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Header Image</TableCell>
              <TableCell>
                <img src={header.header_image} alt="header_img" width="400" />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableWrapper>
      <ButtonWrapper>
        <Link to="/home">
          <Button variant="secondary">Go Back</Button>
        </Link>
        <Link to={`/home/header/edit/${id}`}>
          <Button>Edit header section</Button>
        </Link>
      </ButtonWrapper>
    </ContentWrapper>
  );
}

const ContentWrapper = styled.div`
  padding-bottom: 30px;
  justify-content: center;
`;

const HomeTitle = styled.h3`
  text-align: center;
  padding: 10px 0;
`;

const TableWrapper = styled.div`
  margin: 0 auto;
  padding: 10px 0 0 50px;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 10px;
  border: 2px solid #ccc;
`;

const TableBody = styled.tbody``;

const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  padding-top: 40px;
  display: flex;
  gap: 5rem;
  justify-content: center;
`;
