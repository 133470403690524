import React, { useEffect, useState } from "react";
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {getForIndividualsStep} from "../../../api/axios";
const id = 1;

export default function ViewSteps() {

  const [data,setStep] = useState({
    step1:"",
    step2:"",
    step3:"",
    step4:"",
    index: null,
  });
  useEffect(() => {
    
    getForIndividualsStep().then((res) => {
        const stepData = res.data.data[0];
        setStep({ ...stepData, index: res.data.data.indexOf(stepData) });             
        console.log(stepData);                     
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

    return(
        <ContentWrapper>
            <HomeTitle>Step Cards Section</HomeTitle>
            <TableWrapper>
                <Table>
                    <thead>
                        <TableRow>
                            <TableHeader>Step Number</TableHeader>
                            <TableHeader>Content</TableHeader>
                        </TableRow>
                    </thead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Step 01</TableCell>
                            <TableCell>{data.step1}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Step 02 </TableCell>
                            <TableCell>{data.step2}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Step 03 </TableCell>
                            <TableCell> {data.step3}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Step 04 </TableCell>
                            <TableCell>{data.step4}</TableCell>
                        </TableRow>
                       
                    </TableBody>
                </Table>
            </TableWrapper>
            <ButtonWrapper>
                <Link to="/forindividuals">
                    <Button variant="secondary">Go Back</Button>
                </Link>
                
                <Link to={`/forindividuals/step/edit/${id}`}>
                    <Button>Edit steps section</Button>
                </Link>
            </ButtonWrapper>
        </ContentWrapper>
    );
}

const ContentWrapper = styled.div`
  padding-bottom: 30px;
  justify-content: center;
`;

const HomeTitle = styled.h3`
  text-align: center;
  padding: 10px 0;
`;

const TableWrapper = styled.div`
  margin: 0 auto;
  padding: 10px 0 0 50px;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 10px;
  border: 2px solid #ccc;
`;

const TableBody = styled.tbody``;

const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ddd; 
`;

const ButtonWrapper = styled.div`
    margin: 0 auto;
    padding-top: 40px;
    display: flex;
    gap: 5rem;
    justify-content: center;
`;
