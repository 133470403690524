import {React, useState,useEffect} from 'react';
import { getAllContacts} from '../../api/axios';
import Table from 'react-bootstrap/Table';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function Preview() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    getAllContacts().then((res) => {
      console.log(res);           
      const contactData = res.data.data;
      setData(contactData);            
      console.log(res.data.data[0].id);                     
    })
    .catch((err) => {
      console.log(err);
    });
  }, []);

  const handleEditClick = (id) => {
    navigate(`update/${id}`);
    console.log('clicked');
  }

  return (
    <>
    <Table>
      <thead>
        <tr>
          <th>Contact ID</th>
          <th>Phone number</th>
          <th>Mailing address</th>
          <th>Email address</th>
          <th>Header paragraph</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {data.map((contact) => (
          <tr key={contact.id}>
            <td>{contact.id}</td>
            <td>{contact.phoneNo}</td>
            <td>{contact.mailing_address}</td>
            <td>{contact.email_address}</td>
            <td>{contact.header_paragraph}</td>
            <td>
              <Button variant="primary" onClick={() => handleEditClick(contact.id)}>Edit</Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    </>
  );
}

export default Preview;