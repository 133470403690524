import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";

import styled from "styled-components";
import {
  getForBusinessImage,
  updateForBusinessImage,
} from "../../../api/axios";
const id = 1;

export default function HeaderImgEdit() {
  const [paragraph, setParagraph] = useState("");
  const [image, setImage] = useState("");
  const [data, setHeader] = useState({
    paragraph: "",
    image: "",
    index: null,
  });
  const inputRef = useRef(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();
  const [wordLimitExceeded, setWordLimitExceeded] = useState(false);

  useEffect(() => {
    getForBusinessImage()
      .then((res) => {
        console.log(res);
        // console.log(res.data.data[0]);
        setHeader(res.data.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleParagraphChange = (e) => {
    setParagraph(e.target.value);
    if (e.target.value.split(" ").length > 50 || e.target.value.length > 300) {
      setWordLimitExceeded(true);
    } else {
      setWordLimitExceeded(false);
    }
  };

  const SendHeaderData = (e) => {
    e.preventDefault();
    if (wordLimitExceeded) return;
    setSaving(true);

    var formData = new FormData();
    formData.append("paragraph", paragraph);

    if (image instanceof File) {
      formData.append("image", image);
    }

    updateForBusinessImage(formData)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setShowSuccessMessage(true);
          //alert("Header section has been updated successfully");
          // navigate(`/forbusiness/card/view/${id}`);
        } else {
          Promise.resolve();
        }
        e.target.reset();
        setSaving(false);
      })
      .catch((err) => {
        console.log(err);
        setSaving(false);
      });
  };

  return (
    <FormWrapper>
      <Form>
        <Title>Edit header section</Title>

        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label style={{ fontWeight: "bold" }}>Enter a new paragraph</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            placeholder={data.paragraph}
            defaultValue={data.paragraph}
            onChange={handleParagraphChange}
            isInvalid={wordLimitExceeded}
          />
          {wordLimitExceeded && (
            <Form.Control.Feedback type="invalid">
              Word limit exceeded.
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group
          controlId="formBasicName"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Form.Label style={{ fontWeight: "bold" }}>Select a new Image</Form.Label>

          <Form.Control
            type="file"
            accept="image/*"
            onChange={(e) => {
              setImage(e.target.files[0]);
            }}
            style={{ display: "none" }}
            ref={inputRef}
          />
          {image ? (
            <>
              <img
                style={{ Width: "40vw" }}
                src={URL.createObjectURL(image)}
                alt="header"
                onClick={() => {
                  setImage.current.click();
                }}
              />
              <br />
              <RemoveButtonWrapper>
                <Button variant="outline-danger" onClick={() => setImage(null)}>
                  Remove your image
                </Button>
              </RemoveButtonWrapper>
            </>
          ) : (
            <img
              style={{ maxWidth: "40vw", cursor: "pointer" }}
              src={data.image}
              alt=""
              onClick={() => {
                inputRef.current.click();
              }}
              onMouseEnter={(e) => {
                e.target.style.opacity = 0.8;
              }}
              onMouseLeave={(e) => {
                e.target.style.opacity = 1;
              }}
            />
          )}
        </Form.Group>

        <br />

        <ButtonWrapper>
          <Link to={`/forbusiness/headerimg/view/${id}`}>
            <Button variant="secondary">Go Back</Button>
          </Link>
            <Button
              variant="primary"
              type="submit"
              onClick={SendHeaderData}
              disabled={
                saving ||
              wordLimitExceeded
                
                }
            >
              {saving ? "Saving..." : "Save Changes"}
            </Button>
        </ButtonWrapper>
      </Form>
      {showSuccessMessage && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 100,
            }}
          ></div>
          <Alert
            variant="success"
            onClose={() => setShowSuccessMessage(false)}
            dismissible={false}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "25vw",
              height: "auto",
              zIndex: 1000,
            }}
          >
            <Alert.Heading
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "green",
                textAlign: "center",
              }}
            >
              Update Successfull!
            </Alert.Heading>

            <p style={{ textAlign: "center" }}>
              Your header section has been updated.
            </p>
            <br />
            <div className="d-flex justify-content-end">
              <Button
                variant="outline-success"
                onClick={() => {
                  setShowSuccessMessage(false);
                  navigate(`/forbusiness/headerimg/view/${id}`);
                }}
              >
                Ok
              </Button>
            </div>
          </Alert>
        </>
      )}
    </FormWrapper>
  );
}

const FormWrapper = styled.div`
  padding: 0rem 22rem 1rem 22rem;
`;

const Title = styled.h3`
  text-align: center;
  padding: 10px 0;
`;

const ButtonWrapper = styled.div`
 margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
`;
const RemoveButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 50%;
`;