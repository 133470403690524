import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getAllButton } from "../../api/axios";

export default function ButtonsView() {

  const [buttons, setButtons] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getAllButton()
      .then((response) => {
        console.log(response);
        setButtons(response.data.data);
        console.log(response.data.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <ContentWrapper>
      <ButtonsPageTitle>Buttons section</ButtonsPageTitle>
      <TableWrapper>
        <Table>
          <thead>
            <TableRow>
              <TableHeader>Page</TableHeader>
              <TableHeader>Position</TableHeader>
              <TableHeader>Button text</TableHeader>
              <TableHeader>Button link</TableHeader>
              <TableHeader>Edit Option</TableHeader>
            </TableRow>
          </thead>
          <TableBody>
            {buttons.map((button, index) => (
              <TableRow key={button.id}>
                <TableCell>{button.pageName}</TableCell>
                <TableCell>{button.pagePosition}</TableCell>
                <TableCell>{button.button_name}</TableCell>
                <TableCell>{button.buttonLink}</TableCell>
                <TableCell>
                  <Button 
                    variant="primary" 
                    style={{ margin: "0" }}
                    onClick={() => {
                        console.log("clicked");
                        navigate(`/buttons/update/${button.id}`)
                    }}
                  >
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
    </ContentWrapper>
  );
}

const ContentWrapper = styled.div`
  padding-bottom: 30px;
  justify-content: center;
`;

const ButtonsPageTitle = styled.h3`
  text-align: center;
  padding: 10px 0;
`;

const TableWrapper = styled.div`
  margin: 0 auto;
  padding: 10px 0 0 50px;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 10px;
  border: 2px solid #ccc;
`;

const TableBody = styled.tbody``;

const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`;

// const ButtonWrapper = styled.div`
//   margin: 0 auto;
// `;
