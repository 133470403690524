import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { getHomeParagraph, updateHomeParagraph } from "../../../api/axios";
import { Alert } from "react-bootstrap";

export default function ParagraphEdit() {
  const { id } = useParams();
  const [paragraph_image, setParagraphImage] = useState("");
  const [paragraph, setParagraph] = useState("");
  const [homeParagraph, setHomeParagraph] = useState({
    paragraph_image: "",
    paragraph: "",
    index: null,
  });
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [wordLimitExceeded, setWordLimitExceeded] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    getHomeParagraph(id)
      .then((response) => {
        const paragraphData = response.data.data[0];
        setHomeParagraph({
          ...paragraphData,
          index: response.data.data.indexOf(paragraphData),
        });
        console.log(response.data.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  const handleParagraphChange = (e) => {
    setParagraph(e.target.value);
    if (e.target.value.split(" ").length > 135 || e.target.value.length > 760) {
      setWordLimitExceeded(true);
    } else {
      setWordLimitExceeded(false);
    }
  };

  const SendParagraphData = (e) => {
    e.preventDefault();
    if (wordLimitExceeded) return;
    setSaving(true);

    var formData = new FormData();
    formData.append("paragraph", paragraph);

    if (paragraph_image instanceof File) {
      formData.append("paragraph_image", paragraph_image);
    }

    updateHomeParagraph(id, formData)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          //   alert("Paragraph has been updated successfully");
          setShowSuccessMessage(true);
          //   navigate(`/home/paragraph/view/${id}`);
        } else {
          Promise.resolve();
        }
        e.target.reset();
        setSaving(false);
      })
      .catch((err) => {
        console.log(err);
        setSaving(false);
      });
  };

  return (
    <FormWrapper>
      <Form>
        <Title>Edit paragraph section</Title>
        <Form.Group
          controlId="formBasicName"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Form.Label style={{ fontWeight: "bold" }}>
            Select an image
          </Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            onChange={(e) => {
              setParagraphImage(e.target.files[0]);
            }}
            style={{ display: "none" }}
            ref={inputRef}
          />
          {paragraph_image ? (
            <>
              <img
                style={{ maxWidth: "25vw" }}
                src={URL.createObjectURL(paragraph_image)}
                alt="paragraph_image"
                onClick={() => {
                  setParagraphImage.current.click();
                }}
              />
              <br />
              <RemoveButtonWrapper>
                <Button
                  variant="outline-danger"
                  onClick={() => {
                    setParagraphImage(null);
                  }}
                >
                  Remove your Image
                </Button>
              </RemoveButtonWrapper>
            </>
          ) : (
            <img
              style={{ maxWidth: "25vw", cursor: "pointer" }}
              src={homeParagraph.paragraph_image}
              alt="paragraph_image"
              onClick={() => {
                inputRef.current.click();
              }}
              onMouseEnter={(e) => {
                e.target.style.opacity = 0.8;
              }}
              onMouseLeave={(e) => {
                e.target.style.opacity = 1;
              }}
            />
          )}
        </Form.Group>
        <br />
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label style={{ fontWeight: "bold" }}>
            Enter a new paragraph
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={12}
            defaultValue={homeParagraph.paragraph}
            onChange={handleParagraphChange}
            isInvalid={wordLimitExceeded}
          />
          {wordLimitExceeded && (
            <Form.Control.Feedback type="invalid">
              Word limit exceeded.
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <ButtonWrapper>
          <Link to={`/home/paragraph/view/${id}`}>
            <Button variant="secondary">Go Back</Button>
          </Link>
          <Button
            variant="primary"
            type="submit"
            onClick={SendParagraphData}
            disabled={saving}
          >
            {saving ? "Saving..." : "Save Changes"}
          </Button>
        </ButtonWrapper>
      </Form>
      {showSuccessMessage && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 100,
            }}
          ></div>
          <Alert
            variant="success"
            onClose={() => setShowSuccessMessage(false)}
            dismissible={false}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "25vw",
              height: "auto",
              zIndex: 1000,
            }}
          >
            <Alert.Heading
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "green",
                textAlign: "center",
              }}
            >
              Update Successfull!
            </Alert.Heading>

            <p style={{ textAlign: "center" }}>
              Paragraph section has been updated.
            </p>
            <br />
            <div className="d-flex justify-content-end">
              <Button
                variant="outline-success"
                onClick={() => {
                  setShowSuccessMessage(false);
                  navigate(`/home/paragraph/view/${id}`);
                }}
              >
                Ok
              </Button>
            </div>
          </Alert>
        </>
      )}
    </FormWrapper>
  );
}

const FormWrapper = styled.div`
  padding: 0rem 22rem 1rem 22rem;
`;

const Title = styled.h3`
  text-align: center;
  padding: 10px 0;
`;

const RemoveButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 50%;
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
`;
