import React, { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { updateMeetTheTeam, getByMeetTheTeam } from "../../../api/axios";
import { Alert } from "react-bootstrap";

export default function MeetTeamEdit() {
  const { id } = useParams();
  const [pet1, setPet1] = useState("");
  const [name1, setName1] = useState("");
  const [position1, setPosition1] = useState("");
  const [image1, setImage1] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const inputRef1 = useRef(null);
  const [team, setTeam] = useState([]);

  useEffect(() => {
    getByMeetTheTeam(id)
      .then((res) => {
        setTeam(res.data.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleNameChange1 = (event) => {
    setName1(event.target.value);
  };

  const handlePositionChange1 = (event) => {
    setPosition1(event.target.value);
  };
  const handlePetChange1 = (event) => {
    setPet1(event.target.value);
  };

  const handleImageChange1 = (event) => {
    setImage1(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsUpdating(true);
    const formData = new FormData();
    formData.append("name", pet1);
    if (image1 instanceof File) {
      formData.append("image", image1);
    }
    formData.append("owner", name1);
    formData.append("position", position1);
    try {
      const response = await updateMeetTheTeam(id, formData);
      setShowSuccessMessage(true);
      console.log(response.data);
      setIsUpdating(false);
      
    } catch (error) {
      console.error(error);
      setIsUpdating(false);
    }
  };

  return (
    <FormWrapper>
      <Form onSubmit={handleSubmit}>
        <Title>Edit Meet the Team Section</Title>
        <SubTitle>Edit Members</SubTitle>
        <Row className="mb-3">
        <Col md={4}>
            <Form.Group controlId="MeetTheTeamControl">
              <Form.Label>
              </Form.Label>
              <Row>
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange1}
                  style={{ display: "none" }}
                  ref={inputRef1}
                />
                {image1 ? (
                  <>
                    <img
                      style={{ maxWidth: "13vw", borderRadius: "8px" }}
                      src={URL.createObjectURL(image1)}
                      alt="image1"
                      onClick={() => {
                        setImage1.current.click();
                      }}
                    />
                    <br />
                    <RemoveButtonWrapper>
                      <Button
                        variant="outline-danger"
                        onClick={() => {
                          setImage1(null);
                        }}
                      >
                        Remove your image
                      </Button>
                    </RemoveButtonWrapper>
                  </>
                ) : (
                  <img
                    style={{
                      maxWidth: "13vw",
                      cursor: "pointer",
                      borderRadius: "8px",
                    }}
                    src={team.image}
                    alt="image1"
                    onClick={() => {
                      inputRef1.current.click();
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.opacity = 0.8;
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.opacity = 1;
                    }}
                  />
                )}
              </Row>
            </Form.Group>
          </Col>
          
          <Col style={{ marginTop: "1.2vw" }}>
            <Form.Group  controlId="TeamPetName">
              <Form.Label>Pet Name</Form.Label>
              <Form.Control
                type="input"
                defaultValue={team.name}
                onChange={handlePetChange1}
              />
            </Form.Group>
       
            <Form.Group  controlId="TeamPetOwner">
              <Form.Label>Owner</Form.Label>
              <Form.Control
                type="input"
                defaultValue={team.owner}
                onChange={handleNameChange1}
              />
            </Form.Group>
         
            <Form.Group className="mb-3" controlId="TeamOwnerPosition">
              <Form.Label>Owner's Position</Form.Label>
              <Form.Control
                type="input"
                defaultValue={team.position}
                onChange={handlePositionChange1}
              />
            </Form.Group>
          </Col>
        </Row>
        {/* Button Section */}
        <ButtonWrapper>
          <Link to="/aboutus/meet_the_team/view"  style={{
                width: "50%",
              }}>
          <Button variant="secondary">Go Back</Button>
          </Link>
          <Button type="submit" disabled={isUpdating}  style={{
                width: "50%",
              }}>
            {isUpdating ? "Saving..." : "Save"}
          </Button>
        </ButtonWrapper>
         {/* Show success alert */}
       {showSuccessMessage && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 100,
            }}
          ></div>
          <Alert
            variant="success"
            onClose={() => setShowSuccessMessage(false)}
            dismissible={false}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "25vw",
              height: "auto",
              zIndex: 1000,
            }}
          >
            <Alert.Heading
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "green",
                textAlign: "center",
              }}
            >
              Update Successfull!
            </Alert.Heading>

            <p style={{ textAlign: "center" }}>
              Your meet the team section has been updated.
            </p>
            <br />
            <div className="d-flex justify-content-end">
              <Button
                variant="outline-success"
                onClick={() => {
                  setShowSuccessMessage(false);
                  navigate("/aboutus/meet_the_team/view");
                }}
              >
                Ok
              </Button>
            </div>
          </Alert>
        </>
      )}
      </Form>
    </FormWrapper>
  );
}

const FormWrapper = styled.div`
  padding: 0rem 28rem 1rem 28rem;
`;

const Title = styled.h3`
  text-align: center;
  padding: 10px 0;
`;

const SubTitle = styled.h6``;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  gap: 2rem;
`;
const RemoveButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13vw;
`;
