import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { getForBusinessImage } from "../../../api/axios";
const id = 1;

export default function HeaderView() {
  const [data, setHeader] = useState({
    paragraph: "",
    image: "",
    index: null,
  });
  useEffect(() => {
    getForBusinessImage()
      .then((res) => {
        const headerData = res.data.data[0];
        setHeader({ ...headerData, index: res.data.data.indexOf(headerData) });
        console.log(headerData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <ContentWrapper>
      <HomeTitle>Header Section</HomeTitle>
      <TableWrapper>
        <Table>
          <thead>
            <TableRow>
              <TableHeader>Paragraph</TableHeader>
              {/* <TableHeader>Paragraph</TableHeader> */}
              <TableHeader>Header Image</TableHeader>
            </TableRow>
          </thead>
          <TableBody>
            <TableRow>
              <TableCell>{data.paragraph}</TableCell>
              <TableCell>
                <img src={data.image} alt="header" width="500" height="300"/>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableWrapper>
      <ButtonWrapper>
        <Link to="/forbusiness">
          <Button variant="secondary">Go Back</Button>
        </Link>
        <Link to={`/forbusiness/headerimg/edit/${id}`}>
          <Button>Edit header section</Button>
        </Link>
      </ButtonWrapper>
    </ContentWrapper>
  );
}
const ContentWrapper = styled.div`
  padding-bottom: 30px;
  justify-content: center;
`;

const HomeTitle = styled.h3`
  text-align: center;
  padding: 10px 0;
`;

const TableWrapper = styled.div`
  margin: 0 auto;
  padding: 10px 0 0 50px;
`;
const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;
const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;
const TableHeader = styled.th`
  text-align: left;
  padding: 10px;
  border: 2px solid #ccc;
`;
const TableBody = styled.tbody``;

const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  padding-top: 40px;
  display: flex;
  gap: 5rem;
  justify-content: center;
`;
