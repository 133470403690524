import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate} from "react-router-dom";
import styled from "styled-components";
import { Alert } from "react-bootstrap";
import { getForIndividualsComment,updateForIndividualsComment } from "../../../api/axios";
const id = 1;

export default function EditComments() {

const [owner_name1, setOwner_name1] = useState("");
const [comment1, setComment1] = useState("");
const [owner_name2, setOwner_name2] = useState("");
const [comment2, setComment2] = useState("");
const [owner_name3, setOwner_name3] = useState("");
const [comment3, setComment3] = useState("");
const [owner_name4, setOwner_name4] = useState("");
const [comment4, setComment4] = useState("");
const [data, setComment] = useState({
  owner_name1: "",
  comment1: "",
  owner_name2: "",
  comment2: "",
  owner_name3: "",
  comment3: "",
  owner_name4: "",
  comment4: "",
  index: null,
});

 const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();
  const [wordLimitExceeded1, setWordLimitExceeded1] = useState(false);
  const [wordLimitExceeded2, setWordLimitExceeded2] = useState(false);
  const [wordLimitExceeded3, setWordLimitExceeded3] = useState(false);
  const [wordLimitExceeded4, setWordLimitExceeded4] = useState(false);

useEffect(() => {
  getForIndividualsComment()
    .then((res) => {
      console.log(res);
      // console.log(res.data.data[0]);
      setComment(res.data.data[0]);
    })
    .catch((error) => {
      console.log(error);
    });
}, []);

const handleParagraph1Change = (e) => {
  setComment1(e.target.value);
  if (e.target.value.split(" ").length > 100 || e.target.value.length > 400) {
    setWordLimitExceeded1(true);
  } else {
    setWordLimitExceeded1(false);
  }
};
const handleParagraph2Change = (e) => {
  setComment2(e.target.value);
  if (e.target.value.split(" ").length > 100 || e.target.value.length > 400) {
    setWordLimitExceeded2(true);
  } else {
    setWordLimitExceeded2(false);
  }
};
const handleParagraph3Change = (e) => {
  setComment3(e.target.value);
  if (e.target.value.split(" ").length > 100 || e.target.value.length > 400) {
    setWordLimitExceeded3(true);
  } else {
    setWordLimitExceeded3(false);
  }
};
const handleParagraph4Change = (e) => {
  setComment4(e.target.value);
  if (e.target.value.split(" ").length > 100 || e.target.value.length > 400) {
    setWordLimitExceeded4(true);
  } else {
    setWordLimitExceeded4(false);
  }
};

const SendCommentData = (e) => {
  e.preventDefault();
  if (wordLimitExceeded1) return;
  if (wordLimitExceeded2) return;
  if (wordLimitExceeded3) return;
  if (wordLimitExceeded4) return;
  setSaving(true);

  var formData = {
    owner_name1,
    comment1,
    owner_name2,
    comment2,
    owner_name3,
    comment3,
    owner_name4,
    comment4,   
  };

  updateForIndividualsComment(formData).then((res) => {
    console.log(res);
    if (res.status === 200) {
      setShowSuccessMessage(true);
    //alert("Save changes successfully");
    // navigate('/contactus');
    console.log(res.data);
    } else {
    Promise.resolve();
    }
    e.target.reset();
    setSaving(false);
})
.catch((err) => {
    console.log(err);
    setSaving(false);
});
};


  return (
    <FormWrapper>
      <Form>
        <Title>Edit comments Section</Title>
        <SubTitle >Comment 1</SubTitle>
        {/* Card 01 */}
        <Form.Group controlId="formBasicName"
         style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
        
         
          
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label style={{ fontWeight: "bold" }}> Enter an Owner's name</Form.Label>
          <Form.Control  as="textarea"
            rows={3}
            placeholder={data.owner_name1}
            defaultValue={data.owner_name1}
            onChange={(e) => setOwner_name1(e.target.value)}
          />
          <Form.Label style={{ fontWeight: "bold" }}> Enter a new comment</Form.Label>
          <Form.Control as="textarea"
            rows={3}
            placeholder={data.comment1}
            defaultValue={data.comment1}
            onChange={handleParagraph1Change}
            isInvalid={wordLimitExceeded1}
          />
          {wordLimitExceeded1 && (
            <Form.Control.Feedback type="invalid">
              Word limit exceeded.
            </Form.Control.Feedback>
          )}
        </Form.Group>

      

        {/* Card 02 */}
        <SubTitle >Comment 2</SubTitle>
        <Form.Group controlId="formBasicName"
         style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
      
          
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label style={{ fontWeight: "bold" }}> Enter an Owner's name</Form.Label>
          <Form.Control  as="textarea"
            rows={3}
            placeholder={data.owner_name2}
            defaultValue={data.owner_name2}
            onChange={(e) => setOwner_name2(e.target.value)}
          />
          <Form.Label style={{ fontWeight: "bold" }}> Enter a new comment</Form.Label>
          <Form.Control as="textarea"
            rows={3}
            placeholder={data.comment2}
            defaultValue={data.comment2}
            onChange={handleParagraph2Change}
            isInvalid={wordLimitExceeded2}
          />
          {wordLimitExceeded2 && (
            <Form.Control.Feedback type="invalid">
              Word limit exceeded.
            </Form.Control.Feedback>
          )}
        </Form.Group>


       
        {/* Card 03 */}
        <SubTitle >Comment 3</SubTitle>
        
        <Form.Group controlId="formBasicName"
         style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
      
          
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label style={{ fontWeight: "bold" }}> Enter an Owner's name</Form.Label>
          <Form.Control  as="textarea"
            rows={3}
            placeholder={data.owner_name3}
            defaultValue={data.owner_name3}
            onChange={(e) => setOwner_name3(e.target.value)}
          />
           <Form.Label style={{ fontWeight: "bold" }}> Enter a new comment</Form.Label>
          <Form.Control as="textarea"
            rows={3}
            placeholder={data.comment3}
            defaultValue={data.comment3}
            onChange={handleParagraph3Change}
            isInvalid={wordLimitExceeded3}
          />
          {wordLimitExceeded3 && (
            <Form.Control.Feedback type="invalid">
              Word limit exceeded.
            </Form.Control.Feedback>
          )}
        </Form.Group>


        <Form.Group controlId="formBasicName"
         style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
        {/* Card 4 */}
        <SubTitle >Comment 4</SubTitle>
          
          
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label style={{ fontWeight: "bold" }}> Enter an Owner's name</Form.Label>
          <Form.Control  as="textarea"
            rows={3}
            placeholder={data.owner_name4}
            defaultValue={data.owner_name4}
            onChange={(e) => setOwner_name4(e.target.value)}
          />
           <Form.Label style={{ fontWeight: "bold" }}> Enter a new comment</Form.Label>
          <Form.Control as="textarea"
            rows={3}
            placeholder={data.comment4}
            defaultValue={data.comment4}
            onChange={handleParagraph4Change}
            isInvalid={wordLimitExceeded4}
          />
          {wordLimitExceeded4 && (
            <Form.Control.Feedback type="invalid">
              Word limit exceeded.
            </Form.Control.Feedback>
          )}
        </Form.Group>


       
     

        <ButtonWrapper>
          <Link to={`/forindividuals/comments/view/${id}`}>
            <Button variant="secondary">Go Back</Button>
          </Link>
          
            <Button variant="primary"
          type="submit"
          onClick={SendCommentData}
          disabled={saving ||
              wordLimitExceeded1 ||
              wordLimitExceeded2 ||
              wordLimitExceeded3 ||
              wordLimitExceeded4
              }
          >
          {saving ? "Saving..." : "Save Changes"}</Button>
          
        </ButtonWrapper>
      </Form>
      {showSuccessMessage && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 100,
            }}
          ></div>
          <Alert
            variant="success"
            onClose={() => setShowSuccessMessage(false)}
            dismissible={false}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "25vw",
              height: "auto",
              zIndex: 1000,
            }}
          >
            <Alert.Heading
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "green",
                textAlign: "center",
              }}
            >
              Update Successfull!
            </Alert.Heading>

            <p style={{ textAlign: "center" }}>
              Your comments section has been updated.
            </p>
            <br />
            <div className="d-flex justify-content-end">
              <Button
                variant="outline-success"
                onClick={() => {
                  setShowSuccessMessage(false);
                  navigate(`/forindividuals/comments/view/${id}`);
                }}
              >
                Ok
              </Button>
            </div>
          </Alert>
        </>
      )}
    </FormWrapper>
  );
}

const FormWrapper = styled.div`
  padding: 0rem 22rem 1rem 22rem;
`;

const Title = styled.h3`
  text-align: center;
  padding: 10px 0;
`;

const SubTitle = styled.h5``;

const ButtonWrapper = styled.div`
   margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
`;
