import React, { createContext, useContext, useEffect, useState } from "react";

import { Box, CircularProgress } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const _token = localStorage.getItem("token");

  const [isAuthenticated, setIsAuthenticated] = useState(!!_token);
  const [token, setToken] = useState(_token);
  const [reloading, setReloading] = useState(false);

  const redirectToLogin = () => {
    // setReloading(true);
    navigate("/");
    setIsAuthenticated(false);
  };

  const isStoragePresent = async () => {
    if (
      localStorage.getItem("userID") === null &&
      localStorage.getItem("token") === null
    ) {
      localStorage.clear();
      redirectToLogin();
    }
  };

  const login = () => {
    setIsAuthenticated(true);
  };

  const doLogout = async () => {
    try {
      redirectToLogin();
    } catch (e) {
      console.error({ e });
    }
  };

  useEffect(() => {
    isStoragePresent();
  }, [location?.pathname]);

  const value = {
    isAuthenticated,
    login,
    doLogout,
    token,
    redirectToLogin,
  };

  return (
    <AuthContext.Provider value={value}>
      {reloading ? (
        <Box
          sx={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ color: "black" }} />
        </Box>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default useAuth;
