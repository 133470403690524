import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { getForBusinessCard } from "../../../api/axios";
const id = 1;

export default function CardView() {
  const [data, setCard] = useState({
    card_image1: "",
    content1: "",
    card_image2: "",
    content2: "",
    card_image3: "",
    content3: "",
    card_image4: "",
    content4: "",
    card_image5: "",
    content5: "",
    card_image6: "",
    content6: "",
    card_image7: "",
    content7: "",
    card_image8: "",
    content8: "",
    index: null,
  });

  // useEffect(() => {
  //   getForBusinessCard()
  //     .then((res) => {
  //       console.log(res);
  //       // console.log(res.data.data[0]);
  //       setCard(res.data.data[0]);
        
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  useEffect(() => {
    
    getForBusinessCard().then((res) => {
        const cardData = res.data.data[0];
        setCard({ ...cardData, index: res.data.data.indexOf(cardData) });             
        console.log(cardData);                     
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <ContentWrapper>
      <HomeTitle>Cards Section</HomeTitle>
      <TableWrapper>
        <Table>
          <thead>
            <TableRow>
              <TableHeader>Card Number</TableHeader>
              <TableHeader>Card Image</TableHeader>
              <TableHeader>Content</TableHeader>
            </TableRow>
          </thead>
          <TableBody>
            <TableRow>
              <TableCell>Card 01</TableCell>
              <TableCell><img src={data.card_image1} alt="card 1"  width="150" /></TableCell>
              <TableCell>
                {data.content1}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Card 02 </TableCell>
              <TableCell><img src={data.card_image2} alt="card 2"  width="150" /></TableCell>
              <TableCell>
              {data.content2}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Card 03 </TableCell>
              <TableCell><img src={data.card_image3} alt="card 3"  width="150" /></TableCell>
              <TableCell>{data.content3}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Card 04 </TableCell>
              <TableCell><img src={data.card_image4} alt="card 4"  width="150" /></TableCell>
              <TableCell>{data.content4}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Card 05 </TableCell>
              <TableCell><img src={data.card_image5} alt="card 5"  width="150" /></TableCell>
              <TableCell>
              {data.content5}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Card 06 </TableCell>
              <TableCell><img src={data.card_image6} alt="card 6"  width="150" /></TableCell>
              <TableCell>{data.content6}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Card 07 </TableCell>
              <TableCell><img src={data.card_image7} alt="card 7"  width="150" /></TableCell>
              <TableCell>{data.content7}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Card 08 </TableCell>
              <TableCell><img src={data.card_image8} alt="card 8"  width="150" /></TableCell>
              <TableCell>
              {data.content8}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableWrapper>
      <ButtonWrapper>
        <Link to="/forbusiness">
          <Button variant="secondary">Go Back</Button>
        </Link>
        <Link to={`/forbusiness/card/edit/${id}`}>
        <Button>Edit card section</Button>
        </Link>
      </ButtonWrapper>
    </ContentWrapper>
  );
}

const ContentWrapper = styled.div`
  padding-bottom: 30px;
  justify-content: center;
`;

const HomeTitle = styled.h3`
  text-align: center;
  padding: 10px 0;
`;

const TableWrapper = styled.div`
  margin: 0 auto;
  padding: 10px 0 0 50px;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 10px;
  border: 2px solid #ccc;
`;

const TableBody = styled.tbody``;

const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  padding-top: 40px;
  display: flex;
  gap: 5rem;
  justify-content: center;
`;
