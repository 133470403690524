import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate} from "react-router-dom";
import { Alert } from "react-bootstrap";

import styled from "styled-components";
import { getForBusinessCard, updateForBusinessCard } from "../../../api/axios";
const id = 1;

export default function CardEdit() {
  
  const [card_image1, setCard_image1] = useState("");
  const [content1, setContent_1] = useState("");
  const [card_image2, setCard_image2] = useState("");
  const [content2, setContent_2] = useState("");
  const [card_image3, setCard_image3] = useState("");
  const [content3, setContent_3] = useState("");
  const [card_image4, setCard_image4] = useState("");
  const [content4, setContent_4] = useState("");
  const [card_image5, setCard_image5] = useState("");
  const [content5, setContent_5] = useState("");
  const [card_image6, setCard_image6] = useState("");
  const [content6, setContent_6] = useState("");
  const [card_image7, setCard_image7] = useState("");
  const [content7, setContent_7] = useState("");
  const [card_image8, setCard_image8] = useState("");
  const [content8, setContent_8] = useState("");
  const [data, setBusiness_card] = useState({
    card_image1: "",
    content1: "",
    card_image2: "",
    content2: "",
    card_image3: "",
    content3: "",
    card_image4: "",
    content4: "",
    card_image5: "",
    content5: "",
    card_image6: "",
    content6: "",
    card_image7: "",
    content7: "",
    card_image8: "",
    content8: "",
    index: null,
  });
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const inputRef5 = useRef(null);
  const inputRef6 = useRef(null);
  const inputRef7 = useRef(null);
  const inputRef8 = useRef(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();
  const [wordLimitExceeded1, setWordLimitExceeded1] = useState(false);
  const [wordLimitExceeded2, setWordLimitExceeded2] = useState(false);
  const [wordLimitExceeded3, setWordLimitExceeded3] = useState(false);
  const [wordLimitExceeded4, setWordLimitExceeded4] = useState(false);
  const [wordLimitExceeded5, setWordLimitExceeded5] = useState(false);
  const [wordLimitExceeded6, setWordLimitExceeded6] = useState(false);
  const [wordLimitExceeded7, setWordLimitExceeded7] = useState(false);
  const [wordLimitExceeded8, setWordLimitExceeded8] = useState(false);
  useEffect(() => {
    getForBusinessCard()
       .then((res) => {
         console.log(res);
         // console.log(res.data.data[0]);
         setBusiness_card(res.data.data[0]);
         
         
       })
       .catch((error) => {
         console.log(error);
       });
   }, []);

   const handleParagraph1Change = (e) => {
    setContent_1(e.target.value);
    if (e.target.value.split(" ").length > 20 || e.target.value.length > 100) {
      setWordLimitExceeded1(true);
    } else {
      setWordLimitExceeded1(false);
    }
  };
  const handleParagraph2Change = (e) => {
    setContent_2(e.target.value);
    if (e.target.value.split(" ").length > 20 || e.target.value.length > 100) {
      setWordLimitExceeded2(true);
    } else {
      setWordLimitExceeded2(false);
    }
  };
  const handleParagraph3Change = (e) => {
    setContent_3(e.target.value);
    if (e.target.value.split(" ").length > 20 || e.target.value.length > 100) {
      setWordLimitExceeded3(true);
    } else {
      setWordLimitExceeded3(false);
    }
  };
  const handleParagraph4Change = (e) => {
    setContent_4(e.target.value);
    if (e.target.value.split(" ").length > 20 || e.target.value.length > 100) {
      setWordLimitExceeded4(true);
    } else {
      setWordLimitExceeded4(false);
    }
  };
  const handleParagraph5Change = (e) => {
    setContent_5(e.target.value);
    if (e.target.value.split(" ").length > 20 || e.target.value.length > 100) {
      setWordLimitExceeded5(true);
    } else {
      setWordLimitExceeded5(false);
    }
  };
  const handleParagraph6Change = (e) => {
    setContent_6(e.target.value);
    if (e.target.value.split(" ").length > 20 || e.target.value.length > 100) {
      setWordLimitExceeded6(true);
    } else {
      setWordLimitExceeded6(false);
    }
  };
  const handleParagraph7Change = (e) => {
    setContent_7(e.target.value);
    if (e.target.value.split(" ").length > 20 || e.target.value.length > 100) {
      setWordLimitExceeded7(true);
    } else {
      setWordLimitExceeded7(false);
    }
  };
  const handleParagraph8Change = (e) => {
    setContent_8(e.target.value);
    if (e.target.value.split(" ").length > 20 || e.target.value.length > 100) {
      setWordLimitExceeded8(true);
    } else {
      setWordLimitExceeded8(false);
    }
  };

  const SendCardData = (e) => {
    e.preventDefault();
    if (wordLimitExceeded1) return;
    if (wordLimitExceeded2) return;
    if (wordLimitExceeded3) return;
    if (wordLimitExceeded4) return;
    if (wordLimitExceeded5) return;
    if (wordLimitExceeded6) return;
    if (wordLimitExceeded7) return;
    if (wordLimitExceeded8) return;
    setSaving(true);

    var formData = new FormData();
    formData.append("content1", content1);
    formData.append("content2", content2);
    formData.append("content3", content3);
    formData.append("content4", content4);
    formData.append("content5", content5);
    formData.append("content6", content6);
    formData.append("content7", content7);
    formData.append("content8", content8);

    if (card_image1 instanceof File) {
      formData.append("card_image1", card_image1);
    }
    if (card_image2 instanceof File) {
      formData.append("card_image2", card_image2);
    }
    if (card_image3 instanceof File) {
      formData.append("card_image3", card_image3);
    }
    if (card_image4 instanceof File) {
      formData.append("card_image4", card_image4);
    }
    if (card_image5 instanceof File) {
      formData.append("card_image5", card_image5);
    }
    if (card_image6 instanceof File) {
      formData.append("card_image6", card_image6);
    }
    if (card_image7 instanceof File) {
      formData.append("card_image7", card_image7);
    }
    if (card_image8 instanceof File) {
      formData.append("card_image8", card_image8);
    }

    updateForBusinessCard(formData)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setShowSuccessMessage(true);
          //alert("crad section has been updated successfully");
          // navigate(`/forbusiness/card/view/${id}`);
        } else {
          Promise.resolve();
        }
        e.target.reset();
        setSaving(false);
      })
      .catch((err) => {
        console.log(err);
        setSaving(false);
      });
  };


return (
  <FormWrapper>
    <Form>
      <Title>Edit Cards Section</Title>
      <SubTitle >Card 1</SubTitle>
      {/* Card 01 */}
      <Form.Group
        controlId="formBasicName"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Form.Label style={{ fontWeight: "bold" }}>
        Select a new Image for Card 1
        </Form.Label>

        <Form.Control
          type="file"
          accept="image/*"
          onChange={(e) => {setCard_image1(e.target.files[0]);
          }}
          style={{ display: "none" }}
          ref={inputRef1}
        />
        {card_image1 ? (
          <>
          <img
            style={{ maxWidth: "15vw" }}
            src={URL.createObjectURL(card_image1)}
            alt="card_image1"
            onClick={() => {
              setCard_image1.current.click();
            }}
          />
          <br />
          <RemoveButtonWrapper>
                <Button variant="outline-danger" onClick={() => setCard_image1(null)}>
                  Remove your image
                </Button>
              </RemoveButtonWrapper>
            </>
        ) : (
          <img
            style={{ maxWidth: "15vw", cursor: "pointer" }}
            src={data.card_image1}
            alt="card_image1"
            onClick={() => {
              inputRef1.current.click();
            }}
              onMouseEnter={(e) => {
                e.target.style.opacity = 0.8;
              }}
              onMouseLeave={(e) => {
                e.target.style.opacity = 1;
              }}
            />
          )}
      </Form.Group>
      <br />


      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label style={{ fontWeight: "bold" }}>Enter a new content</Form.Label>
        <Form.Control 
        style={{width: '500px'}}
        placeholder={data.content1}
        defaultValue={data.content1}
        onChange={handleParagraph1Change}
        isInvalid={wordLimitExceeded1}
        />
        {wordLimitExceeded1 && (
            <Form.Control.Feedback type="invalid">
              Word limit exceeded.
            </Form.Control.Feedback>
          )}
         
      </Form.Group>
      <SubTitle >Card 2</SubTitle>
      {/* Card 02 */}
      <Form.Group
        controlId="formBasicName"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Form.Label style={{ fontWeight: "bold" }}>
        Select a new Image for Card 2
        </Form.Label>

        <Form.Control
          type="file"
          accept="image/*"
          onChange={(e) => {setCard_image2(e.target.files[0]);
          }}
          style={{ display: "none" }}
          ref={inputRef2}
        />
        {card_image2 ? (
          <>
          <img
            style={{ maxWidth: "15vw" }}
            src={URL.createObjectURL(card_image2)}
            alt="card_image2"
            onClick={() => {
              setCard_image2.current.click();
            }}
          />
          <br />
          <RemoveButtonWrapper>
                <Button variant="outline-danger" onClick={() => setCard_image2(null)}>
                  Remove your image
                </Button>
              </RemoveButtonWrapper>
            </>
        ) : (
          <img
            style={{ maxWidth: "15vw", cursor: "pointer" }}
            src={data.card_image2}
            alt="card_image2"
            onClick={() => {
              inputRef2.current.click();
            }}
              onMouseEnter={(e) => {
                e.target.style.opacity = 0.8;
              }}
              onMouseLeave={(e) => {
                e.target.style.opacity = 1;
              }}
            />
          )}
      </Form.Group>
      <br />

      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label style={{ fontWeight: "bold" }}>Enter a new content</Form.Label>
        <Form.Control 
        style={{width: '500px'}}
        placeholder={data.content2}
        defaultValue={data.content2}
        onChange={handleParagraph2Change}
            isInvalid={wordLimitExceeded2}
          />
          {wordLimitExceeded2 && (
            <Form.Control.Feedback type="invalid">
              Word limit exceeded.
            </Form.Control.Feedback>
          )}
         
      </Form.Group>
      <SubTitle >Card 3</SubTitle>
      {/* Card 03 */}
      <Form.Group
        controlId="formBasicName"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Form.Label style={{ fontWeight: "bold" }}>
        Select a new Image for Card 3
        </Form.Label>

        <Form.Control
          type="file"
          accept="image/*"
          onChange={(e) => {setCard_image3(e.target.files[0]);
          }}
          style={{ display: "none" }}
          ref={inputRef3}
        />
        {card_image3 ? (
          <>
          <img
            style={{ maxWidth: "15vw" }}
            src={URL.createObjectURL(card_image3)}
            alt="card_image3"
            onClick={() => {
              setCard_image3.current.click();
            }}
          />
          <br />
          <RemoveButtonWrapper>
                <Button variant="outline-danger" onClick={() => setCard_image3(null)}>
                  Remove your image
                </Button>
              </RemoveButtonWrapper>
            </>
        ) : (
          <img
            style={{ maxWidth: "15vw", cursor: "pointer" }}
            src={data.card_image3}
            alt="card_image3"
            onClick={() => {
              inputRef3.current.click();
            }}
              onMouseEnter={(e) => {
                e.target.style.opacity = 0.8;
              }}
              onMouseLeave={(e) => {
                e.target.style.opacity = 1;
              }}
            />
          )}
      </Form.Group>
      <br />

      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label style={{ fontWeight: "bold" }}>Enter a new content</Form.Label>
        <Form.Control 
        style={{width: '500px'}}
        placeholder={data.content3}
        defaultValue={data.content3}
        onChange={handleParagraph3Change}
            isInvalid={wordLimitExceeded3}
          />
          {wordLimitExceeded3 && (
            <Form.Control.Feedback type="invalid">
              Word limit exceeded.
            </Form.Control.Feedback>
          )}
         
      </Form.Group>

      <SubTitle >Card 4</SubTitle>
      {/* Card 4 */}

      <Form.Group
        controlId="formBasicName"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Form.Label style={{ fontWeight: "bold" }}>
        Select a new Image for Card 4
        </Form.Label>

        <Form.Control
          type="file"
          accept="image/*"
          onChange={(e) => {setCard_image4(e.target.files[0]);
          }}
          style={{ display: "none" }}
          ref={inputRef4}
        />
        {card_image4 ? (
          <>
          <img
            style={{ maxWidth: "15vw" }}
            src={URL.createObjectURL(card_image4)}
            alt="card_image1"
            onClick={() => {
              setCard_image4.current.click();
            }}
          />
          <br />
          <RemoveButtonWrapper>
                <Button variant="outline-danger" onClick={() => setCard_image4(null)}>
                  Remove your image
                </Button>
              </RemoveButtonWrapper>
            </>
        ) : (
          <img
            style={{ maxWidth: "15vw", cursor: "pointer" }}
            src={data.card_image4}
            alt="card_image4"
            onClick={() => {
              inputRef4.current.click();
            }}
              onMouseEnter={(e) => {
                e.target.style.opacity = 0.8;
              }}
              onMouseLeave={(e) => {
                e.target.style.opacity = 1;
              }}
            />
          )}
      </Form.Group>
      <br />

      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label style={{ fontWeight: "bold" }}>Enter a new content</Form.Label>
        <Form.Control 
        style={{width: '500px'}}
        placeholder={data.content4}
        defaultValue={data.content4}
        onChange={handleParagraph4Change}
            isInvalid={wordLimitExceeded4}
          />
          {wordLimitExceeded4 && (
            <Form.Control.Feedback type="invalid">
              Word limit exceeded.
            </Form.Control.Feedback>
          )}
         
      </Form.Group>
      <SubTitle >Card 5</SubTitle>
{/* Card 5 */}
<Form.Group
        controlId="formBasicName"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Form.Label style={{ fontWeight: "bold" }}>
        Select a new Image for Card 5
        </Form.Label>

        <Form.Control
          type="file"
          accept="image/*"
          onChange={(e) => {setCard_image5(e.target.files[0]);
          }}
          style={{ display: "none" }}
          ref={inputRef5}
        />
        {card_image5 ? (
          <>
          <img
            style={{ maxWidth: "15vw" }}
            src={URL.createObjectURL(card_image5)}
            alt="card_image5"
            onClick={() => {
              setCard_image5.current.click();
            }}
          />
          <br />
          <RemoveButtonWrapper>
                <Button variant="outline-danger" onClick={() => setCard_image5(null)}>
                  Remove your image
                </Button>
              </RemoveButtonWrapper>
            </>
        ) : (
          <img
            style={{ maxWidth: "15vw", cursor: "pointer" }}
            src={data.card_image5}
            alt="card_image5"
            onClick={() => {
              inputRef5.current.click();
            }}
              onMouseEnter={(e) => {
                e.target.style.opacity = 0.8;
              }}
              onMouseLeave={(e) => {
                e.target.style.opacity = 1;
              }}
            />
          )}
      </Form.Group>
      <br />

      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label style={{ fontWeight: "bold" }}>Enter a new content</Form.Label>
        <Form.Control 
        style={{width: '500px'}}
        placeholder={data.content5}
        defaultValue={data.content5}
        onChange={handleParagraph5Change}
        isInvalid={wordLimitExceeded5}
        />
        {wordLimitExceeded5 && (
            <Form.Control.Feedback type="invalid">
              Word limit exceeded.
            </Form.Control.Feedback>
          )}
         
      </Form.Group>
      <SubTitle >Card 6</SubTitle>
      {/* Card 6 */}

      <Form.Group
        controlId="formBasicName"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Form.Label style={{ fontWeight: "bold" }}>
        Select a new Image for Card 6
        </Form.Label>

        <Form.Control
          type="file"
          accept="image/*"
          onChange={(e) => {setCard_image6(e.target.files[0]);
          }}
          style={{ display: "none" }}
          ref={inputRef6}
        />
        {card_image6 ? (
          <>
          <img
            style={{ maxWidth: "15vw" }}
            src={URL.createObjectURL(card_image6)}
            alt="card_image6"
            onClick={() => {
              setCard_image6.current.click();
            }}
          />
          <br />
          <RemoveButtonWrapper>
                <Button variant="outline-danger" onClick={() => setCard_image6(null)}>
                  Remove your image
                </Button>
              </RemoveButtonWrapper>
            </>
        ) : (
          <img
            style={{ maxWidth: "15vw", cursor: "pointer" }}
            src={data.card_image6}
            alt="card_image6"
            onClick={() => {
              inputRef6.current.click();
            }}
              onMouseEnter={(e) => {
                e.target.style.opacity = 0.8;
              }}
              onMouseLeave={(e) => {
                e.target.style.opacity = 1;
              }}
            />
          )}
      </Form.Group>
      <br />

      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label style={{ fontWeight: "bold" }}>Enter a new content</Form.Label>
        <Form.Control 
        style={{width: '500px'}}
        placeholder={data.content6}
        defaultValue={data.content6}
        onChange={handleParagraph6Change}
        isInvalid={wordLimitExceeded6}
        />
        {wordLimitExceeded6 && (
            <Form.Control.Feedback type="invalid">
              Word limit exceeded.
            </Form.Control.Feedback>
          )}
         
      </Form.Group>
      <SubTitle >Card 7</SubTitle>
      {/* Card 7 */}
      <Form.Group
        controlId="formBasicName"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Form.Label style={{ fontWeight: "bold" }}>
        Select a new Image for Card 7
        </Form.Label>

        <Form.Control
          type="file"
          accept="image/*"
          onChange={(e) => {setCard_image7(e.target.files[0]);
          }}
          style={{ display: "none" }}
          ref={inputRef7}
        />
        {card_image7 ? (
          <>
          <img
            style={{ maxWidth: "15vw" }}
            src={URL.createObjectURL(card_image7)}
            alt="card_image7"
            onClick={() => {
              setCard_image7.current.click();
            }}
          />
          <br />
          <RemoveButtonWrapper>
                <Button variant="outline-danger" onClick={() => setCard_image7(null)}>
                  Remove your image
                </Button>
              </RemoveButtonWrapper>
            </>
        ) : (
          <img
            style={{ maxWidth: "15vw", cursor: "pointer" }}
            src={data.card_image7}
            alt="card_image7"
            onClick={() => {
              inputRef7.current.click();
            }}
              onMouseEnter={(e) => {
                e.target.style.opacity = 0.8;
              }}
              onMouseLeave={(e) => {
                e.target.style.opacity = 1;
              }}
            />
          )}
      </Form.Group>
      <br />

      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label style={{ fontWeight: "bold" }}>Enter a new content</Form.Label>
        <Form.Control 
        
        placeholder={data.content7}
        defaultValue={data.content7}
        onChange={handleParagraph7Change}
        isInvalid={wordLimitExceeded7}
        />
        {wordLimitExceeded7 && (
            <Form.Control.Feedback type="invalid">
              Word limit exceeded.
            </Form.Control.Feedback>
          )}
         
      </Form.Group>
      <SubTitle >Card 8</SubTitle>
      {/* Card 8 */}

      <Form.Group
        controlId="formBasicName"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Form.Label style={{ fontWeight: "bold" }}>
        Select a new Image for Card 8
        </Form.Label>

        <Form.Control
          type="file"
          accept="image/*"
          onChange={(e) => {setCard_image8(e.target.files[0]);
          }}
          style={{ display: "none" }}
          ref={inputRef8}
        />
        {card_image8 ? (
          <>
          <img
            style={{ maxWidth: "15vw" }}
            src={URL.createObjectURL(card_image8)}
            alt="card_image8"
            onClick={() => {
              setCard_image8.current.click();
            }}
          />
          <br />
          <RemoveButtonWrapper>
                <Button variant="outline-danger" onClick={() => setCard_image8(null)}>
                  Remove your image
                </Button>
              </RemoveButtonWrapper>
            </>
        ) : (
          <img
            style={{ maxWidth: "15vw", cursor: "pointer" }}
            src={data.card_image8}
            alt="card_image8"
            onClick={() => {
              inputRef8.current.click();
            }}
              onMouseEnter={(e) => {
                e.target.style.opacity = 0.8;
              }}
              onMouseLeave={(e) => {
                e.target.style.opacity = 1;
              }}
            />
          )}
      </Form.Group>
      <br />

      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label style={{ fontWeight: "bold" }}>Enter a new content</Form.Label>
        <Form.Control 
        placeholder={data.content8}
        defaultValue={data.content8}
        onChange={handleParagraph8Change}
        isInvalid={wordLimitExceeded8}
        />
        {wordLimitExceeded8 && (
            <Form.Control.Feedback type="invalid">
              Word limit exceeded.
            </Form.Control.Feedback>
          )}
         
      </Form.Group>

      <ButtonWrapper>
       
        <Link to={`/forbusiness/card/view/${id}`}>
          <Button variant="secondary">Go Back</Button>
        </Link>
        
          <Button
          variant="primary"
          type="submit"
          onClick={SendCardData}
          disabled={saving ||
              wordLimitExceeded1 ||
              wordLimitExceeded2 ||
              wordLimitExceeded3 ||
              wordLimitExceeded4 ||
              wordLimitExceeded5 ||
              wordLimitExceeded6 ||
              wordLimitExceeded7 ||
              wordLimitExceeded8 
              }
          >
          {saving ? "Saving..." : "Save Changes"}</Button>
        
      </ButtonWrapper>
    </Form>
    {showSuccessMessage && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 100,
            }}
          ></div>
          <Alert
            variant="success"
            onClose={() => setShowSuccessMessage(false)}
            dismissible={false}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "25vw",
              height: "auto",
              zIndex: 1000,
            }}
          >
            <Alert.Heading
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "green",
                textAlign: "center",
              }}
            >
              Update Successfull!
            </Alert.Heading>

            <p style={{ textAlign: "center" }}>
              Your cards section has been updated.
            </p>
            <br />
            <div className="d-flex justify-content-end">
              <Button
                variant="outline-success"
                onClick={() => {
                  setShowSuccessMessage(false);
                  navigate(`/forbusiness/card/view/${id}`);
                }}
              >
                Ok
              </Button>
            </div>
          </Alert>
        </>
      )}

  </FormWrapper>
);
          }

const FormWrapper = styled.div`
  padding: 0rem 22rem 1rem 22rem;
`;

const Title = styled.h3`
  text-align: center;
  padding: 10px 0;
`;

const SubTitle = styled.h5``;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
`;
const RemoveButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 50%;
`;