import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
const id = 1;
function ForIndividuals() {

  return (
    <ContentWrapper>
      <Title>Select a section</Title>
      <SectionWrapper>
    
        
        <Link to={`/forindividuals/para/view/${id}`}>
          <Button>Header Section</Button>
        </Link> 
        <Link to={`/forindividuals/step/view/${id}`}>
        <Button>Step Cards Section</Button>
        </Link>
        <Link to={`/forindividuals/comments/view/${id}`}>
        <Button>Comments Section</Button>'
        </Link>
        
      </SectionWrapper>
    </ContentWrapper>
  );
}

export default ForIndividuals;
const ContentWrapper = styled.div`
  padding:0 50px 30px 50px;
`;

const Title = styled.h3`
  text-align: center;
  padding: 10px 0;
`;

const SectionWrapper = styled.div`
  /* justify-content: center;
  align-items: center;
  gap: 12px; */
  display: flex;
  gap: 6rem;
  justify-content: center;
  padding-top: 5rem;
`;
