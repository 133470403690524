import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Alert } from "react-bootstrap";
import {
  getAboutUsTeam,
  getAboutUsTeamParagraph,
  deleteMeetTheTeam,
} from "../../../api/axios";
import { faL } from "@fortawesome/free-solid-svg-icons";

export default function MeetTeamView() {
  const [data, setData] = useState([]);
  const [paragraph, setParagraph] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showSuccessMessage1, setShowSuccessMessage1] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [id, setId] = useState("");

  console.log(data);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getAboutUsTeam();
        setData(result.data.data);

        const paragraphResult = await getAboutUsTeamParagraph();
        setParagraph(paragraphResult.data.data[0]);
        console.log(paragraphResult.data.data[0]);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);
  const handleDelete = (id) => {
    setId(id);
    setShowSuccessMessage1(true);
  };
  const confirmDelete = () => {
    deleteMeetTheTeam(id).then(setShowSuccessMessage(true));
  };
  return (
    <ContentWrapper>
      <HomeTitle>Meet the Team Section</HomeTitle>
      <TableWrapper>
        <SubTitle>Paragraph section</SubTitle>
        <Table>
          <thead>
            <TableRow>
              <TableHeader>Part Name</TableHeader>
              <TableHeader>Content</TableHeader>
            </TableRow>
          </thead>
          <TableBody>
            <TableCell>Paragraph 1</TableCell>
            <TableCell>{paragraph.paragraph}</TableCell>
          </TableBody>
        </Table>
        <ButtonWrapper>
          <Link
            to="/aboutus"
            style={{
              width: "20%",
            }}
          >
            <Button variant="secondary">Go Back</Button>
          </Link>
          <Link
            to="/aboutus/meet_the_team/paragraph/edit"
            style={{
              width: "20%",
            }}
          >
            <Button>Edit Paragraph section</Button>
          </Link>
        </ButtonWrapper>
        <br />
        <br />
        <HeaderSection>
          <SubTitle>Team slider section</SubTitle>
          <Link to="/aboutus/meet_the_team/create">
            <Button style={{ width: "150px" }}>Create a New</Button>
          </Link>
        </HeaderSection>

        <Table>
          <thead>
            <TableRow>
              <TableHeader>Pet</TableHeader>
              <TableHeader>Image</TableHeader>
              <TableHeader>Owner</TableHeader>
              <TableHeader>Position</TableHeader>
              <TableHeader>Functions</TableHeader>
            </TableRow>
          </thead>
          <TableBody>
            {data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5}>No data</TableCell>
              </TableRow>
            ) : (
              data.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>
                    <img
                      src={item.image}
                      alt={item.name1}
                      height="auto"
                      width="300px"
                    />
                  </TableCell>
                  <TableCell>{item.owner}</TableCell>
                  <TableCell>{item.position}</TableCell>
                  <TableCell>
                    <Link to={`/aboutus/meet_the_team/edit/${item.id}`}>
                      <Button>Edit</Button>
                    </Link>
                    <Button
                      onClick={() => {
                        handleDelete(item.id);
                      }}
                      style={{ backgroundColor: "#dc3545", border: "red" }}
                    >
                      delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableWrapper>
      {showSuccessMessage && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 100,
            }}
          ></div>
          <Alert
            variant="success"
            onClose={() => setShowSuccessMessage(false)}
            dismissible={false}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "25vw",
              height: "auto",
              zIndex: 1000,
            }}
          >
            <Alert.Heading
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "green",
                textAlign: "center",
              }}
            >
              Successfully Deleted!
            </Alert.Heading>

            <p style={{ textAlign: "center" }}>
              Team slider section has been deleted successfully!
            </p>
            <br />
            <div className="d-flex justify-content-end">
              <Button
                style={{ width: "200px" }}
                variant="outline-success"
                onClick={() => {
                  setShowSuccessMessage(false);
                  window.location.reload();
                }}
              >
                Ok
              </Button>
            </div>
          </Alert>
        </>
      )}
      {showSuccessMessage1 && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 100,
            }}
          ></div>
          <Alert
            variant="danger"
            onClose={() => setShowSuccessMessage(false)}
            dismissible={false}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "25vw",
              height: "auto",
              zIndex: 1000,
            }}
          >
            <Alert.Heading
              style={{
                fontSize: "1.5rem",
                color: "#842029",
                textAlign: "center",
              }}
            >
              Confirm Delete
            </Alert.Heading>
            <p style={{ textAlign: "center", color: "#842029" }}>
              Are you sure you want to delete this Item?
            </p>
            <div
              className="d-flex justify-content-end"
              style={{ display: "grid", gridGap: "10px" }}
            >
              <Button
                style={{ color: "white" }}
                className="btn btn-danger mr-2"
                onClick={() => {
                  confirmDelete();
                  setShowSuccessMessage1(false);
                }}
              >
                Yes
              </Button>
              <Button
                className="btn btn-secondary"
                onClick={() => {
                  setShowSuccessMessage1(false);
                }}
              >
                No
              </Button>
            </div>
          </Alert>
        </>
      )}
    </ContentWrapper>
  );
}

const ContentWrapper = styled.div`
  padding-bottom: 30px;
  justify-content: center;
`;

const HomeTitle = styled.h3`
  text-align: center;
  padding: 10px 0;
`;

const SubTitle = styled.h4`
  text-align: center;
`;

const TableWrapper = styled.div`
  margin: 0 auto;
  padding: 10px 0 0 50px;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 10px;
  border: 2px solid #ccc;
`;

const TableBody = styled.tbody``;

const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  padding-top: 40px;
  display: flex;
  gap: 2rem;
  justify-content: center;
`;
const HeaderSection = styled.div`
  text-align: center;
`;
