import React, { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { updateAboutUsOurStory, getAboutUsOurStory } from "../../../api/axios";
import { Alert } from "react-bootstrap";

export default function OurStoryEdit() {
  const [name1, setName1] = useState("");
  const [position1, setPosition1] = useState("");
  const [image1, setImage1] = useState("");
  const [name2, setName2] = useState("");
  const [position2, setPosition2] = useState("");
  const [image2, setImage2] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [ourstory, setOurStory] = useState({
    name1: "",
    image1: "",
    positon1: "",
    name2: "",
    image2: "",
    positon2: "",
    index: null,
  });
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);

  const handleNameChange1 = (event) => {
    setName1(event.target.value);
  };

  const handlePositionChange1 = (event) => {
    setPosition1(event.target.value);
  };

  const handleImageChange1 = (event) => {
    setImage1(event.target.files[0]);
  };
  const handleNameChange2 = (event) => {
    setName2(event.target.value);
  };

  const handlePositionChange2 = (event) => {
    setPosition2(event.target.value);
  };

  const handleImageChange2 = (event) => {
    setImage2(event.target.files[0]);
  };

  useEffect(() => {
    getAboutUsOurStory()
      .then((res) => {
        console.log(res);
        setOurStory(res.data.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsUpdating(true);
    const formData = new FormData();
    if (image1 instanceof File) {
      formData.append("image1", image1);
    }
    formData.append("name1", name1);
    formData.append("position1", position1);
    if (image2 instanceof File) {
      formData.append("image2", image2);
    }
    formData.append("name2", name2);
    formData.append("position2", position2);
    try {
      const response = await updateAboutUsOurStory(formData);
      setShowSuccessMessage(true);
      setIsUpdating(false);
      console.log(response.data);
    } catch (error) {
      console.error(error);
      setIsUpdating(false);
    }
  };

  return (
    <FormWrapper>
      <Form onSubmit={handleSubmit}>
        <Title>Edit Our Story section</Title>
        <SubTitle>Edit Members</SubTitle>
        {/* member 1 */}
        <Row>
          <Col md={4}>
            <Form.Group controlId="OurStoryControl">
              <Row>
                <Form.Label>
                  <i>Member 01</i>
                </Form.Label>
              </Row>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handleImageChange1}
                style={{ display: "none" }}
                ref={inputRef1}
              />
              {image1 ? (
                <>
                  <img
                    style={{ maxWidth: "13vw" }}
                    src={URL.createObjectURL(image1)}
                    alt="image1"
                    onClick={() => {
                      setImage1.current.click();
                    }}
                  />
                  <br />
                  <RemoveButtonWrapper>
                    <Button
                      variant="outline-danger"
                      onClick={() => {
                        setImage1(null);
                      }}
                    >
                      Remove your image
                    </Button>
                  </RemoveButtonWrapper>
                </>
              ) : (
                <img
                  style={{
                    maxWidth: "13vw",
                    cursor: "pointer",
                   
                  }}
                  src={ourstory.image1}
                  alt="image1"
                  onClick={() => {
                    inputRef1.current.click();
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.opacity = 0.8;
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.opacity = 1;
                  }}
                />
              )}
            </Form.Group>
          </Col>
          <Col md={8} style={{ marginTop: "2.5vw" }}>
            <Row>
              <Form.Group
                className="mb-3"
                controlId="ourstory-name"
              >
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="input"
                  defaultValue={ourstory.name1}
                  onChange={handleNameChange1}
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="ourstory-position"
              >
                <Form.Label>Position</Form.Label>
                <Form.Control
                  type="input"
                  defaultValue={ourstory.position1}
                  onChange={handlePositionChange1}
                />
              </Form.Group>
            </Row>
          </Col>
        </Row>
        {/* member 2 */}
        <Row>
          <Col md={4}>
            <Form.Group controlId="OurStoryControl">
              <Row>
                <Form.Label>
                  <i>Member 02</i>
                </Form.Label>
              </Row>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handleImageChange2}
                style={{ display: "none" }}
                ref={inputRef2}
              />
              {image2 ? (
                <>
                  <img
                    style={{ maxWidth: "13vw" }}
                    src={URL.createObjectURL(image2)}
                    alt="image1"
                    onClick={() => {
                      setImage2.current.click();
                    }}
                  />
                  <br />
                  <RemoveButtonWrapper>
                    <Button
                      variant="outline-danger"
                      onClick={() => {
                        setImage2(null);
                      }}
                    >
                      Remove your image
                    </Button>
                  </RemoveButtonWrapper>
                </>
              ) : (
                <img
                  style={{
                    maxWidth: "13vw",
                    cursor: "pointer",
                  
                  }}
                  src={ourstory.image2}
                  alt="image1"
                  onClick={() => {
                    inputRef2.current.click();
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.opacity = 0.8;
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.opacity = 1;
                  }}
                />
              )}
            </Form.Group>
          </Col>
          <Col md={8} style={{ marginTop: "2.5vw" }}>
            <Row>
              <Form.Group
                className="mb-3"
                controlId="ourstory-name"
              >
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="input"
                  defaultValue={ourstory.name2}
                  onChange={handleNameChange2}
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="ourstory-position"
              >
                <Form.Label>Position</Form.Label>
                <Form.Control
                  type="input"
                  defaultValue={ourstory.position2}
                  onChange={handlePositionChange2}
                />
              </Form.Group>
            </Row>
          </Col>
        </Row>
        {/* Button Section */}
        <ButtonWrapper>
          <Link
            to="/aboutus/ourstory/view"
            style={{
              width: "50%",
            }}
          >
            <Button variant="secondary">Go Back</Button>
          </Link>
          <Button
            type="submit"
            disabled={isUpdating}
            style={{
              width: "50%",
            }}
          >
            {" "}
            {isUpdating ? "Saving..." : "Save Changes"}
          </Button>
        </ButtonWrapper>
      </Form>
      {/* Show success alert */}
      {showSuccessMessage && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 100,
            }}
          ></div>
          <Alert
            variant="success"
            onClose={() => setShowSuccessMessage(false)}
            dismissible={false}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "25vw",
              height: "auto",
              zIndex: 1000,
            }}
          >
            <Alert.Heading
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "green",
                textAlign: "center",
              }}
            >
              Update Successfull!
            </Alert.Heading>

            <p style={{ textAlign: "center" }}>
              Your our story section has been updated.
            </p>
            <br />
            <div className="d-flex justify-content-end">
              <Button
                variant="outline-success"
                onClick={() => {
                  setShowSuccessMessage(false);
                  navigate("/aboutus/ourstory/view");
                }}
              >
                Ok
              </Button>
            </div>
          </Alert>
        </>
      )}
    </FormWrapper>
  );
}

const FormWrapper = styled.div`
  padding: 0rem 28rem 1rem 28rem;
`;

const Title = styled.h3`
  text-align: center;
  padding: 10px 0;
`;

const SubTitle = styled.h6``;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  gap: 2rem;
`;
const RemoveButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
`;
