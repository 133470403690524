import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import WithNav from "./Components/Header/Nav/WithNav";
import Aboutus from "./Pages/AboutUs/Aboutus";
import ForBusiness from "./Pages/ForBusiness/ForBusiness";
import ContactUs from "./Pages/ContactUs/ContactUs";
import ForIndividuals from "./Pages/ForIndividuals/ForIndividuals";
import Home from "./Pages/Home/Home";
import Resources from "./Pages/Resources/Resources";
import Footer from "./Pages/Footer/Footer";
import Login from "./Pages/Login/Login";
import ForgetPassword from "./Pages/ForgetPassword/ForgetPassword";
import ChangePassword from "./Pages/ForgetPassword/ChangePassword";
import SuccessPage from "./Pages/ForgetPassword/SuccessPage";

import CreateBlog from "./Pages/Resources/CreateBlog";
import EditBlog from "./Pages/Resources/EditBlog";
import EditContactUs from "./Pages/ContactUs/EditContactUs";

import ViewHeader from "./Components/Home/Header/HeaderView";
import ViewSlider1 from "./Components/Home/Slider1/Slider1View";
import ViewSlider2 from "./Components/Home/Slider2/Slider2View";
import ViewParagraph from "./Components/Home/Paragraph/ParagraphView";
import ViewServiceOverview from "./Components/Home/Overview/OverView";
import EditHeader from "./Components/Home/Header/HeaderEdit";
import EditSlider1 from "./Components/Home/Slider1/Slider1Edit";
import EditSlider2 from "./Components/Home/Slider2/Slider2Edit";
import EditParagraph from "./Components/Home/Paragraph/ParagraphEdit";
import EditServiceOverview from "./Components/Home/Overview/OverviewEdit";
// AboutUS
import AboutUsHeaderView from "./Components/AboutUs/Header/HeaderView";
import AboutUsHeaderEdit from "./Components/AboutUs/Header/HeaderEdit";
import AboutUsParagraphView from "./Components/AboutUs/Paragraph/ParagraphView";
import AboutUsParagraphEdit from "./Components/AboutUs/Paragraph/ParagraphEdit";
import AboutUsStoryView from "./Components/AboutUs/OurStory/OurStoryView";
import AboutUsStoryEdit from "./Components/AboutUs/OurStory/OurStoryEdit";
import AboutUsStoryParaView from "./Components/AboutUs/OurStoryPara/OurStoryParaView";
import AboutUsStoryParaEdit from "./Components/AboutUs/OurStoryPara/OurStoryParaEdit";
import AboutUsCardsView from "./Components/AboutUs/StepCards/StepCardsView";
import AboutUsCardsEdit from "./Components/AboutUs/StepCards/StepCardsEdit";
import AboutUsTeamView from "./Components/AboutUs/MeetTeam/MeetTeamView";
import AboutUsTeamEdit from "./Components/AboutUs/MeetTeam/MeetTeamEdit";
import AboutUsTeamParaView from "./Components/AboutUs/MeetTeam/MeetParagraphEdit";
import AboutUsBottomView from "./Components/AboutUs/Bottom/TitleView";
import AboutUsBottomEdit from "./Components/AboutUs/Bottom/TitleEdit";
import AboutUsMissionView from "./Components/AboutUs/Mission/MissionView";
import AboutUsMissionEdit from "./Components/AboutUs/Mission/MissionEdit";

import FooterEdit from "./Pages/Footer/FooterEdit";
//For Business
import ForBusinessHeaderImgEdit from "./Components/ForBusiness/Header/HeaderImgEdit";
import ForBusinessHeaderImgView from "./Components/ForBusiness/Header/HeaderImgView";
import ForBusinesCardEdit from "./Components/ForBusiness/Cards/CardEdit";
import ForBusinesCardView from "./Components/ForBusiness/Cards/CardView";
import ForBusinessParegraphView from "./Components/ForBusiness/HeaderParagraphTwo/ParagraphTwoView";
import ForBusinessParegraphEdit from "./Components/ForBusiness/HeaderParagraphTwo/ParagraphTwoEdit";
import ForBusinessBottomView from "./Components/ForBusiness/BottomParagraph/BottomParagraphView";
import ForBusinessBottomEdit from "./Components/ForBusiness/BottomParagraph/BottomParagraphEdit";

//For Individuals
// import ForIndividualsImgEdit from "./Components/ForIndividuals/Header/EditHeaderImg";
import ForIndividualsParaEdit from "./Components/ForIndividuals/Paragraph/EditPara";
import ForIndividualsParaView from "./Components/ForIndividuals/Paragraph/ViewPara";
import ForIndividualsStepEdit from "./Components/ForIndividuals/Steps/EditSteps";
import ForIndividualsStepView from "./Components/ForIndividuals/Steps/ViewSteps";
import ForIndividualsCommentEdit from "./Components/ForIndividuals/Comments/EditComments";
import ForIndividualsCommentView from "./Components/ForIndividuals/Comments/ViewComments";

import Admin from "./Components/AdminDashboard/Admin";
import UpdateAdmin from "./Components/AdminDashboard/EditAdmin";
import AdminRegister from "./Pages/Register/AdminRegister";

import Buttons from "./Components/Buttons/ButtonsView";
import ButtonsEdit from "./Components/Buttons/ButtonsEdit";
import MeetTeamCreate from "./Components/AboutUs/MeetTeam/MeetTeamCreate";
import { AuthProvider } from "./ContextProvider/AuthProvider/AuthProvider";

// import Coupons from "./Components/CouponDashboard/Coupon";
// import CreateCoupon from "./Pages/Coupon/AddCoupon";
// import UpdateCoupon from "./Components/CouponDashboard/EditCoupon";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Login />}></Route>
        {/* <Route exact path="/coupon/create" element={<CreateCoupon />}></Route> */}

        <Route
          exact
          path="/forgetpassword"
          element={<ForgetPassword />}
        ></Route>
        <Route path="/SuccessPage" element={<SuccessPage />} />
        <Route path="/user/reset" element={<ChangePassword />} />

        <Route exact path="/" element={<Login />}></Route>
      </Routes>
      <AuthProvider>
        <Routes>
        <Route exact path="/admin/register" element={<AdminRegister />}></Route>
          <Route element={<WithNav />}>
            <Route exact path="/home" element={<Home />}></Route>
            <Route exact path="/aboutus" element={<Aboutus />}></Route>
            <Route exact path="/forbusiness" element={<ForBusiness />}></Route>
            <Route
              exact
              path="/forindividuals"
              element={<ForIndividuals />}
            ></Route>
            <Route exact path="/resources" element={<Resources />}></Route>
            <Route exact path="/contactUs" element={<ContactUs />}></Route>
            <Route exact path="/footer" element={<Footer />}></Route>
            <Route
              exact
              path="/footer/update/:id"
              element={<FooterEdit />}
            ></Route>
            <Route exact path="/buttons" element={<Buttons />}></Route>

            <Route
              exact
              path="/buttons/update/:id"
              element={<ButtonsEdit />}
            ></Route>

            <Route
              exact
              path="/resources/create"
              element={<CreateBlog />}
            ></Route>
            <Route
              exact
              path="/resources/update/:id"
              element={<EditBlog />}
            ></Route>

            <Route
              exact
              path="/contactUs/update/:id"
              element={<EditContactUs />}
            ></Route>

            <Route
              exact
              path="/home/header/view/:id"
              element={<ViewHeader />}
            ></Route>
            <Route
              exact
              path="/home/slider1/view/:id"
              element={<ViewSlider1 />}
            ></Route>
            <Route
              exact
              path="/home/slider2/view/:id"
              element={<ViewSlider2 />}
            ></Route>
            <Route
              exact
              path="/home/paragraph/view/:id"
              element={<ViewParagraph />}
            ></Route>
            <Route
              exact
              path="/home/overview/view/:id"
              element={<ViewServiceOverview />}
            ></Route>
            <Route
              exact
              path="/home/header/edit/:id"
              element={<EditHeader />}
            ></Route>
            <Route
              exact
              path="/home/slider1/edit/:id"
              element={<EditSlider1 />}
            ></Route>
            <Route
              exact
              path="/home/slider2/edit/:id"
              element={<EditSlider2 />}
            ></Route>
            <Route
              exact
              path="/home/paragraph/edit/:id"
              element={<EditParagraph />}
            ></Route>
            <Route
              exact
              path="/home/overview/edit/:id"
              element={<EditServiceOverview />}
            ></Route>

            {/* About us */}
            <Route
              exact
              path="/aboutus/header/view"
              element={<AboutUsHeaderView />}
            ></Route>
            <Route
              exact
              path="/aboutus/header/edit"
              element={<AboutUsHeaderEdit />}
            ></Route>
            <Route
              exact
              path="/aboutus/paragraph/view"
              element={<AboutUsParagraphView />}
            ></Route>
            <Route
              exact
              path="/aboutus/paragraph/edit"
              element={<AboutUsParagraphEdit />}
            ></Route>
            <Route
              exact
              path="/aboutus/ourstory/view"
              element={<AboutUsStoryView />}
            ></Route>
            <Route
              exact
              path="/aboutus/ourstory/edit"
              element={<AboutUsStoryEdit />}
            ></Route>
            <Route
              exact
              path="/aboutus/our_story_paragraph/view"
              element={<AboutUsStoryParaView />}
            ></Route>
            <Route
              exact
              path="/aboutus/our_story_paragraph/edit"
              element={<AboutUsStoryParaEdit />}
            ></Route>
            <Route
              exact
              path="/aboutus/stepcards/view"
              element={<AboutUsCardsView />}
            ></Route>
            <Route
              exact
              path="/aboutus/stepcards/edit"
              element={<AboutUsCardsEdit />}
            ></Route>
            <Route
              exact
              path="/aboutus/meet_the_team/view"
              element={<AboutUsTeamView />}
            ></Route>
            <Route
              exact
              path="/aboutus/meet_the_team/edit/:id"
              element={<AboutUsTeamEdit />}
            ></Route>
            <Route
              exact
              path="/aboutus/meet_the_team/create"
              element={<MeetTeamCreate />}
            ></Route>
            <Route
              exact
              path="/aboutus/bottom_title/view"
              element={<AboutUsBottomView />}
            ></Route>
            <Route
              exact
              path="/aboutus/bottom_title/edit"
              element={<AboutUsBottomEdit />}
            ></Route>
            <Route
              exact
              path="/aboutus/mission/view"
              element={<AboutUsMissionView />}
            ></Route>
            <Route
              exact
              path="/aboutus/mission/edit"
              element={<AboutUsMissionEdit />}
            ></Route>
            <Route
              exact
              path="/aboutus/meet_the_team/paragraph/edit"
              element={<AboutUsTeamParaView />}
            ></Route>
            {/* forindividuals */}

            <Route
              exact
              path="/forindividuals/para/edit/:id"
              element={<ForIndividualsParaEdit />}
            ></Route>
            <Route
              exact
              path="/forindividuals/para/view/:id"
              element={<ForIndividualsParaView />}
            ></Route>
            <Route
              exact
              path="/forindividuals/step/edit/:id"
              element={<ForIndividualsStepEdit />}
            ></Route>
            <Route
              exact
              path="/forindividuals/step/view/:id"
              element={<ForIndividualsStepView />}
            ></Route>
            <Route
              exact
              path="/forindividuals/comments/edit/:id"
              element={<ForIndividualsCommentEdit />}
            ></Route>
            <Route
              exact
              path="/forindividuals/comments/view/:id"
              element={<ForIndividualsCommentView />}
            ></Route>

            {/* ForBusiness */}
            <Route
              exact
              path="/forbusiness/headerimg/view/:id"
              element={<ForBusinessHeaderImgView />}
            ></Route>
            <Route
              exact
              path="/forbusiness/headerimg/edit/:id"
              element={<ForBusinessHeaderImgEdit />}
            ></Route>
            <Route
              exact
              path="/forbusiness/card/edit/:id"
              element={<ForBusinesCardEdit />}
            ></Route>
            <Route
              exact
              path="/forbusiness/card/view/:id"
              element={<ForBusinesCardView />}
            ></Route>
            <Route
              exact
              path="/forbusiness/headerparagraphtwo/view/:id"
              element={<ForBusinessParegraphView />}
            ></Route>
            <Route
              exact
              path="/forbusiness/headerparagraphtwo/edit/:id"
              element={<ForBusinessParegraphEdit />}
            ></Route>
            <Route
              exact
              path="/forbusiness/bottomtitle/view/:id"
              element={<ForBusinessBottomView />}
            ></Route>
            <Route
              exact
              path="/forbusiness/bottomtitle/edit/:id"
              element={<ForBusinessBottomEdit />}
            ></Route>

            {/* Admin */}
            <Route exact path="/admin/view" element={<Admin />}></Route>
            <Route
              exact
              path="/admin/view/update/:id"
              element={<UpdateAdmin />}
            ></Route>
            <Route
              exact
              path="/forindividuals/para/edit/:id"
              element={<ForIndividualsParaEdit />}
            ></Route>
            <Route
              exact
              path="/forindividuals/para/view/:id"
              element={<ForIndividualsParaView />}
            ></Route>
            <Route
              exact
              path="/forindividuals/step/edit/:id"
              element={<ForIndividualsStepEdit />}
            ></Route>
            <Route
              exact
              path="/forindividuals/step/view/:id"
              element={<ForIndividualsStepView />}
            ></Route>
            <Route
              exact
              path="/forindividuals/comments/edit/:id"
              element={<ForIndividualsCommentEdit />}
            ></Route>
            <Route
              exact
              path="/forindividuals/comments/view/:id"
              element={<ForIndividualsCommentView />}
            ></Route>
            {/* <Route exact path="/coupons" element={<Coupons />}></Route> */}
            {/* <Route
            exact
            path="/coupons/update/:id"
            element={<UpdateCoupon />}
          ></Route> */}
          </Route>
          {/* forindividuals */}
          {/* <Route
          exact
          path="/forindividuals/headerimg/edit"
          element={<ForIndividualsImgEdit />}
        ></Route> */}
          <Route
            exact
            path="/forindividuals/para/edit/:id"
            element={<ForIndividualsParaEdit />}
          ></Route>
          <Route
            exact
            path="/forindividuals/para/view/:id"
            element={<ForIndividualsParaView />}
          ></Route>
          <Route
            exact
            path="/forindividuals/step/edit/:id"
            element={<ForIndividualsStepEdit />}
          ></Route>
          <Route
            exact
            path="/forindividuals/step/view/:id"
            element={<ForIndividualsStepView />}
          ></Route>
          <Route
            exact
            path="/forindividuals/comments/edit/:id"
            element={<ForIndividualsCommentEdit />}
          ></Route>
          <Route
            exact
            path="/forindividuals/comments/view/:id"
            element={<ForIndividualsCommentView />}
          ></Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
