import { Button, Form } from "react-bootstrap";
import { React, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getAdminById, updateAdminById } from "../../api/axios";
import validator from "validator";
import { Alert } from "react-bootstrap";

const UpdateAdmin = () => {
  const { id } = useParams(); // get the 'id' parameter from the URL
  const navigate = useNavigate();
  // const [resetForm, setResetForm] = useState(false);

  const [data, setData] = useState([]);
  const [admin_name, setAdmin_name] = useState("");
  const [email, setEmail] = useState("");
  const [profile_pic, setProfile_pic] = useState("");

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [saving, setSaving] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    getAdminById(id)
      .then((res) => {
        console.log(res);
        const adminData = res.data.data[0];
        setData(adminData);
        console.log(res.data.data[0].id);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const SendData = (e) => {
    e.preventDefault();
    setSaving(true);

    var formData = new FormData();

    formData.append("admin_name", admin_name || data.admin_name);
    formData.append("email", email || data.email);

    if (profile_pic instanceof File) {
      formData.append("profile_pic", profile_pic);
    }

    updateAdminById(id, formData)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          // alert("Admin details has been updated successfully");
          setShowSuccessMessage(true);
          // setResetForm(!resetForm);
        } else {
          Promise.resolve();
        }
        e.target.reset();
        setSaving(false);
      })

      .then(() => {
        // fetch the updated data
        getAdminById(id)
          .then((res) => {
            console.log(res);
            const adminData = res.data.data[0];
            setData(adminData);
            console.log(res.data.data[0].id);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClick = () => {
    navigate("/admin/view");
  };

  const [emailError, setEmailError] = useState("");
  const validateEmail = (e) => {
    var email = e.target.value;

    if (validator.isEmail(email)) {
      setEmailError("");
    } else {
      setEmailError("Enter valid Email!");
    }
  };

  return (
    <ContentWrapper>
      <Form
        style={{
          width: "50vw",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
        // key={resetForm}
        onSubmit={SendData}
      >
        <Title>EDIT detais of admin {data.admin_name}</Title>
        <br />

        <Form.Group
          controlId="formBasicName"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Form.Label style={{ fontWeight: "bold", cursor: "pointer" }}>
            Click here to change the profile picture
          </Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            onChange={(e) => {
              setProfile_pic(e.target.files[0]);
            }}
            style={{ display: "none" }}
            ref={inputRef}
          />
          {profile_pic ? (
            <img
              style={{ maxWidth: "15vw" }}
              src={URL.createObjectURL(profile_pic)}
              alt="profile_pic"
              onClick={() => {
                setProfile_pic.current.click();
              }}
            />
          ) : (
            <img
              style={{ maxWidth: "15vw" }}
              src={data.profile_pic}
              alt="profile_pic"
              onClick={() => {
                inputRef.current.click();
              }}
            />
          )}
        </Form.Group>
        <br />

        <Form.Group controlId="formBasicName">
          <Form.Label style={{ fontWeight: "bold" }}>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder={data.admin_name}
            value={admin_name || data.admin_name}
            onChange={(e) => {
              setAdmin_name(e.target.value);
            }}
          />
        </Form.Group>
        <br />

        <Form.Group controlId="formBasicName">
          <Form.Label style={{ fontWeight: "bold" }}>Email</Form.Label>
          <Form.Control
            placeholder={data.email}
            value={email || data.email}
            onChange={(e) => {
              setEmail(e.target.value);
              validateEmail(e);
            }}
          />
          <span
            style={{
              color: "red",
            }}
          >
            {emailError}
          </span>

          <br />
        </Form.Group>
        <br />

        <ButtonWrapper>
          <Button
            variant="secondary"
            onClick={handleClick}
            style={{
              cursor: "pointer",
              width: "auto",
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={SendData}
            disabled={saving}
            style={{
              cursor: "pointer",
              width: "auto",
            }}
          >
            {saving ? "Saving..." : "Save Changes"}
          </Button>
        </ButtonWrapper>
      </Form>
      {showSuccessMessage && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 100,
            }}
          ></div>
          <Alert
            variant="success"
            onClose={() => setShowSuccessMessage(false)}
            dismissible={false}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "25vw",
              height: "auto",
              zIndex: 1000,
            }}
          >
            <Alert.Heading
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "green",
                textAlign: "center",
              }}
            >
              Successfully updated!
            </Alert.Heading>
            <p style={{ textAlign: "center" }}>
              Admin details has been saved successfully.
            </p>
            <br />
            <div className="d-flex justify-content-end">
              <Button
              style={{width:"200px"}}
                variant="outline-success"
                onClick={() => {
                  setShowSuccessMessage(false);
                  navigate(`/admin/view`);
                }}
              >
                Ok
              </Button>
            </div>
          </Alert>
        </>
      )}
    </ContentWrapper>
  );
};

export default UpdateAdmin;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Title = styled.h3`
  text-align: center;
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  padding-top: 40px;
  display: flex;
  gap: 5rem;
  justify-content: center;
`;
