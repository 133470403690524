import { Button, Form, Image } from "react-bootstrap";
import { React, useState, useRef } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { createNewBlog } from "../../api/axios";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { Alert } from "react-bootstrap";

function CreateBlog() {
  const navigate = useNavigate();

  const [blog_img, setBlogImg] = useState("");
  const [blog_title, setBlogTitle] = useState("");
  const [blog_content, setBlogContent] = useState("");
  const [blog_readMore, setBlogReadMore] = useState("");
  const [saving, setSaving] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [imageError, setImageError] = useState(null);
  const [titleError, setTitleError] = useState(null);
  const [contentError, setContentError] = useState(null);
  const [readMoreError, setReadMoreError] = useState(null);

  const inputRef = useRef(null);

  const SendData = (e) => {
    e.preventDefault();
    setSaving(true);
  
    // Validate fields
    let imageError = null;
    let titleError = null;
    let contentError = null;
    let readMoreError = null;

    if (!blog_img) {
      imageError = "Image is required";
    } 
  
    if (!blog_title) {
      titleError = "Title is required";
    } else if (blog_title.length > 50) {
      titleError = "Title must be less than 50 characters";
    }
  
    if (!blog_content) {
      contentError = "Content is required";
    } else if (blog_content.length > 300) {
      contentError = "Content must be less than 300 characters";
    }
  
    if (!blog_readMore) {
      readMoreError = "Read More is required";
    }
  
    setImageError(imageError);
    setTitleError(titleError);
    setContentError(contentError);
    setReadMoreError(readMoreError);
  
    // If any errors exist, prevent submission
    if (imageError || titleError || contentError || readMoreError) {
      setSaving(false);
      return;
    }
  
    var formData = new FormData();
    formData.append("blog_title", blog_title);
    formData.append("blog_content", blog_content);
    formData.append("blog_readMore", blog_readMore);
  
    // add updated image data to formData if it exists
    if (blog_img instanceof File) {
      formData.append("blog_img", blog_img);
    }
  
    createNewBlog(formData)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setShowSuccessMessage(true);
        } else {
          Promise.resolve();
        }
        e.target.reset();
        setSaving(false);
      })
      .catch((err) => {
        console.log(err);
        setSaving(false);
      });
  };
  

  const handleClick = () => {
    navigate("/resources");
  };

  return (
    <ContentWrapper>
      
      <Form>
        <Title>Create a new blog</Title>
        {/* new blog */}
        <Form.Group controlId="formBasicName">
            <Form.Label style={{ fontWeight: "bold" }}>Image</Form.Label>
          {blog_img ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              
              <img
                style={{ maxWidth: "15vw", cursor: "pointer" }}
                src={URL.createObjectURL(blog_img)}
                alt="blog_img"
                onClick={() => {
                  setBlogImg(null);
                }}
                onMouseEnter={(e) => {
                e.target.style.opacity = 0.8;
              }}
              onMouseLeave={(e) => {
                e.target.style.opacity = 1;
              }}
              />
             
              <RemoveButtonWrapper>
                <Button
                  variant="outline-danger"
                  onClick={() => setBlogImg(null)}
                >
                  Remove your image
                </Button>
              </RemoveButtonWrapper>
            </div>
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              
              <Form.Control
                type="file"
                accept="image/*"
                onChange={(e) => {
                  setBlogImg(e.target.files[0]);
                }}
                isInvalid={!!imageError}
                ref={inputRef}
              />
              <Image
                style={{ maxWidth: "15vw" , cursor: "pointer"}}
                onClick={() => {
                  inputRef.current.click();
                }}
              />
            </div>
          )}
          <Form.Control.Feedback type="invalid">
            {imageError}
          </Form.Control.Feedback>
        </Form.Group><br/>

        <Form.Group controlId="formBasicName">
          <Form.Label style={{ fontWeight: "bold" }}>Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Blog Title"
            onChange={(e) => {
              setBlogTitle(e.target.value);
            }}
            maxLength="50"
            isInvalid={!!titleError}
          />       

          <Form.Control.Feedback type="invalid">
            {titleError}
          </Form.Control.Feedback>   
        </Form.Group><br/>

        <Form.Group controlId="formBasicName">
          <Form.Label style={{ fontWeight: "bold" }}>Content</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            placeholder="Enter Blog Content"
            maxLength={300}
            onChange={(e) => {
              setBlogContent(e.target.value);
            }}
            isInvalid={!!contentError}
          />

          <Form.Control.Feedback type="invalid">
            {contentError}
          </Form.Control.Feedback>
        </Form.Group><br/>

        <Form.Group controlId="formBasicName">
          <Form.Label style={{ fontWeight: "bold" }}>Read More</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            placeholder="Enter Blog Read More"
            onChange={(e) => {
              setBlogReadMore(e.target.value);
            }}
            isInvalid={!!readMoreError}
          />

          <Form.Control.Feedback type="invalid">
            {readMoreError}
          </Form.Control.Feedback>
        </Form.Group><br/>

        <ButtonWrapper>
          <Button variant="secondary" onClick={handleClick}>
            Go Back
          </Button>
          <Button 
            variant="primary" 
            type="submit" 
            onClick={SendData}
            disabled={saving}
            >
            {saving ? "Saving..." : "Save Changes"}
          </Button>
        </ButtonWrapper>
      </Form>
      {showSuccessMessage && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 100,
            }}
          ></div>
          <Alert
            variant="success"
            onClose={() => setShowSuccessMessage(false)}
            dismissible={false}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "auto",
              height: "auto",
              zIndex: 1000,
              backgroundColor: "white",
              padding: "1rem",
            }}
          >
            <Alert.Heading
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "green",
                textAlign: "center",
              }}
            >
              Blog create successfully!
            </Alert.Heading>

            <p style={{ textAlign: "center" }}>
                Your new blog has been created.
            </p>
            <br />
            <div className="d-flex justify-content-end">
              <Button
                variant="outline-success"
                onClick={() => {
                  setShowSuccessMessage(false);
                  navigate(`/resources`);
                }}
                style={{
                  color: "#ffffff",
                  backgroundColor: "green",
                  width: "20vw",
                }}
              >
                Ok
              </Button>
            </div>
          </Alert>
        </>
      )}
    </ContentWrapper>
  );
}

export default CreateBlog;

const ContentWrapper = styled.div`
  padding: 0rem 22rem 1rem 22rem;
`;

const Title = styled.h3`
  text-align: center;
  padding: 10px 0;
`;

const RemoveButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 50%;
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
`;
