import { Button, Form } from "react-bootstrap";
import { React, useEffect, useState } from "react";
import styled from "styled-components";
import { getFooterById, updateFooter } from "../../api/axios";
import { useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";

export default function FooterEdit() {

  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [data, setData] = useState({
    paragraph: "",
    mailing_address: "",
    phoneNo: "",
    email_address: "",
    faq: "",
    privacy_policy: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    instagram: "",
    pinterest: "",
  });
  // const inputRef1 = useRef(null);

  let [paragraph, setParagraph] = useState(data.paragraph || "");
  let [mailing_address, setMailing_address] = useState(
    data.mailing_address || ""
  );
  let [phoneNo, setPhoneNo] = useState(data.phoneNo || "");
  let [email_address, setEmail_address] = useState(data.email_address || "");
  let [faq] = useState(data.faq || "");
  let [privacy_policy, setPrivacy_policy] = useState(data.privacy_policy || "");
  let [facebook, setFacebook] = useState(data.facebook || "");
  let [twitter, setTwitter] = useState(data.twitter || "");
  let [linkedin, setLinkedin] = useState(data.linkedin || "");
  let [instagram, setInstagram] = useState(data.instagram || "");
  let [pinterest, setPinterest] = useState(data.pinterest || "");

  useEffect(() => {
    getFooterById()
      .then((res) => {
        const footerData = res.data.data[0];
        setData({ ...footerData, index: res.data.data.indexOf(footerData) });
        console.log(footerData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleParagraphChange = (e) => {
    const value = e.target.value;
    const maxWords = 50;
    const wordCount = value.trim().split(/\s+/).length;
    if (wordCount > maxWords) {
      // restrict the user from entering more words than the allowed limit
      e.target.value = value.trim().split(/\s+/).slice(0, maxWords).join(" ");
      setParagraph(e.target.value);
      setErrorMessage(`Word limit exceeded.`);
    } else {
      setParagraph(value);
      setErrorMessage("");
    }
  };

  const SendData = (e) => {
    e.preventDefault();
    setSaving(true);

    var formData = {
      paragraph,
      mailing_address,
      phoneNo,
      email_address,
      faq,
      privacy_policy,
      facebook,
      twitter,
      linkedin,
      instagram,
      pinterest,
    };

    updateFooter(formData)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          // alert("save changes successfully");
          setShowSuccessMessage(true);
          // navigate("/footer");
          console.log(res.data);
        } else {
          Promise.resolve();
        }
        e.target.reset();
        setSaving(false);
      })
      .catch((err) => {
        console.log(err);
        setSaving(false);
      });
  };

  const handleClick = () => {
    navigate("/footer");
  };

  return (
    <ContentWrapper>
      <Form>
        <Title>Edit Footer details</Title>
        <Form.Group>
          <Form.Label style={{ paddingTop: "20px", fontSize: "17px" }}>
            Paragraph
          </Form.Label>
          <textarea
            className="form-control"
            id="textAreaExample1"
            style={{ height: "auto", minHeight: "180px" }}
            defaultValue={data.paragraph}
            placeholder="Enter paragraph"
            onChange={handleParagraphChange}
          ></textarea>
          {errorMessage && (
            <div style={{ color: "red", fontSize: "12px", paddingTop: "5px" }}>
              {errorMessage}
            </div>
          )}
        </Form.Group>

        <Form.Group controlId="FormBasicName">
          <Form.Label style={{ paddingTop: "20px", fontSize: "17px" }}>
            Mailing Address
          </Form.Label>
          <Form.Control
            type="text"
            defaultValue={data.mailing_address}
            style={{ height: "40px" }}
            placeholder="Enter Mailing Address"
            onChange={(e) => {
              setMailing_address(e.target.value);
            }}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="formBasicName">
          <Form.Label style={{ paddingTop: "20px", fontSize: "17px" }}>
            Phone Number
          </Form.Label>
          <Form.Control
            type="text"
            defaultValue={data.phoneNo}
            style={{ height: "40px" }}
            placeholder="Enter Phone Number"
            onChange={(e) => {
              setPhoneNo(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group controlId="FormBasicName">
          <Form.Label style={{ paddingTop: "20px", fontSize: "17px" }}>
            Email
          </Form.Label>
          <Form.Control
            type="email"
            defaultValue={data.email_address}
            style={{ height: "40px" }}
            placeholder="Enter Email"
            onChange={(e) => {
              setEmail_address(e.target.value);
            }}
          />
        </Form.Group>

        <Form.Group controlId="FormBasicName">
          <Form.Label style={{ paddingTop: "20px", fontSize: "17px" }}>
            FAQ
          </Form.Label>
          {/* <Form.Control
          name="faq"
            type="file"
            style={{ height: "40px" }}
            onChange={(e) => {
              setFaq(e.target.files[0]);
            }}
          /> */}
        </Form.Group>

        <Form.Group controlId="FormBasicName">
          <Form.Label style={{ paddingTop: "20px", fontSize: "17px" }}>
            Privacy Policy
          </Form.Label>
          <Form.Control
            name="privacy_policy"
            type="file"
            style={{ height: "40px" }}
            onChange={(e) => {
              setPrivacy_policy(e.target.files[0]);
            }}
          />
        </Form.Group>

        <Form.Group controlId="formBasicName">
          <Form.Label style={{ paddingTop: "20px", fontSize: "17px" }}>
            Social Media
          </Form.Label>
          <TableWrapper>
            <Table>
              <thead>
                <TableRow>
                  <TableHeader>Social Media</TableHeader>
                  <TableHeader>Link</TableHeader>
                </TableRow>
              </thead>
              <TableBody>
                <TableRow>
                  <TableCell>Facebook</TableCell>
                  <TableCell>
                    <Form.Control
                      type="text"
                      defaultValue={data.facebook}
                      style={{ height: "40px" }}
                      placeholder="Enter your Facebook URL"
                      onChange={(e) => {
                        setFacebook(e.target.value);
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Twitter</TableCell>
                  <TableCell>
                    <Form.Control
                      type="text"
                      defaultValue={data.twitter}
                      style={{ height: "40px" }}
                      placeholder="Enter your Twitter URL"
                      onChange={(e) => {
                        setTwitter(e.target.value);
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Linkedin</TableCell>
                  <TableCell>
                    <Form.Control
                      type="text"
                      defaultValue={data.linkedin}
                      style={{ height: "40px" }}
                      placeholder="Enter your Linkedin URL"
                      onChange={(e) => {
                        setLinkedin(e.target.value);
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Instagram</TableCell>
                  <TableCell>
                    <Form.Control
                      type="text"
                      defaultValue={data.instagram}
                      style={{ height: "40px" }}
                      placeholder="Enter your Instagram URL"
                      onChange={(e) => {
                        setInstagram(e.target.value);
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Pinterest</TableCell>
                  <TableCell>
                    <Form.Control
                      type="text"
                      defaultValue={data.pinterest}
                      style={{ height: "40px" }}
                      placeholder="Enter your Pinterest URL"
                      onChange={(e) => {
                        setPinterest(e.target.value);
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableWrapper>
        </Form.Group>

        <ButtonWrapper>
          <Button
            variant="secondary"
            onClick={handleClick}
            style={{ marginTop: "20px", width: "150px" }}
          >
            Go Back
          </Button>
          <Button
            variant="primary"
            onClick={SendData}
            type="submit"
            style={{ marginTop: "20px", width: "150px" }}
            disabled={saving}
          >
            {saving ? "Saving..." : "Save Changes"}
          </Button>
        </ButtonWrapper>
      </Form>
      {showSuccessMessage && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 100,
            }}
          ></div>
          <Alert
            variant="success"
            onClose={() => setShowSuccessMessage(false)}
            dismissible={false}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "25vw",
              height: "auto",
              zIndex: 1000,
            }}
          >
            <Alert.Heading
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "green",
                textAlign: "center",
              }}
            >
              Update Successfull!
            </Alert.Heading>

            <p style={{ textAlign: "center" }}>
              Your footer section has been updated.
            </p>
            <br />
            <div className="d-flex justify-content-end">
              <Button
                variant="outline-success"
                onClick={() => {
                  setShowSuccessMessage(false);
                  navigate("/footer");
                }}
              >
                Ok
              </Button>
            </div>
          </Alert>
        </>
      )}
    </ContentWrapper>
  );
}

const ContentWrapper = styled.div`
  display: grid;
  align-items: center;
  width: 40%;
  margin: 50px 30% 50px 30%;
`;

const Title = styled.h3`
  text-align: center;
`;

const TableWrapper = styled.div`
  margin: 0 auto;
  padding: 10px 0 0 50px;

  .textarea {
    box-sizing: fixed;
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 10px;
  border: 2px solid #ccc;
`;

const TableBody = styled.tbody``;

const ButtonWrapper = styled.tbody`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`;
