import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { getHomeParagraph } from "../../../api/axios";

export default function ParagraphView() {

  const { id } = useParams();

  const [paragraph, setParagraph] = useState([]);

  useEffect(() => {
    getHomeParagraph(id)
      .then((response) => {
        setParagraph(response.data.data[0]);
        console.log(response.data.data[0]);
        console.log(id);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  return (
    <ContentWrapper>
      <HomeTitle>Paragraph Section</HomeTitle>
      <TableWrapper>
        <Table>
          <thead>
            <TableRow>
              <TableHeader>Paragraph Image</TableHeader>
              <TableHeader>Paragraph</TableHeader>
            </TableRow>
          </thead>
          <TableBody>
            <TableRow>
              <TableCell>
                <img
                  src={paragraph.paragraph_image}
                  alt="slider 1"
                  width="350"
                  height="auto"
                />
              </TableCell>
              <TableCell>{paragraph.paragraph}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableWrapper>
      <ButtonWrapper>
        <Link to="/home">
          <Button variant="secondary">Go Back</Button>
        </Link>
        <Link to={`/home/paragraph/edit/${id}`}>
          <Button>Edit paragraph section</Button>
        </Link>
      </ButtonWrapper>
    </ContentWrapper>
  );
}

const ContentWrapper = styled.div`
  padding-bottom: 30px;
  justify-content: center;
`;

const HomeTitle = styled.h3`
  text-align: center;
  padding: 10px 0;
`;

const TableWrapper = styled.div`
  margin: 0 auto;
  padding: 10px 0 0 50px;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 10px;
  border: 2px solid #ccc;
`;

const TableBody = styled.tbody``;

const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  padding-top: 40px;
  display: flex;
  gap: 5rem;
  justify-content: center;
`;
