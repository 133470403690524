import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Alert } from "react-bootstrap";
import { createNewAdmin } from "../../api/axios";
import styled from "styled-components";
import validator from "validator";
import "./reg.css";

const AdminRegister = () => {

  const navigate = useNavigate();

  const [admin_name, setAdmin_name] = useState("");
  const [email, setEmail] = useState("");
  const [profile_pic, setProfile_pic] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [errors, setErrors] = useState({});
  const [registering, setRegistering] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [minPasswordLength] = useState(8);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setRegistering(true);

    if (password !== confirmPassword) {
      setErrors({ confirmPassword: "Password does not match" });
      return;
    } else {
      setErrors("");
    }

    if (password.length < minPasswordLength) {
      setErrors({
        password: `Password must be at least ${minPasswordLength} characters long`,
      });
      return;
    }

    const formData = new FormData();
    formData.append("admin_name", admin_name);
    formData.append("email", email);
    formData.append("profile_pic", profile_pic);
    formData.append("password", password);

    createNewAdmin(formData)
      .then((res) => {
        console.log(res);

        if (res.data.success === true) {
          setShowSuccessMessage(true);
        } else if (res.data.success === false) {
          setShowErrorMsg(true);
        } else {
          Promise.resolve();
        }
        {
          event.target.reset();
          setRegistering(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setRegistering(false);
        alert(err.response.data.message);
      });
  };

  const handleClick = () => {
    navigate("/admin/view");
  };

  const [emailError, setEmailError] = useState("");
  const validateEmail = (e) => {
    var email = e.target.value;

    if (validator.isEmail(email)) {
      setEmailError("");
    } else {
      setEmailError("Enter valid Email!");
    }
  };

  return (
    <ContentWrapper>
      <h2>Admin Registration</h2>
      <div>
        <Form onSubmit={handleFormSubmit}>
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name"
              value={admin_name}
              onChange={(event) => setAdmin_name(event.target.value)}
              required
            />
          </Form.Group>
          <br />

          <Form.Group controlId="email">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
                validateEmail(event);
              }}
              required
            />
            <span
              style={{
                color: "red",
              }}
            >
              {emailError}
            </span>
          </Form.Group>
          <br />

          <Form.Group controlId="profile_pic">
            <Form.Label>Profile picture</Form.Label>
            <Form.Control
              type="file"
              required
              onChange={(event) => setProfile_pic(event.target.files[0])}
            />
          </Form.Group>
          <br />

          <Form.Group controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              required
              minLength={minPasswordLength}
            />
          </Form.Group>
          <br />

          <Form.Group controlId="confirmPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
              required
            />

            {errors.confirmPassword && (
              <span style={{ color: "red" }}>{errors.confirmPassword}</span>
            )}
          </Form.Group>

          <ButtonWrapper>
            <button
              type="button"
              className="btn btn-danger custom-btn close-button"
              onClick={handleClick}
              style={{backgroundColor:"#6c757d", border:"none"}}
            >
              Go back
            </button>
            <button
              type="submit"
              className="btn btn-success custom-btn"
              disabled={registering}
              style={{ backgroundColor:"#0d6efd", border:"none", width: "200px;", height:"40px" }}
            >
              {" "}
              {registering ? "Registering..." : "Register"}
            </button>
          </ButtonWrapper>
        </Form>
      </div>
      {showSuccessMessage && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 100,
            }}
          ></div>
          <Alert
            variant="success"
            onClose={() => setShowSuccessMessage(false)}
            dismissible={false}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "25vw",
              height: "auto",
              zIndex: 1000,
            }}
          >
            <Alert.Heading
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "green",
                textAlign: "center",
              }}
            >
              Registration Successfull!
            </Alert.Heading>

            <p style={{ textAlign: "center" }}>Check your email to verify.</p>
            <br />
            <div className="d-flex justify-content-end">
              <Button
                style={{ width: "200px" }}
                variant="outline-success"
                onClick={() => {
                  setShowSuccessMessage(false);
                  window.location.reload();
                }}
              >
                Ok
              </Button>
            </div>
          </Alert>
        </>
      )}

      {showErrorMsg && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 100,
            }}
          ></div>
          <Alert
            variant="danger"
            onClose={() => setShowErrorMsg(false)}
            dismissible={false}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "25vw",
              height: "auto",
              zIndex: 1000,
            }}
          >
            <Alert.Heading
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "red",
                textAlign: "center",
              }}
            >
              Try again
            </Alert.Heading>

            <p style={{ textAlign: "center" }}>
              User is already exist! Try again with another username and email.
            </p>
            <br />
            <div className="d-flex justify-content-end">
              <Button
                variant="outline-danger"
                onClick={() => {
                  setShowErrorMsg(false);
                  // window.location.reload();
                }}
              >
                Ok
              </Button>
            </div>
          </Alert>
        </>
      )}
    </ContentWrapper>
  );
};

export default AdminRegister;

const ContentWrapper = styled.div`
  font-family: sans-serif;
  font-size: 16px;
  display: grid;
  align-items: center;
  width: 40%;
  margin: 50px 30% 50px 30%;
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  padding-top: 40px;
  display: flex;
  gap: 5rem;
  justify-content: center;
  width: 400px;
`;
