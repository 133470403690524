import { React, useEffect, useState } from "react";
import styled from "styled-components";
import { getAllAdmin, deleteadminById } from "../../api/axios";
import "./admin.css";
import { useNavigate } from "react-router-dom";
import { Alert, Button } from "react-bootstrap";

export default function Admin() {
  const [data, setData] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const navigate = useNavigate();

  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    getAllAdmin()
      .then((res) => {
        setData(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleDeleteClick = (id) => {
    setDeleteId(id);
    setShowAlert(true);
  };

  const handleDeleteConfirm = () => {
    setShowAlert(false);
    if (deleteId) {
      deleteadminById(deleteId)
        .then((res) => {
          if (res.ok) {
            setData(data.filter((admin) => admin.id !== deleteId));
          } else {
            console.log(res);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // Show the success message
    setShowSuccess(true);
    // Hide the confirmation alert
    setShowAlert(false);
  };

  const handleUpdateClick = (id) => {
    navigate(`update/${id}`);
    console.log("clicked");
  };

  const handleClick = () => {
    navigate("/admin/register");
  };

  return (
    <ContentWrapper>
      <Title>Admin Details</Title>
      <TableWrapper>
        <Table>
          <thead>
            <TableRow>
              <TableHeader>ID</TableHeader>
              <TableHeader>Name</TableHeader>
              <TableHeader>Email</TableHeader>
              <TableHeader>Function</TableHeader>
            </TableRow>
          </thead>
          <TableBody>
            {data.map((admin, index) => (
              <TableRow key={index}>
                <TableCell>{admin.id}</TableCell>
                <TableCell>{admin.admin_name}</TableCell>
                <TableCell>{admin.email}</TableCell>
                <TableCell>
                <button
                    type="button"
                    class="btn btn-warning custom-btn"
                    id="EditAdmin"
                    onClick={() => handleUpdateClick(admin.id)}
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger custom-btn"
                    id="DeleteAdmin"
                    onClick={() => handleDeleteClick(admin.id)}
                  >
                    Delete
                  </button>
                  
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <button id="btn2" onClick={handleClick}>
          Register New Admin
        </button>
      </TableWrapper>

      <div
        className="overlay"
        style={{ display: showAlert ? "block" : "none" }}
      ></div>

      <Alert
        show={showAlert}
        variant="danger"
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "25vw",
          height: "auto",
          zIndex: 1000,
        }}
      >
        <Alert.Heading style={{ textAlign: "center" }}>
          Confirm Delete
        </Alert.Heading>
        <p style={{ textAlign: "center" }}>
          Are you sure you want to delete this admin?
        </p>

        <div className="d-flex justify-content-end">
          <button className="btn btn-danger mr-2" onClick={handleDeleteConfirm}>
            Yes
          </button>
          <button
            className="btn btn-secondary"
            style={{ marginLeft: "1em" }}
            onClick={() => setShowAlert(false)}
          >
            No
          </button>
        </div>
      </Alert>
      {showSuccess && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 100,
            }}
          ></div>
          <Alert
            variant="success"
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "25vw",
              height: "auto",
              zIndex: 1000,
            }}
          >
            <Alert.Heading
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "green",
                textAlign: "center",
              }}
            >
              Successfully Deleted!
            </Alert.Heading>

            <p style={{ textAlign: "center" }}>
              Admin has been deleted successfully!
            </p>
            <br />

            <div className="d-flex justify-content-end">
              <Button
                style={{ width: "200px" }}
                variant="outline-success"
                onClick={() => {
                  setShowSuccess(false);
                  window.location.reload();
                }}
              >
                OK
              </Button>
            </div>
          </Alert>
        </>
      )}
    </ContentWrapper>
  );
}

const ContentWrapper = styled.div`
  display: grid;
  align-items: center;
  width: 40%;
  margin: 50px 30% 50px 20%;
`;

const Title = styled.h3`
  text-align: center;
`;

const TableWrapper = styled.div`
  margin: 0 auto;
  padding: 10px 0 0 50px;

  .textarea {
    box-sizing: fixed;
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 200%;
  max-width: 800px;
  margin: 0 0;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 10px;
  border: 2px solid #ccc;
`;

const TableBody = styled.tbody``;

const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`;
