import { Button } from "react-bootstrap";
import { React, useEffect, useState } from "react";
import styled from "styled-components";
import { getFooterById } from "../../api/axios";
import { Link } from "react-router-dom";
import "./footer.css";

const id = 7;

export default function Footer() {

  const [data, setData] = useState({
    phoneNo: "",
    mailing_address: "",
    email_address: "",
    paragraph: "",
    faq: "",
    privacy_policy: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    instagram: "",
    pinterest: "",
  });

  useEffect(() => {
    getFooterById()
      .then((res) => {
        const footetdata = res.data.data[0];
        setData({ ...footetdata, index: res.data.data.indexOf(footetdata) });
        console.log(footetdata);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Title>Footer details</Title>

      <TableWrapper>
        <table>
          <thead>
            <tr>
              <th style={{ width: "20%" }}>Paragraph</th>
              <th style={{ width: "10%" }}>Mailing Address</th>
              <th style={{ width: "10%" }}>Phone Number</th>
              <th style={{ width: "20%" }}>Email</th>
              <th style={{ width: "20%" }}>FAQ</th>
              <th style={{ width: "20%" }}>Privacy Policy</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              {" "}
              <td>{data.paragraph}</td>
              <td>{data.mailing_address}</td>
              <td>{data.phoneNo}</td>
              <td>{data.email_address}</td>
              <td
                style={{
                  maxWidth: "200px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  wordWrap: "break-word",
                }}
              >
                {data.faq}
              </td>
              <td
                style={{
                  maxWidth: "200px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  wordWrap: "break-word",
                }}
              >
                {data.privacy_policy}
              </td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>
      <br />

      <div className="SocialMedia">
        <h5>Social Media</h5>

        <TableWrapper>
          <table>
            <thead>
              <tr>
                <th>Social Media</th>
                <th>Link</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Facebook</td>
                <td>{data.facebook}</td>
              </tr>
              <tr>
                <td>Twitter</td>
                <td>{data.twitter}</td>
              </tr>
              <tr>
                <td>Linkedin</td>
                <td>{data.linkedin}</td>
              </tr>
              <tr>
                <td>Instagram</td>
                <td>{data.instagram}</td>
              </tr>
              <tr>
                <td>Pinterest</td>
                <td>{data.pinterest}</td>
              </tr>
            </tbody>
          </table>
        </TableWrapper>
      </div>

      <Link to={`/footer/update/${id}`}>
        <Button className="FooterEdit" variant="primary" type="submit">
          Edit details
        </Button>
      </Link>
    </>
  );
}

const Title = styled.h2`
  text-align: center;
`;

const TableWrapper = styled.div`
  margin: 0 auto;
  padding: 10px 0 0 50px;
`;
