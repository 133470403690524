import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { getHomeOverview, updateHomeOverview } from "../../../api/axios";
import { Alert } from "react-bootstrap";

export default function OverviewEdit() {
  const { id } = useParams();

  const [icon1, setIcon1] = useState("");
  const [icon2, setIcon2] = useState("");
  const [icon3, setIcon3] = useState("");
  const [icon4, setIcon4] = useState("");
  const [iconTitle1, setIconTitle1] = useState("");
  const [iconTitle2, setIconTitle2] = useState("");
  const [iconTitle3, setIconTitle3] = useState("");
  const [iconTitle4, setIconTitle4] = useState("");
  const [overview, setOverview] = useState({
    icon1: "",
    iconTitle1: "",
    icon2: "",
    iconTitle2: "",
    icon3: "",
    iconTitle3: "",
    icon4: "",
    iconTitle4: "",
    index: null,
  });
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    getHomeOverview(id)
      .then((response) => {
        const overviewData = response.data.data[0];
        setOverview({
          ...overviewData,
          index: response.data.data.indexOf(overviewData),
        });
        console.log(response.data.data[0]);
        console.log(id);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  const SendOverviewData = (e) => {
    e.preventDefault();
    setSaving(true);

    var formData = new FormData();
    formData.append("name1", iconTitle1);
    formData.append("name2", iconTitle2);
    formData.append("name3", iconTitle3);
    formData.append("name4", iconTitle4);

    if (icon1 instanceof File) {
      formData.append("icon1", icon1);
    }

    if (icon2 instanceof File) {
      formData.append("icon2", icon2);
    }

    if (icon3 instanceof File) {
      formData.append("icon3", icon3);
    }

    if (icon3 instanceof File) {
      formData.append("icon4", icon4);
    }

    updateHomeOverview(id, formData)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          //   alert("Service has been updated successfully");
          setShowSuccessMessage(true);
          //   navigate(`/home/overview/view/${id}`);
        } else {
          Promise.resolve();
        }
        e.target.reset();
        setSaving(false);
      })
      .catch((err) => {
        console.log(err);
        setSaving(false);
      });
  };

  return (
    <FormWrapper>
      <Form>
        <Title>Edit Service Overview section</Title>
        <SubTitle>Icon 1</SubTitle>
        <Form.Group
          controlId="formBasicName"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Form.Label style={{ fontWeight: "bold" }}>
            Select an icon 1
          </Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            onChange={(e) => {
              setIcon1(e.target.files[0]);
            }}
            style={{ display: "none" }}
            ref={inputRef1}
          />
          {icon1 ? (
            <>
              <img
                style={{ maxWidth: "15vw" }}
                src={URL.createObjectURL(icon1)}
                alt="icon 1"
                onClick={() => {
                  setIcon1.current.click();
                }}
              />
              <br />
              <RemoveButtonWrapper>
                <Button
                  variant="outline-danger"
                  onClick={() => {
                    setIcon1(null);
                  }}
                >
                  Remove your image
                </Button>
              </RemoveButtonWrapper>
            </>
          ) : (
            <img
              style={{ maxWidth: "15vw", cursor: "pointer" }}
              src={overview.icon1}
              alt="overview_icon1"
              onClick={() => {
                inputRef1.current.click();
              }}
              onMouseEnter={(e) => {
                e.target.style.opacity = 0.8;
              }}
              onMouseLeave={(e) => {
                e.target.style.opacity = 1;
              }}
            />
          )}
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label style={{ fontWeight: "bold" }}>
            Enter a title 1
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            defaultValue={overview.name1}
            onChange={(e) => setIconTitle1(e.target.value)}
          />
        </Form.Group>
        <br />
        <SubTitle>Icon 2</SubTitle>
        <Form.Group
          controlId="formBasicName"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Form.Label style={{ fontWeight: "bold" }}>
            Select an icon 2
          </Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            onChange={(e) => {
              setIcon2(e.target.files[0]);
            }}
            style={{ display: "none" }}
            ref={inputRef2}
          />
          {icon2 ? (
            <>
              <img
                style={{ maxWidth: "15vw" }}
                src={URL.createObjectURL(icon2)}
                alt="icon 2"
                onClick={() => {
                  setIcon2.current.click();
                }}
              />
              <br />
              <RemoveButtonWrapper>
                <Button
                  variant="outline-danger"
                  onClick={() => {
                    setIcon2(null);
                  }}
                >
                  Remove your image
                </Button>
              </RemoveButtonWrapper>
            </>
          ) : (
            <img
              style={{ maxWidth: "15vw", cursor: "pointer" }}
              src={overview.icon2}
              alt="overview_icon2"
              onClick={() => {
                inputRef2.current.click();
              }}
              onMouseEnter={(e) => {
                e.target.style.opacity = 0.8;
              }}
              onMouseLeave={(e) => {
                e.target.style.opacity = 1;
              }}
            />
          )}
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label style={{ fontWeight: "bold" }}>
            Enter a title 2
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            defaultValue={overview.name2}
            onChange={(e) => setIconTitle2(e.target.value)}
          />
        </Form.Group>
        <br />
        <SubTitle>Icon 3</SubTitle>
        <Form.Group
          controlId="formBasicName"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Form.Label style={{ fontWeight: "bold" }}>
            Select an icon 3
          </Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            onChange={(e) => {
              setIcon3(e.target.files[0]);
            }}
            style={{ display: "none" }}
            ref={inputRef3}
          />
          {icon3 ? (
            <>
              <img
                style={{ maxWidth: "15vw" }}
                src={URL.createObjectURL(icon3)}
                alt="icon 3"
                onClick={() => {
                  setIcon3.current.click();
                }}
              />
              <br />
              <RemoveButtonWrapper>
                <Button
                  variant="outline-danger"
                  onClick={() => {
                    setIcon3(null);
                  }}
                >
                  Remove your image
                </Button>
              </RemoveButtonWrapper>
            </>
          ) : (
            <img
              style={{ maxWidth: "15vw", cursor: "pointer" }}
              src={overview.icon3}
              alt="overview_icon3"
              onClick={() => {
                inputRef3.current.click();
              }}
              onMouseEnter={(e) => {
                e.target.style.opacity = 0.8;
              }}
              onMouseLeave={(e) => {
                e.target.style.opacity = 1;
              }}
            />
          )}
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label style={{ fontWeight: "bold" }}>
            Enter a title 3
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            defaultValue={overview.name3}
            onChange={(e) => setIconTitle3(e.target.value)}
          />
        </Form.Group>
        <br />
        <SubTitle>Icon 4</SubTitle>
        <Form.Group
          controlId="formBasicName"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Form.Label style={{ fontWeight: "bold" }}>
            Select an icon 4
          </Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            onChange={(e) => {
              setIcon4(e.target.files[0]);
            }}
            style={{ display: "none" }}
            ref={inputRef4}
          />
          {icon4 ? (
            <>
              <img
                style={{ maxWidth: "15vw" }}
                src={URL.createObjectURL(icon4)}
                alt="icon 4"
                onClick={() => {
                  setIcon1.current.click();
                }}
              />
              <RemoveButtonWrapper>
                <Button
                  variant="outline-danger"
                  onClick={() => {
                    setIcon4(null);
                  }}
                >
                  Remove your image
                </Button>
              </RemoveButtonWrapper>
            </>
          ) : (
            <img
              style={{ maxWidth: "15vw", cursor: "pointer" }}
              src={overview.icon4}
              alt="overview_icon4"
              onClick={() => {
                inputRef4.current.click();
              }}
              onMouseEnter={(e) => {
                e.target.style.opacity = 0.8;
              }}
              onMouseLeave={(e) => {
                e.target.style.opacity = 1;
              }}
            />
          )}
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label style={{ fontWeight: "bold" }}>
            Enter a title 4
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            defaultValue={overview.name4}
            onChange={(e) => setIconTitle4(e.target.value)}
          />
        </Form.Group>
        <br />
        <ButtonWrapper>
          <Link to={`/home/overview/view/${id}`}>
            <Button variant="secondary">Go Back</Button>
          </Link>
          <Button
            variant="primary"
            type="submit"
            onClick={SendOverviewData}
            disabled={saving}
          >
            {saving ? "Saving..." : "Save Changes"}
          </Button>
        </ButtonWrapper>
      </Form>
      {showSuccessMessage && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 100,
            }}
          ></div>
          <Alert
            variant="success"
            onClose={() => setShowSuccessMessage(false)}
            dismissible={false}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "25vw",
              height: "auto",
              zIndex: 1000,
            }}
          >
            <Alert.Heading
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "green",
                textAlign: "center",
              }}
            >
              Update Successfull!
            </Alert.Heading>

            <p style={{ textAlign: "center" }}>
              Service Overview section has been updated.
            </p>
            <br />
            <div className="d-flex justify-content-end">
              <Button
                variant="outline-success"
                onClick={() => {
                  setShowSuccessMessage(false);
                  navigate(`/home/overview/view/${id}`);
                }}
              >
                Ok
              </Button>
            </div>
          </Alert>
        </>
      )}
    </FormWrapper>
  );
}

const FormWrapper = styled.div`
  padding: 0rem 22rem 1rem 22rem;
`;

const Title = styled.h3`
  text-align: center;
  padding: 10px 0;
`;

const SubTitle = styled.h5``;

const RemoveButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 50%;
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
`;
