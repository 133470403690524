import { Button, Form } from "react-bootstrap";
import { React, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getBlogsById, updateBlogsById } from "../../api/axios";
import { Alert } from "react-bootstrap";

const EditBlog = () => {

  const { id } = useParams(); // get the 'id' parameter from the URL
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [blog_img, setBlogImg] = useState("");
  const [blog_title, setBlogTitle] = useState("");
  const [blog_content, setBlogContent] = useState("");
  const [blog_readMore, setBlogReadMore] = useState("");
  const [saving, setSaving] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    getBlogsById(id)
      .then((res) => {
        console.log(res);
        const blogData = res.data.data[0];
        setData(blogData);
        console.log(res.data.data[0].id);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const SendData = (e) => {
    e.preventDefault();
    setSaving(true);

    var formData = new FormData();
    formData.append("blog_title", blog_title);
    formData.append("blog_content", blog_content);
    formData.append("blog_readMore", blog_readMore);

    // add updated image data to formData if it exists
    if (blog_img instanceof File) {
      formData.append("blog_img", blog_img);
    }

    updateBlogsById(id, formData)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setShowSuccessMessage(true);
        } else {
          Promise.resolve();
        }
        e.target.reset();
        setSaving(false);
      })
      .catch((err) => {
        console.log(err);
        setSaving(false);
      });
  };

  const handleClick = () => {
    navigate("/resources");
  };

  return (
    <FormWrapper>
      <Form
        style={{
          width: "50vw",
          margin: "auto",
        }}
      >
        <Title>EDIT BLOG {data.id}</Title>

        {/* blog 1 */}
        <Form.Group controlId="formBasicName">
          <Form.Label style={{ fontWeight: "bold" }}>Image</Form.Label>
          {blog_img ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <img
                style={{ maxWidth: "15vw" }}
                src={URL.createObjectURL(blog_img)}
                alt="blog_img"
                onClick={() => {
                  setBlogImg(null);
                }}
              />
              <br />
              <RemoveButtonWrapper>
                <Button
                  variant="outline-danger"
                  onClick={() => setBlogImg(null)}
                >
                  Remove your image
                </Button>
              </RemoveButtonWrapper>
            </div>
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={(e) => {
                  setBlogImg(e.target.files[0]);
                }}
                style={{ display: "none" }}
                ref={inputRef}
              />
              <img
                style={{ maxWidth: "15vw", cursor: "pointer" }}
                src={data.blog_img}
                alt="blog_img"
                onClick={() => {
                  inputRef.current.click();
                }}
                onMouseEnter={(e) => {
                  e.target.style.opacity = 0.8;
                }}
                onMouseLeave={(e) => {
                  e.target.style.opacity = 1;
                }}
              />
            </div>
          )}
        </Form.Group>
        <br />

        <Form.Group controlId="formBasicName">
          <Form.Label style={{ fontWeight: "bold" }}>Title</Form.Label>
          <Form.Control
            type="text"
            placeholder={data.blog_title}
            defaultValue={data.blog_title} // set the default value
            maxLength="50"
            onChange={(e) => {
              setBlogTitle(e.target.value);
            }}
          />
        </Form.Group>
        <br />

        <Form.Group controlId="formBasicName">
          <Form.Label style={{ fontWeight: "bold" }}>Content</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            placeholder={data.blog_content}
            defaultValue={data.blog_content} // set the default value
            maxLength="300"
            onChange={(e) => {
              setBlogContent(e.target.value);
            }}
          />
        </Form.Group>
        <br />

        <Form.Group controlId="formBasicName">
          <Form.Label style={{ fontWeight: "bold" }}>Read More</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            placeholder={data.blog_readMore}
            defaultValue={data.blog_readMore} // set the default value
            onChange={(e) => {
              setBlogReadMore(e.target.value);
            }}
          />
        </Form.Group>
        <br />

        <ButtonWrapper>
          <Button variant="secondary" onClick={handleClick}>
            Go Back
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={SendData}
            disabled={saving}
          >
            {saving ? "Saving..." : "Save Changes"}
          </Button>
        </ButtonWrapper>
      </Form>
      {showSuccessMessage && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 100,
            }}
          ></div>
          <Alert
            variant="success"
            onClose={() => setShowSuccessMessage(false)}
            dismissible={false}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "25vw",
              height: "auto",
              zIndex: 1000,
              backgroundColor: "white",
              padding: "1rem",
            }}
          >
            <Alert.Heading
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "green",
                textAlign: "center",
              }}
            >
              Update Successfull!
            </Alert.Heading>

            <p style={{ textAlign: "center" }}>Your blog has been updated.</p>
            <br />
            <div className="d-flex justify-content-end">
              <Button
                variant="outline-success"
                onClick={() => {
                  setShowSuccessMessage(false);
                  navigate(`/resources`);
                }}
                style={{
                  color: "#ffffff",
                  backgroundColor: "green",
                  width: "20vw",
                }}
              >
                Ok
              </Button>
            </div>
          </Alert>
        </>
      )}
    </FormWrapper>
  );
};

export default EditBlog;

const FormWrapper = styled.div`
  padding: 0rem 22rem 1rem 22rem;
`;

const Title = styled.h3`
  text-align: center;
  padding: 10px 0;
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
`;

const RemoveButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 50%;
`;
