import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { getAboutUsHeader } from "../../../api/axios";

export default function HeaderView() {
  const [title, setTitle] = useState("");

  const fetchTitle = async () => {
    try {
      const response = await getAboutUsHeader();
      console.log(response.data);
      setTitle(response.data.data[0].paragraph);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchTitle();
  }, []);

  return (
    <ContentWrapper>
      {/* Header */}
      <HomeTitle>Header Section</HomeTitle>
      {/* Table */}
      <TableWrapper>
        <Table>
          <thead>
            <TableRow>
              <TableHeader>Part Name</TableHeader>
              <TableHeader>Content</TableHeader>
            </TableRow>
          </thead>
          <TableBody>
            <TableRow>
              <TableCell>Header Topic</TableCell>
              <TableCell>
                <p>{title}</p>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableWrapper>
      {/* Button Section */}
      <ButtonWrapper>
        <Link
          to="/aboutus"
          style={{
            width: "20%",
          }}
        >
          <Button variant="secondary">Go Back</Button>
        </Link>
        <Link
          to="/aboutus/header/edit"
          style={{
            width: "20%",
          }}
        >
          <Button>Edit Header Section</Button>
        </Link>
      </ButtonWrapper>
    </ContentWrapper>
  );
}

const ContentWrapper = styled.div`
  padding-bottom: 30px;
  justify-content: center;
`;

const HomeTitle = styled.h3`
  text-align: center;
  padding: 10px 0;
`;

const TableWrapper = styled.div`
  margin: 0 auto;
  padding: 10px 0 0 50px;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 10px;
  border: 2px solid #ccc;
`;

const TableBody = styled.tbody``;

const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  padding: 10px 0 0 50px;
  padding-top: 40px;
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
`;
