import React from 'react';
import styled from 'styled-components';
import Preview from './Preview';

function ContactUS() {
  
  return (
    <ContentWrapper>
      <Title>Click button to edit Contact Us page</Title> 
      <Preview/>    
    </ContentWrapper>
  );
}

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
`;

const Title = styled.h3`
  text-align: center;
  padding: 5px 0;
`;

export default ContactUS;