import React, { useEffect } from "react";
import { useState } from "react";
import "../Login/login.css";
import { useNavigate } from "react-router-dom";
import { login } from "../../api/axios";
import { CircularProgress } from "@mui/material";

function Login() {

  useEffect(() => {
      localStorage.clear();
  }, []);

  let navigate = useNavigate();
  //initial state of the input fields
  const initialvalues = { username: "", password: "" };
  const [Values, setValues] = useState(initialvalues);
  const [Errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  //Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...Values, [name]: value });
  };

  //send data
  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validate(Values));
    console.log(Values);
    if (Values.username !== "" && Values.password !== "") {
      setLoading(true);
      var data = {
        admin_name: Values.username,
        password: Values.password,
      };
      login(data)
        .then((res) => {
          console.log(res.data);
          if (res.data.success) {
            localStorage.setItem("userID", res.data.sub.id);
            let token = res.data.token.slice(7);
            console.log(token);
            localStorage.setItem("token", token);
            navigate("/home");
          } else {
            localStorage.clear();
            // window.location.reload();
            let error = {
              message: res.data.message,
            };
            setErrors(error);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  //validation
  const validate = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = "Username is required!";
    }
    if (!values.password) {
      errors.password = "Password is required!";
    } else if (values.password.length < 8) {
      errors.password = "Password must be more than 8 characters!";
    } else if (values.password.length > 50) {
      errors.password = "Password cannot exceed more than 50 characters!";
    }
    return errors;
  };

  return (
    <div className="cont">
      <form>
        <h1>Log In</h1>
        <p>{Errors.message}</p>
        <div className="field">
          <label>Username</label>
          <input
            type="text"
            name="username"
            className="textarea"
            value={Values.username}
            onChange={handleChange}
          />
        </div>
        <p>{Errors.username}</p>
        <div className="field">
          <label>Password</label>
          <input
            type="password"
            name="password"
            className="textarea"
            value={Values.password}
            onChange={handleChange}
          />
        </div>
        <p>{Errors.password}</p>
        <div className="middle">
          <div className="right">
            <label>
              <p>
                Forgot password?
                <a href="/forgetpassword">
                  {" "}
                  <u>Reset your password here</u>
                </a>
              </p>
            </label>
          </div>
        </div>
        <button id="btn1" onClick={handleSubmit}>
          {loading ? <CircularProgress style={{ textAlign: "center", color: "black", height: '30px', width: "30px" }} /> : "Log In"}
        </button>
      </form>
    </div>
  );
}

export default Login;
