import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { getBusinessBottom } from "../../../api/axios";

export default function BottomParagraphView() {
    const { id } = useParams();
    const [bottom, setBottom] = useState([]);

    useEffect(() => {
        getBusinessBottom(id)
            .then((response) => {
                setBottom(response.data.data[0]);
                console.log(response.data.data[0]);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [id]);

    return (
        <ContentWrapper>
            <BottomTitle>Bottom Title Section</BottomTitle>
            <TableWrapper>
                <Table>
                    <thead>
                        <TableRow>
                            <TableHeader>Part Name</TableHeader>
                            <TableHeader>Content</TableHeader>
                        </TableRow>
                    </thead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Bottom Title</TableCell>
                            <TableCell>{bottom.paragraph}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableWrapper>
            <ButtonWrapper>
        <Link to="/forbusiness">
          <Button variant="secondary">Go Back</Button>
        </Link>
        <Link to={`/forbusiness/bottomtitle/edit/${id}`}>
          <Button>Edit bottom section</Button>
        </Link>
      </ButtonWrapper>
        </ContentWrapper>
    );
    }

    const ContentWrapper = styled.div`
    padding-bottom: 30px;
    justify-content: center;
  `;
  
  const BottomTitle = styled.h3`
    text-align: center;
    padding: 10px 0;
  `;
  
  const TableWrapper = styled.div`
    margin: 0 auto;
    padding: 10px 0 0 50px;
  `;
  
  const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  `;
  
  const TableRow = styled.tr`
    &:nth-child(even) {
      background-color: #f2f2f2;
    }
  `;
  
  const TableHeader = styled.th`
    text-align: left;
    padding: 10px;
    border: 2px solid #ccc;
  `;
  
  const TableBody = styled.tbody``;
  
  const TableCell = styled.td`
    padding: 10px;
    border: 1px solid #ddd;
  `;
  
  const ButtonWrapper = styled.div`
    margin: 0 auto;
    padding-top: 40px;
    display: flex;
    gap: 5rem;
    justify-content: center;
  `;
  