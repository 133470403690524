import React, { useState } from "react";
//import HttpCommon from "../../Utils/http-common";
import "./ForgetPassword.css";
import { forgotPassword } from "../../api/axios";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // const res = await HttpCommon.post(`/admin/forgotpassword`, { email });
      console.log(email);
      forgotPassword({ email: email }).then((res) => {
        console.log(res.data.success);
        if (res.data.success !== false) {
          alert("Email Sent");
        } else {
          alert(res.data.message);
        }
      });
    } catch (error) {
      console.error(error);
      alert("Failed to send email");
    }
  };

  return (
    <div className="container my-5">
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <div className="card text-center">
            <div className="card-header">
              <h3 className="text-danger">Forgot Password</h3>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control border border-success light-green-box"
                    placeholder="Enter your email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <button type="submit" className="btn btn-success btn-block">
                  Send Email
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
