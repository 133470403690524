import {Button, Form} from 'react-bootstrap';
import {React,useState,useEffect} from 'react';
import styled from 'styled-components';
import { getcontactUsById, updateContactUs } from '../../api/axios';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Alert } from "react-bootstrap";

function EditContactUs() {

  const { id } = useParams();
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    let [phoneNo, setPhoneNo] = useState(data.phoneNo || "")
    let [mailing_address, setMailing] = useState(data.mailing_address || "")
    let [email_address, setEmail] = useState(data.email_address || "")
    let [header_paragraph, setParagraph] = useState(data.header_paragraph || "")
    const [saving, setSaving] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    useEffect(() => {
      getcontactUsById(id).then((res) => {
        console.log(res);           
        const blogData = res.data.data[0];
        setData(blogData);            
        console.log(res.data.data[0].id);                     
      })
        .catch((err) => {
          console.log(err);
        });
    }, [id]);      
      
   const SendData = (e) => {
        e.preventDefault();
        setSaving(true);

        var formData = {
            phoneNo,
            mailing_address,
            email_address,
            header_paragraph,
        };

        updateContactUs(id, formData).then((res) => {
            console.log(res);
            if (res.status === 200) {
            setShowSuccessMessage(true);
            // alert("Save changes successfully");
            // navigate('/contactUs');
            console.log(res.data);
            } else {
            Promise.resolve();
            }
            e.target.reset();
            setSaving(false);
        })
        .catch((err) => {
            console.log(err);
            setSaving(false);
        });
    };

    const handleClick = () => {
        navigate('/contactUs');
    }

    return (
        <ContentWrapper>
          <Form>
            <Title>Edit Contact US details</Title><br/>
            <Form.Group controlId="formBasicName">
              <Form.Label style={{ fontWeight: "bold" }}>Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter phone number"
                defaultValue= {data.phoneNo} 
                onChange={(e) => {
                  setPhoneNo(e.target.value);
                }}
              />
            </Form.Group><br/>
            <Form.Group controlId="formBasicName">
              <Form.Label style={{ fontWeight: "bold" }}>Mailing Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter mailing address"
                defaultValue={data.mailing_address}
                onChange={(e) => {
                  setMailing(e.target.value);
                }}
              />
            </Form.Group><br/>
            <Form.Group controlId="formBasicName">
              <Form.Label style={{ fontWeight: "bold" }}>Email Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter email address"
                defaultValue={data.email_address}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Form.Group><br/>
            <Form.Group controlId="formBasicName">
              <Form.Label style={{ fontWeight: "bold" }}>Paragraph</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="Enter paragraph"
                defaultValue={data.header_paragraph}
                maxLength="60"
                onChange={(e) => {
                  setParagraph(e.target.value);
                }}
              />
            </Form.Group><br/>
      
            <ButtonWrapper>
                <Button variant="secondary" onClick={handleClick} >Close</Button>
                <Button
                  variant="primary"
                  type="submit"                  
                  onClick={SendData}
                  disabled={saving}
                >
                  {saving ? "Saving..." : "Save Changes"}
            </Button>
            </ButtonWrapper> 
          </Form>
          {showSuccessMessage && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 100,
            }}
          ></div>
          <Alert
            variant="success"
            onClose={() => setShowSuccessMessage(false)}
            dismissible={false}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "25vw",
              height: "auto",
              zIndex: 1000,
              backgroundColor: "white",
              padding: "1rem",
            }}
          >
            <Alert.Heading
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "green",
                textAlign: "center",
              }}
            >
              Update Successfull!
            </Alert.Heading>

            <p style={{ textAlign: "center" }}>
              Your Contact us section has been updated.
            </p>
            <br />
            <div className="d-flex justify-content-end">
              <Button
                variant="outline-success"
                onClick={() => {
                  setShowSuccessMessage(false);
                  navigate(`/contactUs`);
                }}
                style={{
                  color: "#ffffff",
                  backgroundColor: "green",
                  width: "20vw",
                }}
              >
                Ok
              </Button>
            </div>
          </Alert>
        </>
      )}
        </ContentWrapper>
    );
      
}

export default EditContactUs;

const ContentWrapper = styled.div`
    /* display: flex;
    justify-content: center; */
    padding: 0rem 22rem 1rem 22rem;
`;

const Title = styled.h3`
  text-align: center;
`;

const ButtonWrapper = styled.div`
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5rem;
`;