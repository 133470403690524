import React, { useEffect } from "react";
import styled from "styled-components";

const SuccessPage = () => {
  useEffect(() => {
    if (
      localStorage.getItem("userID") === null &&
      localStorage.getItem("token") === null
    ) {
      localStorage.clear();
      const link = process.env.REACT_APP_REDIRECT
      console.log(link);
      window.location.href = link;
    }
  }, []);
  return (
    <div className="main">
      <Box>
        <div className="row g-0">
          <Image>
            <img
              src={require("../../Assets/pawprint-icon.png")}
              className="deltamaxlogo"
              alt="logo"
            />
          </Image>

          <div className="row g-0">
            <div
              className="d-flex align-items-center mb-3 pb-1"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <i
                className="fas fa-cubes fa-2x me-3"
                style={{ color: "#ff6219" }}
              ></i>
              <h1
                className="foget-password-header"
                style={{ marginBottom: 50 }}
              >
                Password Successfully Changed
              </h1>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default SuccessPage;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 5;
  padding-bottom: 7;
  border-radius: 25px;
  background-color: hsla(242, 40%, 37%, 1);
  box-shadow: 5px 5px 10px #ccc;
  :hover {
    box-shadow: 10px 10px 20px #ccc;
  }
`;

const Image = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  img {
    margin-top: 50px;
    margin-bottom: 50px;
  }
`;
