import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";
import styled from "styled-components";

import {
  getForIndividualsStep,
  updateForIndividualsStep,
} from "../../../api/axios";
const id = 1;

export default function EditSteps() {
  const [step1, setStep_1] = useState("");
  const [step2, setStep_2] = useState("");
  const [step3, setStep_3] = useState("");
  const [step4, setStep_4] = useState("");
  const [data, setStepcard] = useState({
    step1: "",
    step2: "",
    step3: "",
    step4: "",
    index: null,
  });

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();
  const [wordLimitExceeded1, setWordLimitExceeded1] = useState(false);
  const [wordLimitExceeded2, setWordLimitExceeded2] = useState(false);
  const [wordLimitExceeded3, setWordLimitExceeded3] = useState(false);
  const [wordLimitExceeded4, setWordLimitExceeded4] = useState(false);

  useEffect(() => {
    getForIndividualsStep()
      .then((res) => {
        console.log(res);
        // console.log(res.data.data[0]);
        setStepcard(res.data.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleParagraph1Change = (e) => {
    setStep_1(e.target.value);
    if (e.target.value.split(" ").length > 50 || e.target.value.length > 300) {
      setWordLimitExceeded1(true);
    } else {
      setWordLimitExceeded1(false);
    }
  };
  const handleParagraph2Change = (e) => {
    setStep_2(e.target.value);
    if (e.target.value.split(" ").length > 50 || e.target.value.length > 300) {
      setWordLimitExceeded2(true);
    } else {
      setWordLimitExceeded2(false);
    }
  };
  const handleParagraph3Change = (e) => {
    setStep_3(e.target.value);
    if (e.target.value.split(" ").length > 50 || e.target.value.length > 300) {
      setWordLimitExceeded3(true);
    } else {
      setWordLimitExceeded3(false);
    }
  };
  const handleParagraph4Change = (e) => {
    setStep_4(e.target.value);
    if (e.target.value.split(" ").length > 50 || e.target.value.length > 300) {
      setWordLimitExceeded4(true);
    } else {
      setWordLimitExceeded4(false);
    }
  };

  const SendStepData = (e) => {
    e.preventDefault();
    if (wordLimitExceeded1) return;
    if (wordLimitExceeded2) return;
    if (wordLimitExceeded3) return;
    if (wordLimitExceeded4) return;
    setSaving(true);

    var formData = {
      step1,
      step2,
      step3,
      step4,   
    };

    updateForIndividualsStep(formData).then((res) => {
        console.log(res);
        if (res.status === 200) {
           setShowSuccessMessage(true);
        //alert("Save changes successfully");
        // navigate('/contactus');
        console.log(res.data);
        } else {
        Promise.resolve();
        }
        e.target.reset();
        setSaving(false);
    })
    .catch((err) => {
        console.log(err);
        setSaving(false);
    });
};

  return (
    <FormWrapper>
      <Form>
        <Title>Edit Steps Section</Title>
        <SubTitle >Step 1</SubTitle>
        {/* Card 01 */}
        <Form.Group
          controlId="formBasicName"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label style={{ fontWeight: "bold" }}>Enter a new content</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder={data.step1}
            defaultValue={data.step1}
            onChange={handleParagraph1Change}
            isInvalid={wordLimitExceeded1}
          />
          {wordLimitExceeded1 && (
            <Form.Control.Feedback type="invalid">
              Word limit exceeded.
            </Form.Control.Feedback>
          )}
        </Form.Group>

        {/* Card 02 */}
        <SubTitle >Step 2</SubTitle>
        <Form.Group
          controlId="formBasicName"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
         
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label style={{ fontWeight: "bold" }}>Enter a new content</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder={data.step2}
            defaultValue={data.step2}
            onChange={handleParagraph2Change}
            isInvalid={wordLimitExceeded2}
          />
          {wordLimitExceeded2 && (
            <Form.Control.Feedback type="invalid">
              Word limit exceeded.
            </Form.Control.Feedback>
          )}
        </Form.Group>

        {/* Card 03 */}
        <SubTitle >Step 3</SubTitle>
        <Form.Group
          controlId="formBasicName"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
         
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label style={{ fontWeight: "bold" }}>Enter a new content</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder={data.step3}
            defaultValue={data.step3}
            onChange={handleParagraph3Change}
            isInvalid={wordLimitExceeded3}
          />
          {wordLimitExceeded3 && (
            <Form.Control.Feedback type="invalid">
              Word limit exceeded.
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <SubTitle >Step 4</SubTitle>
{/* Card 4 */}
<Form.Group
          controlId="formBasicName"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label style={{ fontWeight: "bold" }}>Enter a new content</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder={data.step4}
            defaultValue={data.step4}
            onChange={handleParagraph4Change}
            isInvalid={wordLimitExceeded4}
          />
          {wordLimitExceeded4 && (
            <Form.Control.Feedback type="invalid">
              Word limit exceeded.
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <ButtonWrapper>
        
          <Link to={`/forindividuals/step/view/${id}`}>
            <Button variant="secondary">Go Back</Button>
          </Link>
          
            <Button variant="primary"
          type="submit"
          onClick={SendStepData}
          disabled={saving ||
              wordLimitExceeded1 ||
              wordLimitExceeded2 ||
              wordLimitExceeded3 ||
              wordLimitExceeded4
              }

          >
          {saving ? "Saving..." : "Save Changes"}</Button>
          
        </ButtonWrapper>
      </Form>
      {showSuccessMessage && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 100,
            }}
          ></div>
          <Alert
            variant="success"
            onClose={() => setShowSuccessMessage(false)}
            dismissible={false}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "25vw",
              height: "auto",
              zIndex: 1000,
            }}
          >
            <Alert.Heading
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "green",
                textAlign: "center",
              }}
            >
              Update Successfull!
            </Alert.Heading>

            <p style={{ textAlign: "center" }}>
              Your step cards section has been updated.
            </p>
            <br />
            <div className="d-flex justify-content-end">
              <Button
                variant="outline-success"
                onClick={() => {
                  setShowSuccessMessage(false);
                  navigate(`/forindividuals/step/view/${id}`);
                }}
              >
                Ok
              </Button>
            </div>
          </Alert>
        </>
      )}
    </FormWrapper>
  );
}

const FormWrapper = styled.div`
  padding: 0rem 22rem 1rem 22rem;
`;

const Title = styled.h3`
  text-align: center;
  padding: 10px 0;
`;

const SubTitle = styled.h5``;

const ButtonWrapper = styled.div`
 margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
  
`;
