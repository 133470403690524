import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Alert } from "react-bootstrap";
import { getBusinessBottom, updateBusinessBottom } from "../../../api/axios";

export default function BottomParagraphEdit() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [paragraph, setParagraph] = useState("");
    const [bottom, setBottom] = useState([]);
    const [saving, setSaving] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    useEffect(() => {
        getBusinessBottom(id)
            .then((response) => {
                const bottomData = response.data.data[0];
                setBottom(bottomData);
                console.log(response.data.data[0]);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [id]);

    const SendBottomData = (e) => {
        e.preventDefault();
        setSaving(true);

        const formData = {
            paragraph: paragraph,
        }

        updateBusinessBottom(id, formData)
            .then((response) => {
                console.log(response);
                if(response.status === 200) {
                    setShowSuccessMessage(true);
                    console.log("success");
                } else {
                    Promise.resolve();
                }
                e.target.reset();
                setSaving(false);
            })
            .catch((error) => {
                console.log(error);
                setSaving(false);
            });
    };

  return (
    <FormWrapper>
      <Title>Edit Bottom Title Section</Title>
      <Form>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label style={{ fontWeight: "bold" }}>
            Enter a bottom title
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            defaultValue={bottom.paragraph}
            onChange={(e) => setParagraph(e.target.value)}
          />
        </Form.Group>
        <br />
        <ButtonWrapper>
          <Link to={`/forbusiness/bottomtitle/view/${id}`}>
            <Button variant="secondary">Go Back</Button>
          </Link>
          <Button
            variant="primary"
            type="submit"
            onClick={SendBottomData}
            disabled={saving}
          >
            {saving ? "Saving..." : "Save Changes"}
          </Button>
        </ButtonWrapper>
      </Form>
      {showSuccessMessage && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 100,
            }}
          ></div>
          <Alert
            variant="success"
            onClose={() => setShowSuccessMessage(false)}
            dismissible={false}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "25vw",
              height: "auto",
              zIndex: 1000,
            }}
          >
            <Alert.Heading
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "green",
                textAlign: "center",
              }}
            >
              Update Successfull!
            </Alert.Heading>

            <p style={{ textAlign: "center" }}>
              Your bottom section has been updated.
            </p>
            <br />
            <div className="d-flex justify-content-end">
              <Button
                variant="outline-success"
                onClick={() => {
                  setShowSuccessMessage(false);
                    navigate(`/forbusiness/bottomtitle/view/${id}`);
                }}
              >
                Ok
              </Button>
            </div>
          </Alert>
        </>
      )}
    </FormWrapper>
  );
}

const FormWrapper = styled.div`
  padding: 0rem 22rem 1rem 22rem;
`;

const Title = styled.h3`
  text-align: center;
  padding: 10px 0;
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
`;
