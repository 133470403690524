import React, { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { updateAboutUsStepCards, getAboutUsStepCards, } from "../../../api/axios";
import { Alert } from "react-bootstrap";

export default function StepCardsEdit() {
  const [header1, setHeader1] = useState("");
  const [image1, setImage1] = useState("");
  const [btn_content1, setBtnContent1] = useState("");
  const [header2, setHeader2] = useState("");
  const [image2, setImage2] = useState("");
  const [btn_content2, setBtnContent2] = useState("");
  const [header3, setHeader3] = useState("");
  const [image3, setImage3] = useState("");
  const [btn_content3, setBtnContent3] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [wordLimitExceeded, setWordLimitExceeded] = useState(false);
  const [wordLimitExceeded2, setWordLimitExceeded2] = useState(false);
  const [wordLimitExceeded3, setWordLimitExceeded3] = useState(false);

  const [stepCards, setStepCards] = useState({
    header1: "",
    image1: "",
    btn_content1: "",
    header2: "",
    image2: "",
    btn_content2: "",
    header3: "",
    image3: "",
    btn_content3: "",
  });
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);

  const handleHeaderChange1 = (event) => {
    setHeader1(event.target.value);
  };
  const handleIamgeChange1 = (event) => {
    setImage1(event.target.files[0]);
  };
  const handleBtnChange1 = (event) => {
    setBtnContent1(event.target.value);
    if (
      event.target.value.split(" ").length > 50 ||
      event.target.value.length > 30
    ) {
      setWordLimitExceeded(true);
    } else {
      setWordLimitExceeded(false);
    }
  };
  const handleHeaderChange2 = (event) => {
    setHeader2(event.target.value);
  };
  const handleIamgeChange2 = (event) => {
    setImage2(event.target.files[0]);
  };
  const handleBtnChange2 = (event) => {
    setBtnContent2(event.target.value);
    if (
      event.target.value.split(" ").length > 50 ||
      event.target.value.length > 30
    ) {
      setWordLimitExceeded2(true);
    } else {
      setWordLimitExceeded2(false);
    }
  };
  const handleHeaderChange3 = (event) => {
    setHeader3(event.target.value);
  };
  const handleIamgeChange3 = (event) => {
    setImage3(event.target.files[0]);
  };
  const handleBtnChange3 = (event) => {
    setBtnContent3(event.target.value);
    if (
      event.target.value.split(" ").length > 50 ||
      event.target.value.length > 30
    ) {
      setWordLimitExceeded3(true);
    } else {
      setWordLimitExceeded3(false);
    }
  };
  const [isUpdating, setIsUpdating] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getAboutUsStepCards()
      .then((res) => {
        console.log(res);
        setStepCards(res.data.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (wordLimitExceeded) return;
    if (wordLimitExceeded2) return;
    if (wordLimitExceeded3) return;
    setIsUpdating(true);
    const formData = new FormData();
    if (image1 instanceof File) {
      formData.append("image1", image1);
    }
    formData.append("header1", header1);
    formData.append("btn_content1", btn_content1);
    if (image2 instanceof File) {
      formData.append("image2", image2);
    }
    formData.append("header2", header2);
    formData.append("btn_content2", btn_content2);
    if (image3 instanceof File) {
      formData.append("image3", image3);
    }
    formData.append("header3", header3);
    formData.append("btn_content3", btn_content3);
    try {
      
      const response = await updateAboutUsStepCards(formData);
      setShowSuccessMessage(true);
      console.log(response.data);
      setIsUpdating(false);
    } catch (error) {
      console.error(error);
      setIsUpdating(false);
    }
  };

  return (
    <FormWrapper>
      <Form onSubmit={handleSubmit}>
        <Title>Edit Step Cards Section</Title>
        <SubTitle>Edit Cards</SubTitle>
        {/* Card 01 */}
        <Row>
          <Col md={4}>
            <Form.Group controlId="StepCardsControl">
              <Form.Label>
                <i>Card 01</i>
              </Form.Label>
              <Row>
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={handleIamgeChange1}
                  style={{ display: "none" }}
                  ref={inputRef1}
                />
                {image1 ? (
                  <>
                    <img
                      style={{ maxWidth: "13vw", borderRadius: "8px" }}
                      src={URL.createObjectURL(image1)}
                      alt="image1"
                      onClick={() => {
                        setImage1.current.click();
                      }}
                    />
                    <br />
                    <RemoveButtonWrapper>
                      <Button
                        variant="outline-danger"
                        onClick={() => {
                          setImage1(null);
                        }}
                      >
                        Remove your image
                      </Button>
                    </RemoveButtonWrapper>
                  </>
                ) : (
                  <img
                    style={{
                      maxWidth: "13vw",
                      cursor: "pointer",
                      borderRadius: "8px",
                    }}
                    src={stepCards.image1}
                    alt="image1"
                    onClick={() => {
                      inputRef1.current.click();
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.opacity = 0.8;
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.opacity = 1;
                    }}
                  />
                )}
              </Row>
            </Form.Group>
          </Col>
          <Col md={8} style={{ marginTop: "2.5vw" }}>
            <Form.Group className="mb-3" controlId="CardHeader">
              <Form.Label>Card Header</Form.Label>
              <Form.Control
                type="input"
                defaultValue={stepCards.header1}
                onChange={handleHeaderChange1}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="ButtonContent">
              <Form.Label>Button Content</Form.Label>
              <Form.Control
                type="input"
                defaultValue={stepCards.btn_content1}
                onChange={handleBtnChange1}
                isInvalid={wordLimitExceeded}
              />
                 {wordLimitExceeded && (
            <Form.Control.Feedback type="invalid">
              Word limit exceeded.
            </Form.Control.Feedback>
          )}
            </Form.Group>
          </Col>
        </Row>
        {/* Card 02 */}
        <Row>
          <Col md={4}>
            <Form.Group controlId="StepCardsControl">
              <Form.Label>
                <i>Card 02</i>
              </Form.Label>
              <Row>
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={handleIamgeChange2}
                  style={{ display: "none" }}
                  ref={inputRef2}
                />
                {image2 ? (
                  <>
                    <img
                      style={{ maxWidth: "13vw", borderRadius: "8px" }}
                      src={URL.createObjectURL(image2)}
                      alt="image1"
                      onClick={() => {
                        setImage2.current.click();
                      }}
                    />
                    <br />
                    <RemoveButtonWrapper>
                      <Button
                        variant="outline-danger"
                        onClick={() => {
                          setImage2(null);
                        }}
                      >
                        Remove your image
                      </Button>
                    </RemoveButtonWrapper>
                  </>
                ) : (
                  <img
                    style={{
                      maxWidth: "13vw",
                      cursor: "pointer",
                      borderRadius: "8px",
                    }}
                    src={stepCards.image2}
                    alt="image2"
                    onClick={() => {
                      inputRef2.current.click();
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.opacity = 0.8;
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.opacity = 1;
                    }}
                  />
                )}
              </Row>
            </Form.Group>
          </Col>
          <Col md={8} style={{ marginTop: "2.5vw" }}>
            <Form.Group className="mb-3" controlId="CardHeader">
              <Form.Label>Card Header</Form.Label>
              <Form.Control
                type="input"
                defaultValue={stepCards.header2}
                onChange={handleHeaderChange2}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="ButtonContent">
              <Form.Label>Button Content</Form.Label>
              <Form.Control
                type="input"
                defaultValue={stepCards.btn_content2}
                onChange={handleBtnChange2}
                isInvalid={wordLimitExceeded2}
              />
                 {wordLimitExceeded2 && (
            <Form.Control.Feedback type="invalid">
              Word limit exceeded.
            </Form.Control.Feedback>
          )}
            </Form.Group>
          </Col>
        </Row>
        {/* Card 03 */}
        <Row>
          <Col md={4}>
            <Form.Group controlId="StepCardsControl">
              <Form.Label>
                <i>Card 03</i>
              </Form.Label>
              <Row>
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={handleIamgeChange3}
                  style={{ display: "none" }}
                  ref={inputRef3}
                />
                {image3 ? (
                  <>
                    <img
                      style={{ maxWidth: "13vw", borderRadius: "8px" }}
                      src={URL.createObjectURL(image3)}
                      alt="image3"
                      onClick={() => {
                        setImage3.current.click();
                      }}
                    />
                    <br />
                    <RemoveButtonWrapper>
                      <Button
                        variant="outline-danger"
                        onClick={() => {
                          setImage3(null);
                        }}
                      >
                        Remove your image
                      </Button>
                    </RemoveButtonWrapper>
                  </>
                ) : (
                  <img
                    style={{
                      maxWidth: "13vw",
                      cursor: "pointer",
                      borderRadius: "8px",
                    }}
                    src={stepCards.image3}
                    alt="image3"
                    onClick={() => {
                      inputRef3.current.click();
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.opacity = 0.8;
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.opacity = 1;
                    }}
                  />
                )}
              </Row>
            </Form.Group>
          </Col>
          <Col md={8} style={{ marginTop: "2.5vw" }}>
            <Form.Group className="mb-3" controlId="CardHeader">
              <Form.Label>Card Header</Form.Label>
              <Form.Control
                type="input"
                defaultValue={stepCards.header3}
                onChange={handleHeaderChange3}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="ButtonContent">
              <Form.Label>Button Content</Form.Label>
              <Form.Control
                type="input"
                defaultValue={stepCards.btn_content3}
                onChange={handleBtnChange3}
                isInvalid={wordLimitExceeded3}
              />
                 {wordLimitExceeded3 && (
            <Form.Control.Feedback type="invalid">
              Word limit exceeded.
            </Form.Control.Feedback>
          )}
            </Form.Group>
          </Col>
        </Row>
        {/* Button section */}
        <ButtonWrapper>
          <Link
            to="/aboutus/stepcards/view"
            style={{
              width: "50%",
            }}
          >
            <Button variant="secondary">Go Back</Button>
          </Link>
          <Button
            type="submit"
            disabled={isUpdating || wordLimitExceeded ||wordLimitExceeded2 || wordLimitExceeded3}
            style={{
              width: "50%",
            }}
          >
            {" "}
            {isUpdating ? "Saving..." : "Save Changes"}
          </Button>
        </ButtonWrapper>
      </Form>
      {/* Show success alert */}
      {showSuccessMessage && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 100,
            }}
          ></div>
          <Alert
            variant="success"
            onClose={() => setShowSuccessMessage(false)}
            dismissible={false}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "25vw",
              height: "auto",
              zIndex: 1000,
            }}
          >
            <Alert.Heading
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "green",
                textAlign: "center",
              }}
            >
              Update Successfull!
            </Alert.Heading>

            <p style={{ textAlign: "center" }}>
              Your step cards section has been updated.
            </p>
            <br />
            <div className="d-flex justify-content-end">
              <Button
                variant="outline-success"
                onClick={() => {
                  setShowSuccessMessage(false);
                  navigate("/aboutus/stepcards/view");
                }}
              >
                Ok
              </Button>
            </div>
          </Alert>
        </>
      )}
    </FormWrapper>
  );
}

const FormWrapper = styled.div`
  padding: 0rem 28rem 1rem 28rem;
`;

const Title = styled.h3`
  text-align: center;
  padding: 10px 0;
`;

const SubTitle = styled.h6``;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  gap: 2rem;
`;
const RemoveButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13vw;
`;
