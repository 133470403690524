export const NavData = [
  {
    title: "Home",
    link: "/home",
  },
  {
    title: "For Business",
    link: "/forbusiness",
  },
  {
    title: "For Individuals",
    link: "/forindividuals",
  },
  {
    title: "About Us",
    link: "/aboutus",
  },
  {
    title: "Resources",
    link: "/resources",
  },
  {
    title: "Contact Us",
    link: "/contactUs",
  },
  {
    title: "Footer",
    link: "/footer",
  },
  {
    title: "Admin",
    link: "/admin/view",
  },
  {
    title: "Buttons",
    link: "/buttons",
  },
  // {
  //   title: "Discount Info",
  //   link: "/coupons",
  // },
  // {
  //   title: "",
  //   link: "",
  // },
  // {
  //   title: "",
  //   link: "",
  // },
  // {
  //   title: "Log Out",
  //   link: "/",
  // },
];
