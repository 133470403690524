import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  updateAboutUsMission,
  getAboutUsMission,
} from "../../../api/axios";
import { Alert } from "react-bootstrap";

export default function MissionEdit() {
  const [newPara, setNewPara] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const navigate = useNavigate();
  const [paragraph, setParagraph] = useState({
    paragraph: "",
    index: null,
  });
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [wordLimitExceeded, setWordLimitExceeded] = useState(false);

  useEffect(() => {
    getAboutUsMission()
      .then((response) => {
        const paraData = response.data.data[0];
        setParagraph({
          ...paraData,
          index: response.data.data.indexOf(paraData),
        });
        console.log(response.data.data[0].paragraph);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleParaChange = (event) => {
    setNewPara(event.target.value);
    if (
      event.target.value.split(" ").length > 50 ||
      event.target.value.length > 300
    ) {
      setWordLimitExceeded(true);
    } else {
      setWordLimitExceeded(false);
    }
  };

  const updateTitle = async () => {
    setIsUpdating(true);
    if (wordLimitExceeded) return;
    try {
      const response = await updateAboutUsMission({ paragraph: newPara });
      console.log(response.data);
      setShowSuccessMessage(true);
      setIsUpdating(false);
    } catch (error) {
      console.error(error);
      setIsUpdating(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateTitle();
  };

  return (
    <FormWrapper>
      <Form onSubmit={handleSubmit}>
        {/* Title */}
        <Title>Edit Mission section</Title>
        {/* Form */}
        <Form.Group className="mb-3" controlId="Paragraph-Title">
          <Form.Label>Enter a New Mission Paragraph</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            defaultValue={paragraph.paragraph}
            placeholder={paragraph.paragraph}
            onChange={handleParaChange}
            isInvalid={wordLimitExceeded}
          />
          {wordLimitExceeded && (
            <Form.Control.Feedback type="invalid">
              Word limit exceeded.
            </Form.Control.Feedback>
          )}
        </Form.Group>
        {/* Button Section */}
        <ButtonWrapper>
          <Link
            to="/aboutus/mission/view"
            style={{
              width: "50%",
            }}
          >
            <Button variant="secondary">Go Back</Button>
          </Link>
          <Button
            type="submit"
            style={{
              width: "50%",
            }}
            disabled={isUpdating || wordLimitExceeded}
          >
            {isUpdating ? "Saving..." : "Save Changes"}
          </Button>
        </ButtonWrapper>
      </Form>
      {/* Show success message */}
      {showSuccessMessage && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 100,
            }}
          ></div>
          <Alert
            variant="success"
            onClose={() => setShowSuccessMessage(false)}
            dismissible={false}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "25vw",
              height: "auto",
              zIndex: 1000,
            }}
          >
            <Alert.Heading
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "green",
                textAlign: "center",
              }}
            >
              Update Successfull!
            </Alert.Heading>

            <p style={{ textAlign: "center" }}>
              Your mission paragraph has been updated.
            </p>
            <br />
            <div className="d-flex justify-content-end">
              <Button
                variant="outline-success"
                onClick={() => {
                  setShowSuccessMessage(false);
                  navigate("/aboutus/mission/view");
                }}
              >
                Ok
              </Button>
            </div>
          </Alert>
        </>
      )}
    </FormWrapper>
  );
}

const FormWrapper = styled.div`
  padding: 0rem 28rem 1rem 28rem;
`;

const Title = styled.h3`
  text-align: center;
  padding: 10px 0;
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  gap: 2rem;
`;
