import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { updateAboutUsOSPara, getAboutUsOSPara } from "../../../api/axios";
import { Alert } from "react-bootstrap";

export default function OurStoryParaEdit() {
  const [paragraph1, setPara1] = useState("");
  const [paragraph2, setPara2] = useState("");
  const [paragraph3, setPara3] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [wordLimitExceeded, setWordLimitExceeded] = useState(false);
  const [wordLimitExceeded2, setWordLimitExceeded2] = useState(false);
  const [wordLimitExceeded3, setWordLimitExceeded3] = useState(false);
  const navigate = useNavigate();
  const [data, setStepcard] = useState({
    paragraph1: "",
    paragraph2: "",
    paragraph3: "",
    index: null,
  });

  useEffect(() => {
    getAboutUsOSPara()
      .then((res) => {
        console.log(res);
        setStepcard(res.data.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handlePara1Change = (event) => {
    setPara1(event.target.value);
    if (
      event.target.value.split(" ").length > 100 ||
      event.target.value.length > 500
    ) {
      setWordLimitExceeded(true);
    } else {
      setWordLimitExceeded(false);
    }
  };
  const handlePara2Change = (event) => {
    setPara2(event.target.value);
    if (
      event.target.value.split(" ").length > 50 ||
      event.target.value.length > 300
    ) {
      setWordLimitExceeded2(true);
    } else {
      setWordLimitExceeded2(false);
    }
  };
  const handlePara3Change = (event) => {
    setPara3(event.target.value);
    if (
      event.target.value.split(" ").length > 50 ||
      event.target.value.length > 300
    ) {
      setWordLimitExceeded3(true);
    } else {
      setWordLimitExceeded3(false);
    }
  };
  const SendStepData = (e) => {
    e.preventDefault();
    if (wordLimitExceeded) return;
    if (wordLimitExceeded2) return;
    if (wordLimitExceeded3) return;
    setIsUpdating(true);
    var formData = {
      paragraph1,
      paragraph2,
      paragraph3,
    };

    updateAboutUsOSPara(formData)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setShowSuccessMessage(true);
          console.log(res.data);
          setIsUpdating(false);
        } else {
          Promise.resolve();
        }
        e.target.reset();
      })
      .catch((err) => {
        console.log(err);
        setIsUpdating(false);
      });
  };

  return (
    <FormWrapper>
      <Form onSubmit={SendStepData}>
        <Title>Edit our story paragraph section</Title>
        {/* Form */}
        <Form.Group className="mb-3" controlId="ourStory-paragraph1">
          {/* Paragraph 1 */}
          <Form.Label>Enter a New Paragraph 1</Form.Label>
          <Form.Control
            as="textarea"
            rows={6}
            placeholder={data.paragraph1}
            defaultValue={data.paragraph1}
            onChange={handlePara1Change}
            isInvalid={wordLimitExceeded}
          />
                    {wordLimitExceeded && (
            <Form.Control.Feedback type="invalid">
              Word limit exceeded.
            </Form.Control.Feedback>
          )}
        </Form.Group>
        {/* Paragraph 2 */}
        <Form.Group className="mb-3" controlId="ourStory-paragraph2">
          <Form.Label> Enter a New Paragraph 2</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            placeholder={data.paragraph2}
            defaultValue={data.paragraph2}
            onChange={handlePara2Change}
            isInvalid={wordLimitExceeded2}
          />
                    {wordLimitExceeded2 && (
            <Form.Control.Feedback type="invalid">
              Word limit exceeded.
            </Form.Control.Feedback>
          )}
        </Form.Group>
        {/* Paragraph 3 */}
        <Form.Group className="mb-3" controlId="ourStory-paragraph3">
          <Form.Label>Enter a New Paragraph 3</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            placeholder={data.paragraph3}
            defaultValue={data.paragraph3}
            onChange={handlePara3Change}
            isInvalid={wordLimitExceeded3}
          />
                    {wordLimitExceeded3 && (
            <Form.Control.Feedback type="invalid">
              Word limit exceeded.
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <ButtonWrapper>
          <Link
            to="/aboutus/our_story_paragraph/view"
            style={{
              width: "50%",
            }}
          >
            <Button variant="secondary">Go Back</Button>
          </Link>
          <Button
            type="submit"
            style={{
              width: "50%",
            }}
            disabled={isUpdating || wordLimitExceeded ||wordLimitExceeded2 || wordLimitExceeded3}
          >
            {isUpdating ? "Saving..." : "Save Changes"}
          </Button>
        </ButtonWrapper>
      </Form>
      {/* show success message */}
      {showSuccessMessage && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 100,
            }}
          ></div>
          <Alert
            variant="success"
            onClose={() => setShowSuccessMessage(false)}
            dismissible={false}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "25vw",
              height: "auto",
              zIndex: 1000,
            }}
          >
            <Alert.Heading
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "green",
                textAlign: "center",
              }}
            >
              Update Successfull!
            </Alert.Heading>

            <p style={{ textAlign: "center" }}>
              Your our story paragraph section has been updated.
            </p>
            <br />
            <div className="d-flex justify-content-end">
              <Button
                variant="outline-success"
                onClick={() => {
                  setShowSuccessMessage(false);
                  navigate("/aboutus/our_story_paragraph/view");
                }}
              >
                Ok
              </Button>
            </div>
          </Alert>
        </>
      )}
    </FormWrapper>
  );
}

const FormWrapper = styled.div`
  padding: 0rem 28rem 1rem 28rem;
`;

const Title = styled.h3`
  text-align: center;
  padding: 10px 0;
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  gap: 2rem;
`;
