import { React, useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import "./resources.css";
import { Image, Alert, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getAllBlogs, deleteBlogById } from "../../api/axios";

function Preview() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    getAllBlogs()
      .then((res) => {
        console.log(res);
        const blogData = res.data.data;
        setData(blogData);
        console.log(res.data.data[0].id);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleEditClick = (id) => {
    navigate(`update/${id}`);
    console.log("clicked");
  };

  const handleDeleteClick = (id) => {
    deleteBlogById(id)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setShowAlert(false);
          setShowSuccessMessage(true);
          console.log("deleted successful!");
        } else {
          Promise.resolve();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Blog ID</th>
            <th>Image</th>
            <th>Title</th>
            <th>Content</th>
            <th>Read More</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((blog, index) => (
            <tr key={blog.id}>
              <td>{blog.id}</td>
              <td>
                <Image
                  style={{ maxWidth: "15vw" }}
                  src={blog.blog_img}
                  alt={`blog_image${index}`}
                />
              </td>
              <td>{blog.blog_title}</td>
              <td>{blog.blog_content}</td>
              <td>{blog.blog_readMore}</td>
              <td>
                <Button
                  variant="primary"
                  onClick={() => handleEditClick(blog.id)}
                >
                  Edit
                </Button>
              </td>
              <td>
                <Button variant="danger" onClick={() => setShowAlert(true)}>
                  Delete
                </Button>
              </td>
              {showAlert && (
                <>
                  <div
                    style={{
                      position: "fixed",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      zIndex: 100,
                    }}
                  ></div>
                  <Alert
                    variant="danger"
                    onClose={() => setShowAlert(false)}
                    dismissible={false}
                    style={{
                      position: "fixed",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "25vw",
                      height: "auto",
                      zIndex: 1000,
                      backgroundColor: "#f5c2c7",
                    }}
                  >
                    <Alert.Heading
                      style={{
                        fontSize: "1.5rem",
                        color: "#842029",
                        textAlign: "center",
                      }}
                    >
                      Confirm Delete
                    </Alert.Heading>

                    <p style={{ textAlign: "center", color: "#842029" }}>
                      Are you sure you want to delete this blog?
                    </p>
                    <div
                      className="d-flex justify-content-end"
                      style={{ display: "grid", gridGap: "10px" }}
                    >
                      <Button
                        style={{ color: "white" }}
                        className="btn btn-danger mr-2"
                        onClick={() => handleDeleteClick(blog.id)}
                      >
                        Yes
                      </Button>
                      <Button
                        className="btn btn-secondary"
                        onClick={() => setShowAlert(false)}
                      >
                        No
                      </Button>
                    </div>
                  </Alert>
                </>
              )}
              {showSuccessMessage && (
                <>
                  <div
                    style={{
                      position: "fixed",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      zIndex: 100,
                    }}
                  ></div>
                  <Alert
                    variant="success"
                    onClose={() => setShowSuccessMessage(false)}
                    dismissible={false}
                    style={{
                      position: "fixed",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "auto",
                      height: "auto",
                      backgroundColor: "#d1e7dd",
                      zIndex: 1000,
                    }}
                  >
                    <Alert.Heading
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                        color: "green",
                        textAlign: "center",
                      }}
                    >
                      Successfully Deleted!
                    </Alert.Heading>

                    <p style={{ textAlign: "center" }}>
                      Your blog has been deleted successfully!.
                    </p>
                    <br />
                    <div className="d-flex justify-content-end">
                      <Button className="buttonHover"
                        variant="outline-success"
                        onClick={() => {
                          setShowSuccessMessage(false);
                          window.location.reload();
                        }}
                        style={{
                          width: "20vw",
                          color: "white",
                        }}
                      >
                        Ok
                      </Button>
                    </div>
                  </Alert>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default Preview;
