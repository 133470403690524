import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
//import { Button } from "@mui/material";
import "./ChangePassword.css";
import pawPrintLogo from "../../Assets/business.jpg";
//import HttpCommon from "../../Utils/http-common";
import styled from "styled-components";
import { changePassword } from "../../api/axios";

const ChangePassword = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("id");
  const token = queryParameters.get("token");
  const navigate = useNavigate();
  const [input, setInput] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const passwordRegex = new RegExp(
    /^(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/
  );
  const confirmPasswordRegex = new RegExp(
    /^(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!passwordRegex.test(input.newPassword)) {
      alert(
        "Password must be at least 6 characters long and contain at least 1 uppercase letter and 1 special character"
      );
      return;
    }
    if (!confirmPasswordRegex.test(input.confirmPassword)) {
      alert(
        "Confirm Password must be at least 6 characters long and contain at least 1 uppercase letter and 1 special character"
      );
      return;
    } else if (input.newPassword !== input.confirmPassword) {
      alert("New password and confirm password do not match");
      return;
    }

    const res = await changePassword(id, token, input);
    if (res.status === 200) {
      alert("password changed Successfully");
      navigate("/SuccessPage");
    }
  };

  return (
    <div className="main">
      <Box>
        <div className="row g-0">
          <div className="col-12 d-flex align-items-center">
            <div className="card-body p-4 p-lg-5 text-black">
              <form onSubmit={handleSubmit}>
                <Image>
                  <img src={pawPrintLogo} className="deltamaxlogo" alt="logo" />
                </Image>
                <div className="d-flex align-items-center mb-3 pb-1">
                  <i
                    className="fas fa-cubes fa-2x me-3"
                    style={{ color: "#ff6219" }}
                  ></i>
                  <h1 className="foget-password-header">Forgot Password?</h1>
                </div>
                <h5
                  className="fw-normal mb-3 pb-3, header2"
                  style={{ letterSpacing: "1px" }}
                >
                  Type Your New Password Here
                </h5>

                <div className="form-outline mb-4">
                  <input
                    type="password"
                    id=""
                    placeholder="Enter New Password"
                    className="form-control form-control-lg text_input"
                    name="newPassword"
                    value={input.newPassword}
                    required
                    onChange={(e) =>
                      setInput({
                        ...input,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="form-outline mb-4">
                  <input
                    type="password"
                    id=""
                    placeholder="Confirm Password"
                    className="form-control form-control-lg text_input"
                    name="confirmPassword"
                    value={input.confirmPassword}
                    required
                    onChange={(e) =>
                      setInput({
                        ...input,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="pt-1 mb-4">
                  <Button
                    className="btn btn-dark btn-lg btn-block"
                    type="submit"
                  >
                    Change Password
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default ChangePassword;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 5;
  padding-bottom: 7;
  border-radius: 25px;
  background-color: hsla(242, 40%, 37%, 1);
  box-shadow: 5px 5px 10px #ccc;
  :hover {
    box-shadow: 10px 10px 20px #ccc;
  }
`;

const Button = styled.button`
  margin-top: 3px;
  border-radius: 15px;
  color: white;
  background-color: #ed6c02;
`;

const Image = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  img {
    border-radius: 50%;
    border: 5px solid white;
    height: 130px;
    width: 130px;
  }
`;
