import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Alert } from "react-bootstrap";
import { getButton, updateButton } from "../../api/axios";

export default function ButtonsEdit() {

  const { id } = useParams();
  const navigate = useNavigate();

  const [pageName, setPageName] = useState("");
  const [pagePosition, setPagePosition] = useState("");
  const [button_name, setButtonText] = useState("");
  const [buttonLink, setButtonLink] = useState("");
  const [button, setButton] = useState([]);
  const [saving, setSaving] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    getButton(id)
      .then((response) => {
        console.log(response);
        const blogData = response.data.data[0];
        setButton(blogData);
        console.log(response.data.data[0].id);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  const SendButtonData = (e) => {
    e.preventDefault();
    setSaving(true);

    // var formData = new FormData();
    // formData.append("pageName", pageName);
    // formData.append("pagePosition", pagePosition);
    // formData.append("button_name", button_name);
    // formData.append("buttonLink", buttonLink);

    const formData = {
        pageName: pageName,
        pagePosition: pagePosition,
        button_name: button_name,
        buttonLink: buttonLink,
    }

    updateButton(id, formData)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setShowSuccessMessage(true);
          console.log("success");
        } else {
          Promise.resolve();
        }
        e.target.reset();
        setSaving(false);
      })
      .catch((err) => {
        console.log(err);
        setSaving(false);
      });
  };

  return (
    <FormWrapper>
      <Title>Edit Buttons section</Title>
      <Form>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>
            <b>Page Name</b> (You cannot edit this field.)
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            disabled
            defaultValue={button.pageName}
            onChange={(e) => setPageName(e.target.value)}
          />
        </Form.Group>
        <br />
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>
            <b>Position</b> (You cannot edit this field.)
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            defaultValue={button.pagePosition}
            disabled
            onChange={(e) => setPagePosition(e.target.value)}
          />
        </Form.Group>
        <br />
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label style={{ fontWeight: "bold" }}>Enter a new part 4</Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            defaultValue={button.button_name}
            onChange={(e) => setButtonText(e.target.value)}
          />
        </Form.Group>
        <br />
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label style={{ fontWeight: "bold" }}>
            Enter a button link
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            defaultValue={button.buttonLink}
            onChange={(e) => setButtonLink(e.target.value)}
          />
        </Form.Group>
        <br />
        <ButtonWrapper>
          <Link to={`/buttons`}>
            <Button variant="secondary">Go Back</Button>
          </Link>
          <Button
            variant="primary"
            type="submit"
            onClick={SendButtonData}
            disabled={saving}
          >
            {saving ? "Saving..." : "Save Changes"}
          </Button>
        </ButtonWrapper>
      </Form>
      {showSuccessMessage && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 100,
            }}
          ></div>
          <Alert
            variant="success"
            onClose={() => setShowSuccessMessage(false)}
            dismissible={false}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "25vw",
              height: "auto",
              zIndex: 1000,
            }}
          >
            <Alert.Heading
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "green",
                textAlign: "center",
              }}
            >
              Update Successfull!
            </Alert.Heading>

            <p style={{ textAlign: "center" }}>
              Your button has been updated.
            </p>
            <br />
            <div className="d-flex justify-content-end">
              <Button
                variant="outline-success"
                onClick={() => {
                  setShowSuccessMessage(false);
                    navigate(`/buttons`);
                }}
              >
                Ok
              </Button>
            </div>
          </Alert>
        </>
      )}
    </FormWrapper>
  );
}

const FormWrapper = styled.div`
  padding: 0rem 22rem 1rem 22rem;
`;

const Title = styled.h3`
  text-align: center;
  padding: 10px 0;
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
`;
