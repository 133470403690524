import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { getHomeSlider1, updateHomeSlider1 } from "../../../api/axios";
import { Alert } from "react-bootstrap";

export default function SlideroneEdit() {
  const { id } = useParams();

  const [image1, setImage1] = useState("");
  const [paragraph1, setParagraph1] = useState("");
  const [image2, setImage2] = useState("");
  const [paragraph2, setParagraph2] = useState("");
  const [image3, setImage3] = useState("");
  const [paragraph3, setParagraph3] = useState("");
  const [sliderone, setSliderone] = useState({
    image1: "",
    paragraph1: "",
    image2: "",
    paragraph2: "",
    image3: "",
    paragraph3: "",
    index: null,
  });
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);
  const [wordLimitExceeded, setWordLimitExceeded] = useState(false);
  const [wordLimitExceeded2, setWordLimitExceeded2] = useState(false);
  const [wordLimitExceeded3, setWordLimitExceeded3] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    getHomeSlider1(id)
      .then((response) => {
        const slideoneData = response.data.data[0];
        setSliderone({
          ...slideoneData,
          index: response.data.data.indexOf(slideoneData),
        });
        console.log(response.data.data[0]);
        console.log(id);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  const handleParagraphChange = (e) => {
    setParagraph1(e.target.value);
    if (e.target.value.split(" ").length > 50 || e.target.value.length > 300) {
      setWordLimitExceeded(true);
    } else {
      setWordLimitExceeded(false);
    }
  };

  const handleParagraph2Change = (e) => {
    setParagraph2(e.target.value);
    if (e.target.value.split(" ").length > 50 || e.target.value.length > 300) {
      setWordLimitExceeded2(true);
    } else {
      setWordLimitExceeded2(false);
    }
  };

  const handleParagraph3Change = (e) => {
    setParagraph3(e.target.value);
    if (e.target.value.split(" ").length > 50 || e.target.value.length > 300) {
      setWordLimitExceeded3(true);
    } else {
      setWordLimitExceeded3(false);
    }
  };

  const SendSlideoneData = (e) => {
    e.preventDefault();
    if (wordLimitExceeded) return;
    if (wordLimitExceeded2) return;
    if (wordLimitExceeded3) return;
    setSaving(true);

    var formData = new FormData();
    formData.append("paragraph1", paragraph1);
    formData.append("paragraph2", paragraph2);
    formData.append("paragraph3", paragraph3);

    if (image1 instanceof File) {
      formData.append("image1", image1);
    }

    if (image2 instanceof File) {
      formData.append("image2", image2);
    }

    if (image3 instanceof File) {
      formData.append("image3", image3);
    }

    updateHomeSlider1(id, formData)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          //   alert("Blog has been updated successfully");
          setShowSuccessMessage(true);
          //   navigate(`/home/slider1/view/${id}`);
        } else {
          Promise.resolve();
        }
        e.target.reset();
        setSaving(false);
      })
      .catch((err) => {
        console.log(err);
        setSaving(false);
      });
  };

  return (
    <FormWrapper>
      <Form>
        <Title>Edit Slider 1 section</Title>
        <SubTitle>Slide 1</SubTitle>
        <Form.Group
          controlId="formBasicName"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Form.Label style={{ fontWeight: "bold" }}>Select a new Image</Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            onChange={(e) => {
              setImage1(e.target.files[0]);
            }}
            style={{ display: "none" }}
            ref={inputRef1}
          />
          {image1 ? (
            <>
              <img
                style={{ maxWidth: "25vw" }}
                src={URL.createObjectURL(image1)}
                alt="image1"
                onClick={() => {
                  setImage1.current.click();
                }}
              />
              <br />
              <RemoveButtonWrapper>
                <Button
                  variant="outline-danger"
                  onClick={() => {
                    setImage1(null);
                  }}
                >
                  Remove your image
                </Button>
              </RemoveButtonWrapper>
            </>
          ) : (
            <img
              style={{ maxWidth: "25vw", cursor: "pointer" }}
              src={sliderone.image1}
              alt="image1"
              onClick={() => {
                inputRef1.current.click();
              }}
              onMouseEnter={(e) => {
                e.target.style.opacity = 0.8;
              }}
              onMouseLeave={(e) => {
                e.target.style.opacity = 1;
              }}
            />
          )}
        </Form.Group>
        <br />
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label style={{ fontWeight: "bold" }}>Enter a new paragraph</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            defaultValue={sliderone.paragraph1}
            onChange={handleParagraphChange}
            isInvalid={wordLimitExceeded}
          />
          {wordLimitExceeded && (
            <Form.Control.Feedback type="invalid">
              Word limit exceeded.
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <SubTitle>Slide 2</SubTitle>
        <Form.Group
          controlId="formBasicName"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Form.Label style={{ fontWeight: "bold" }}>Select a new Image</Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            onChange={(e) => {
              setImage2(e.target.files[0]);
            }}
            style={{ display: "none" }}
            ref={inputRef2}
          />
          {image2 ? (
            <>
              <img
                style={{ maxWidth: "25vw" }}
                src={URL.createObjectURL(image2)}
                alt="image2"
                onClick={() => {
                  setImage2.current.click();
                }}
              />
              <br />
              <RemoveButtonWrapper>
                <Button
                  variant="outline-danger"
                  onClick={() => {
                    setImage2(null);
                  }}
                >
                  Remove your image
                </Button>
              </RemoveButtonWrapper>
            </>
          ) : (
            <img
              style={{ maxWidth: "25vw", cursor: "pointer" }}
              src={sliderone.image2}
              alt="image2"
              onClick={() => {
                inputRef2.current.click();
              }}
              onMouseEnter={(e) => {
                e.target.style.opacity = 0.8;
              }}
              onMouseLeave={(e) => {
                e.target.style.opacity = 1;
              }}
            />
          )}
        </Form.Group>
        <br />
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label style={{ fontWeight: "bold" }}>Enter a new paragraph</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            defaultValue={sliderone.paragraph2}
            onChange={handleParagraph2Change}
            isInvalid={wordLimitExceeded2}
          />
          {wordLimitExceeded2 && (
            <Form.Control.Feedback type="invalid">
              Word limit exceeded.
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <SubTitle>Slide 3</SubTitle>
        <Form.Group
          controlId="formBasicName"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Form.Label style={{ fontWeight: "bold" }}>Select a new Image</Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            onChange={(e) => {
              setImage3(e.target.files[0]);
            }}
            style={{ display: "none" }}
            ref={inputRef3}
          />
          {image3 ? (
            <>
              <img
                style={{ maxWidth: "25vw" }}
                src={URL.createObjectURL(image3)}
                alt="image3"
                onClick={() => {
                  setImage3.current.click();
                }}
              />
              <br />
              <RemoveButtonWrapper>
                <Button
                  variant="outline-danger"
                  onClick={() => {
                    setImage3(null);
                  }}
                >
                  Remove your image
                </Button>
              </RemoveButtonWrapper>
            </>
          ) : (
            <img
              style={{ maxWidth: "25vw", cursor: "pointer" }}
              src={sliderone.image3}
              alt="image3"
              onClick={() => {
                inputRef3.current.click();
              }}
              onMouseEnter={(e) => {
                e.target.style.opacity = 0.8;
              }}
              onMouseLeave={(e) => {
                e.target.style.opacity = 1;
              }}
            />
          )}
        </Form.Group>
        <br />
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label style={{ fontWeight: "bold" }}>Enter a new paragraph</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            defaultValue={sliderone.paragraph3}
            onChange={handleParagraph3Change}
            isInvalid={wordLimitExceeded3}
          />
          {wordLimitExceeded3 && (
            <Form.Control.Feedback type="invalid">
              Word limit exceeded.
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <ButtonWrapper>
          <Link to={`/home/slider1/view/${id}`}>
            <Button variant="secondary">Go Back</Button>
          </Link>
          <Button
            variant="primary"
            type="submit"
            onClick={SendSlideoneData}
            disabled={
              saving ||
              wordLimitExceeded ||
              wordLimitExceeded2 ||
              wordLimitExceeded3
            }
          >
            {saving ? "Saving..." : "Save Changes"}
          </Button>
        </ButtonWrapper>
      </Form>

      {showSuccessMessage && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 100,
            }}
          ></div>
          <Alert
            variant="success"
            onClose={() => setShowSuccessMessage(false)}
            dismissible={false}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "25vw",
              height: "auto",
              zIndex: 1000,
            }}
          >
            <Alert.Heading
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "green",
                textAlign: "center",
              }}
            >
              Update Successfull!
            </Alert.Heading>

            <p style={{ textAlign: "center" }}>
              Your slider 1 section has been updated.
            </p>
            <br />
            <div className="d-flex justify-content-end">
              <Button
                variant="outline-success"
                onClick={() => {
                  setShowSuccessMessage(false);
                  navigate(`/home/slider1/view/${id}`);
                }}
              >
                Ok
              </Button>
            </div>
          </Alert>
        </>
      )}
    </FormWrapper>
  );
}

const FormWrapper = styled.div`
  padding: 0rem 22rem 1rem 22rem;
`;

const Title = styled.h3`
  text-align: center;
  padding: 10px 0;
`;

const SubTitle = styled.h5``;

const RemoveButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 50%;
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
`;
