import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { getHomeSlider2, updateHomeSlider2 } from "../../../api/axios";
import { Alert } from "react-bootstrap";

export default function SlidertwoEdit() {
  const { id } = useParams();

  const [logo1, setLogo1] = useState("");
  const [logo2, setLogo2] = useState("");
  const [logo3, setLogo3] = useState("");
  const [slidertwo, setSlidertwo] = useState({
    logo1: "",
    logo2: "",
    logo3: "",
    index: null,
  });
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    getHomeSlider2(id)
      .then((response) => {
        const slidertwoData = response.data.data[0];
        setSlidertwo({
          ...slidertwoData,
          index: response.data.data.indexOf(slidertwoData),
        });
        console.log(response.data.data[0]);
        console.log(id);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  const SendSlidertwoData = (e) => {
    e.preventDefault();
    setSaving(true);

    var formData = new FormData();

    if (logo1 instanceof File) {
      formData.append("logo1", logo1);
    }

    if (logo2 instanceof File) {
      formData.append("logo2", logo2);
    }

    if (logo3 instanceof File) {
      formData.append("logo3", logo3);
    }

    updateHomeSlider2(id, formData)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          //   alert("Slider 2 has been updated successfully");
          setShowSuccessMessage(true);
          //   navigate(`/home/slider2/view/${id}`);
        } else {
          Promise.resolve();
        }
        e.target.reset();
        setSaving(false);
      })
      .catch((err) => {
        console.log(err);
        setSaving(false);
      });
  };

  return (
    <FormWrapper>
      <Form>
        <Title>Edit Slider 2 section</Title><br/>
        <SubTitle>Logo 1</SubTitle>
        <Form.Group
          controlId="formBasicName"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Form.Label style={{ fontWeight: "bold" }}>Select a Logo</Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            onChange={(e) => {
              setLogo1(e.target.files[0]);
            }}
            style={{ display: "none" }}
            ref={inputRef1}
          />
          {logo1 ? (
            <>
              <img
                style={{ maxWidth: "15vw" }}
                src={URL.createObjectURL(logo1)}
                alt="logo1"
                onClick={() => {
                  setLogo1.current.click();
                }}
              />
              <br />
              <RemoveButtonWrapper>
                <Button
                  variant="outline-danger"
                  onClick={() => {
                    setLogo1(null);
                  }}
                >
                  Remove your image
                </Button>
              </RemoveButtonWrapper>
            </>
          ) : (
            <img
              style={{ maxWidth: "15vw", cursor: "pointer" }}
              src={slidertwo.logo1}
              alt="logo1"
              onClick={() => {
                inputRef1.current.click();
              }}
              onMouseEnter={(e) => {
                e.target.style.opacity = 0.8;
              }}
              onMouseLeave={(e) => {
                e.target.style.opacity = 1;
              }}
            />
          )}
        </Form.Group>
        <br />
        <SubTitle>Logo 2</SubTitle>
        <Form.Group
          controlId="formBasicName"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Form.Label style={{ fontWeight: "bold" }}>Select a Logo</Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            onChange={(e) => {
              setLogo2(e.target.files[0]);
            }}
            style={{ display: "none" }}
            ref={inputRef2}
          />
          {logo2 ? (
            <>
              <img
                style={{ maxWidth: "15vw" }}
                src={URL.createObjectURL(logo2)}
                alt="logo2"
                onClick={() => {
                  setLogo2.current.click();
                }}
              />
              <br />
              <RemoveButtonWrapper>
                <Button
                  variant="outline-danger"
                  onClick={() => {
                    setLogo2(null);
                  }}
                >
                  Remove your image
                </Button>
              </RemoveButtonWrapper>
            </>
          ) : (
            <img
              style={{ maxWidth: "15vw", cursor: "pointer" }}
              src={slidertwo.logo2}
              alt="logo2"
              onClick={() => {
                inputRef2.current.click();
              }}
              onMouseEnter={(e) => {
                e.target.style.opacity = 0.8;
              }}
              onMouseLeave={(e) => {
                e.target.style.opacity = 1;
              }}
            />
          )}
        </Form.Group>
        <br />
        <SubTitle>Logo 3</SubTitle>
        <Form.Group
          controlId="formBasicName"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Form.Label style={{ fontWeight: "bold" }}>Select a Logo</Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            onChange={(e) => {
              setLogo3(e.target.files[0]);
            }}
            style={{ display: "none" }}
            ref={inputRef3}
          />
          {logo3 ? (
            <>
              <img
                style={{ maxWidth: "15vw" }}
                src={URL.createObjectURL(logo3)}
                alt="logo3"
                onClick={() => {
                  setLogo3.current.click();
                }}
              />
              <br />
              <RemoveButtonWrapper>
                <Button
                  variant="outline-danger"
                  onClick={() => {
                    setLogo3(null);
                  }}
                >
                  Remove your image
                </Button>
              </RemoveButtonWrapper>
            </>
          ) : (
            <img
              style={{ maxWidth: "15vw", cursor: "pointer" }}
              src={slidertwo.logo3}
              alt="logo3"
              onClick={() => {
                inputRef3.current.click();
              }}
              onMouseEnter={(e) => {
                e.target.style.opacity = 0.8;
              }}
              onMouseLeave={(e) => {
                e.target.style.opacity = 1;
              }}
            />
          )}
        </Form.Group>
        <br />
        <ButtonWrapper>
          <Link to={`/home/slider2/view/${id}`}>
            <Button variant="secondary">Go Back</Button>
          </Link>
          <Button
            variant="primary"
            type="submit"
            onClick={SendSlidertwoData}
            disabled={saving}
          >
            {saving ? "Saving..." : "Save Changes"}
          </Button>
        </ButtonWrapper>
      </Form>
      {showSuccessMessage && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 100,
            }}
          ></div>
          <Alert
            variant="success"
            onClose={() => setShowSuccessMessage(false)}
            dismissible={false}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "25vw",
              height: "auto",
              zIndex: 1000,
            }}
          >
            <Alert.Heading
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "green",
                textAlign: "center",
              }}
            >
              Update Successfull!
            </Alert.Heading>

            <p style={{ textAlign: "center" }}>
              Your slider 2 section has been updated.
            </p>
            <br />
            <div className="d-flex justify-content-end">
              <Button
                variant="outline-success"
                onClick={() => {
                  setShowSuccessMessage(false);
                  navigate(`/home/slider2/view/${id}`);
                }}
              >
                Ok
              </Button>
            </div>
          </Alert>
        </>
      )}
    </FormWrapper>
  );
}

const FormWrapper = styled.div`
  padding: 0rem 28rem 1rem 28rem;
`;

const Title = styled.h3`
  text-align: center;
  padding: 10px 0;
`;

const SubTitle = styled.h5``;

const RemoveButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 50%;
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
`;
