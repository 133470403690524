import React from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Preview from './Preview';

function Resources() {

  return (
    <ContentWrapper>
    <Title>Click button to create new blog</Title>    
    {/* create new blog button */}      
      <Link to="/resources/create">
        <Button>Create a new blog</Button>
      </Link>
    <Preview/>
    </ContentWrapper> 
  );
}

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
`;

const Title = styled.h3`
  text-align: center;
  padding: 5px 0;
`;

export default Resources;
