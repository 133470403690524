import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

function Aboutus() {

  return (
    <ContentWrapper>
      <Title>Select a section</Title>
      <SectionWrapper>
        <Link to="/aboutus/header/view">
          <Button>Header Section</Button>
        </Link>
        <Link to="/aboutus/paragraph/view">
          <Button>Header Paragraph Section</Button>
        </Link>
        <Link to="/aboutus/our_story_paragraph/view">
          <Button>Our Story Paragraph Section </Button>
        </Link>
        <Link to="/aboutus/ourstory/view">
          <Button>"Our Story" Section</Button>
        </Link>
        <Link to="/aboutus/mission/view">
          <Button>"Mission" Section</Button>
        </Link>
        <Link to="/aboutus/stepcards/view">
          <Button>Step Cards Section</Button>
        </Link>
        <Link to="/aboutus/meet_the_team/view">
          <Button>“Meet the Team” Section</Button>
        </Link>
        <Link to="/aboutus/bottom_title/view">
          <Button>Bottom Title Section</Button>
        </Link>
      </SectionWrapper>
    </ContentWrapper>
  );
}

export default Aboutus;
const ContentWrapper = styled.div`
  padding: 0 50px 30px 50px;
`;

const Title = styled.h3`
  text-align: center;
  padding: 10px 0;
`;

const SectionWrapper = styled.div`
  /* justify-content: center;
  align-items: center;
  gap: 12px; */
  display: flex;
  gap: 2rem;
  justify-content: center;
  padding-top: 5rem;
`;
