import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { getHomeSlider1 } from "../../../api/axios";

export default function Slider1iew() {

  const { id } = useParams();

  const [slideone, setSlideone] = useState([]);

  useEffect(() => {
    getHomeSlider1(id)
      .then((response) => {
        setSlideone(response.data.data[0]);
        console.log(response.data.data[0]);
        console.log(id);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  return (
    <ContentWrapper>
      <HomeTitle>Slider 1 Section</HomeTitle>
      <TableWrapper>
        <Table>
          <thead>
            <TableRow>
              <TableHeader>Slide number</TableHeader>
              <TableHeader>Slide Image</TableHeader>
              <TableHeader>Slide Paragraph</TableHeader>
            </TableRow>
          </thead>
          <TableBody>
            <TableRow>
              <TableCell>Slide 1</TableCell>
              <TableCell>
                <img src={slideone.image1} alt="slider 1" width="250" />
              </TableCell>
              <TableCell>{slideone.paragraph1}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Slide 2</TableCell>
              <TableCell>
                <img src={slideone.image2} alt="slider 2" width="250" />
              </TableCell>
              <TableCell>{slideone.paragraph2}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Slidet 3</TableCell>
              <TableCell>
                <img src={slideone.image3} alt="slider 2" width="250" />
              </TableCell>
              <TableCell>{slideone.paragraph3}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableWrapper>
      <ButtonWrapper>
        <Link to="/home">
          <Button variant="secondary">Go Back</Button>
        </Link>
        <Link to={`/home/slider1/edit/${id}`}>
          <Button>Edit Slider 1 section</Button>
        </Link>
      </ButtonWrapper>
    </ContentWrapper>
  );
}

const ContentWrapper = styled.div`
  padding-bottom: 30px;
  justify-content: center;
`;

const HomeTitle = styled.h3`
  text-align: center;
  padding: 10px 0;
`;

const TableWrapper = styled.div`
  margin: 0 auto;
  padding: 10px 0 0 50px;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 10px;
  border: 2px solid #ccc;
`;

const TableBody = styled.tbody``;

const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  padding-top: 40px;
  display: flex;
  gap: 5rem;
  justify-content: center;
`;
