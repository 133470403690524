import axios from "axios";

export const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
});
// login
export const login = async (formData) => {
  const res = await api.post(`/admin/login`, formData);
  return res;
};

// forget password
export const forgotPassword = async (formData) => {
  const res = await api.post(`/admin/forgotpassword`, formData);
  return res;
};

// change password
export const changePassword = async (id, token, formData) => {
  const res = await api.post(`/admin/forget-password/${id}/${token}`, formData);
  return res;
};

// resources page
export const getAllBlogs = async () => {
  const res = await api.get(`/resources/getAllBlogs`);
  return res;
};

export const getBlogsById = async (id) => {
  const res = await api.get(`/resources/getResourcesById/${id}`);
  return res;
};

export const updateBlogsById = async (id, formData) => {
  const res = await api.put(`/resources/update/${id}`, formData);
  return res;
};

export const createNewBlog = async (formData) => {
  const res = await api.post(`/resources/create`, formData);
  return res;
};
export const createMeetTheTeam = async (formData) => {
  const res = await api.post(`/abouteUsTeamSectionNew/createTeamSection`, formData);
  return res;
};

export const deleteBlogById = async (id) => {
  const res = await api.delete(`/resources/delete/${id}`);
  return res;
};

export const deleteMeetTheTeam = async (id) => {
  const res = await api.delete(`/abouteUsTeamSectionNew/deleteTeamSection/${id}`);
  return res;
};
// contact us page
export const getAllContacts = async () => {
  const res = await api.get(`/contactUs/getAllContacts`);
  return res;
};

export const getcontactUsById = async (id) => {
  const res = await api.get(`/contactUs/getcontactUsById/${id}`);
  return res;
};

// home page
export const getAllHomeHeader = async () => {
  const res = await api.get(`/home/getAllHomeHeader`);
  return res;
};

export const getHomeHeader = async (id) => {
  const res = await api.get(`/home/getHomeHeaderById/${id}`);
  return res;
};

export const updateContactUs = async (id, formData) => {
  const res = await api.put(`/contactUs/update/${id}`, formData);
  return res;
};

export const updateHomeHeader = async (id, formData) => {
  const res = await api.put(`/home/updateHomeHeader/${id}`, formData);
  return res;
};

export const getAllHomeSlider1 = async () => {
  const res = await api.get(`/home/getAllHomeSliderOne`);
  return res;
};

export const getHomeSlider1 = async (id) => {
  const res = await api.get(`/home/homeSliderOneById/${id}`);
  return res;
};

export const updateHomeSlider1 = async (id, formData) => {
  const res = await api.put(`/home/updateHomeSliderOne/${id}`, formData);
  return res;
};

export const getAllHomeSlider2 = async () => {
  const res = await api.get(`/home/getAllHomeSliderTwo`);
  return res;
};

export const getHomeSlider2 = async (id) => {
  const res = await api.get(`/home/homeSliderTwoById/${id}`);
  return res;
};

export const updateHomeSlider2 = async (id, formData) => {
  const res = await api.put(`/home/updateHomeSliderTwo/${id}`, formData);
  return res;
};

export const getAllHomeParagraph = async () => {
  const res = await api.get(`/home/getAllHomeParagraph`);
  return res;
};

export const getHomeParagraph = async (id) => {
  const res = await api.get(`/home/getHomeParagraphById/${id}`);
  return res;
};

export const updateHomeParagraph = async (id, formData) => {
  const res = await api.put(`/home/updateHomeParagraph/${id}`, formData);
  return res;
};

export const getAllHomeOverview = async () => {
  const res = await api.get(`/home/getAllHomeService`);
  return res;
};

export const getHomeOverview = async (id) => {
  const res = await api.get(`/home/getHomeServiceById/${id}`);
  return res;
};

export const updateHomeOverview = async (id, formData) => {
  const res = await api.put(`/home/updateHomeService/${id}`, formData);
  return res;
};

//button
export const getAllButton = async () => {
  const res = await api.get(`/button/getAllButton`);
  return res;
};

export const getButton = async (id) => {
  const res = await api.get(`/button/getButtonById/${id}`);
  return res;
};

export const updateButton = async (id, formData) => {
  const res = await api.put(`/button/updateButton/${id}`, formData);
  return res;
};

// Footer
export const getFooterById = async () => {
  const res = await api.get(`/footer/getFooterById/7`);
  return res;
};

export const updateFooter = async (formData) => {
  const res = await api.put(`/footer/updateFooter/7`, formData);
  console.log(res.data);
  return res;
};

//Admin
export const getAllAdmin = async () => {
  const res = await api.get("/admin/getAllAdmin", {
    headers: {
      Authorization: `Bearer ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOnsiaWQiOjksImFkbWluX25hbWUiOiJjaGludGhhbmkiLCJlbWFpbCI6Im5jcmFua290aEBnbWFpbC5jb20iLCJzdGF0dXMiOiJBRE1JTiJ9LCJpYXQiOjE2NzgzNTI2NzU0NTksImV4cCI6MTY3ODM1Mzg4NTA1OX0.BvOIUYX-mSMQ4Kt4ckaX3yzTs-gLmJQhRqjiKTxggEw"}`,
    },
  });
  console.log(res.data);
  return res.data;
};

export const deleteadminById = async (id) => {
  const res = await api.delete(`/admin/delete/${id}`, {
    headers: {
      Authorization: `Bearer ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOnsiaWQiOjksImFkbWluX25hbWUiOiJjaGludGhhbmkiLCJlbWFpbCI6Im5jcmFua290aEBnbWFpbC5jb20iLCJzdGF0dXMiOiJBRE1JTiJ9LCJpYXQiOjE2NzgzNTI2NzU0NTksImV4cCI6MTY3ODM1Mzg4NTA1OX0.BvOIUYX-mSMQ4Kt4ckaX3yzTs-gLmJQhRqjiKTxggEw"}`,
    },
  });
  return res;
};

// export const updateAdminById = async (id, formData) => {
//     const res = await api.put(`/admin/update/${id}`, formData)
//     console.log(res)
//     return res
// }

// export const updateAdminById = async (id, formData) => {
//     // const token = localStorage.getItem('token');
//     const config = {
//       headers: {
//         Authorization: `Bearer ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOnsiaWQiOjgsImFkbWluX25hbWUiOiJzYWppdGgiLCJlbWFpbCI6InNhaml0aHRoaWxhbmdhOTRAZ21haWwuY29tIiwic3RhdHVzIjoiQURNSU4ifSwiaWF0IjoxNjc4NDAzMjcxMzY3LCJleHAiOjE2Nzg0MDQ0ODA5Njd9.CCofKhzt6O2MEZnBBaIknFFvAOAmC2p1YSBv6YFDgsg"}`,
//       },
//     };
//     const res = await api.put(`/admin/update/${id}`, formData, config)
//     // console.log(formData);
//     // console.log(res)
//     return res
//   }

export const updateAdminById = async (id, formData) => {
  // const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOnsiaWQiOjgsImFkbWluX25hbWUiOiJzYWppdGgiLCJlbWFpbCI6InNhaml0aHRoaWxhbmdhOTRAZ21haWwuY29tIiwic3RhdHVzIjoiQURNSU4ifSwiaWF0IjoxNjc4NDAzMjcxMzY3LCJleHAiOjE2Nzg0MDQ0ODA5Njd9.CCofKhzt6O2MEZnBBaIknFFvAOAmC2p1YSBv6YFDgsg"}`,
    },
  };
  const res = await api.put(`/admin/update/${id}`, formData, config, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
};

export const getAdminById = async (id) => {
  const res = await api.get(`/admin/getAdmin/${id}`, {
    headers: {
      Authorization: `Bearer ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOnsiaWQiOjgsImFkbWluX25hbWUiOiJzYWppdGhBQkMiLCJlbWFpbCI6InNhaml0aHRoaWxhbmdhOTRAZ21haWwuY29tIiwic3RhdHVzIjoiQURNSU4ifSwiaWF0IjoxNjc4NDAwMzE4OTg2LCJleHAiOjE2Nzg0MDE1Mjg1ODZ9.b-SX3Qy3IYVFfXIbPgmRoSPLre-UWlevt-4VprJUUSc"}`,
    },
  });
  return res;
};

// export const getAdminById = async (id, token) => {
//     const res = await api.get(`/admin/getAdmin/${id}`, {
//         headers: {
//             Authorization: `Bearer ${token}`
//         }
//     })
//     return res
// }

// export const createNewAdmin = async (formData) => {
//     const res = await api.post(`/admin/register`, formData,  {
//         headers: {
//             Authorization: `Bearer ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOnsiaWQiOjgsImFkbWluX25hbWUiOiJzYWppdGhBQkMiLCJlbWFpbCI6InNhaml0aHRoaWxhbmdhOTRAZ21haWwuY29tIiwic3RhdHVzIjoiQURNSU4ifSwiaWF0IjoxNjc4NDAwMzE4OTg2LCJleHAiOjE2Nzg0MDE1Mjg1ODZ9.b-SX3Qy3IYVFfXIbPgmRoSPLre-UWlevt-4VprJUUSc"}`
//         }
//     })
//     console.log(res)
//     return res
// }

export const createNewAdmin = async (formData) => {
  const res = await api.post(`/admin/register`, formData);
  console.log(res);
  return res;
};

// ForBusiness
export const getForBusinessCard = async () => {
  const res = await api.get(`/business/getBusinessCardById/1`);
  return res;
};
export const updateForBusinessCard = async (formData) => {
  const res = await api.put(`/business/updateBusinessCard/1`, formData);
  return res;
};
export const getForBusinessImage = async () => {
  const res = await api.get(`/business/getBusinessHeaderById/1`);
  return res;
};
export const updateForBusinessImage = async (formData) => {
  const res = await api.put(`/business/updateBusinessHeader/1`, formData);
  return res;
};

export const getBusinessBottom = async (id) => {
  const res = await api.get(`/business/getByIdBusinessFooter/1`);
  return res;
};

export const updateBusinessBottom = async (id, formData) => {
  const res = await api.put(`/business/updateBusinessFooter/${id}`, formData);
  return res;
};
export const updateMeetTheTeam = async (id, formData) => {
  const res = await api.put(`/abouteUsTeamSectionNew/updateTeamSection/${id}`, formData);
  return res;
};

export const getAllBusinessParagraph = async (id) => {
  const res = await api.get(`/business/getAllBusinessParagraph`);
  return res;
};

export const getBusinessParagraph = async (id) => {
  const res = await api.get(`/business/getByIdBusinessParagraph/${id}`);
  return res;
};

export const getByMeetTheTeam = async (id) => {
  const res = await api.get(`/abouteUsTeamSectionNew/getByIdTeamSection/${id}`);
  return res;
};

export const updateBusinessParagraph = async (id, formData) => {
  const res = await api.put(
    `/business/updateBusinessParagraph/${id}`,
    formData
  );
  return res;
};

// ForIndividuals
export const getForIndividualsStep = async () => {
  const res = await api.get(`/forIndividuals/getStepById/1`);
  return res;
};
export const updateForIndividualsStep = async (formData) => {
  const res = await api.put(`/forIndividuals/updateStep/1`, formData);
  return res;
};
export const getForIndividualsPara = async () => {
  const res = await api.get(`/forIndividuals/getByParagraph/2`);
  return res;
};
export const updateForIndividualsPara = async (formData) => {
  const res = await api.put(`/forIndividuals/updateParagraph/2`, formData);
  return res;
};
export const getForIndividualsComment = async () => {
  const res = await api.get(`/forIndividuals/getCommentById/1`);
  return res;
};
export const updateForIndividualsComment = async (formData) => {
  const res = await api.put(`/forIndividuals/updateComment/1`, formData);
  return res;
};
//About Us
export const getAboutUsHeader = async () => {
  const res = await api.get(`/abouteUsHeader/getById/1`);
  return res;
};
export const updateAboutUsHeader = async (formData) => {
  const res = await api.put(`/abouteUsHeader/update/1`, formData);
  return res;
};
export const getAboutUsLastTitle = async () => {
  const res = await api.get(`/abouteUsLastTittle/getById/1`);
  return res;
};
export const updateAboutUsLastTitle = async (formData) => {
  const res = await api.put(`/abouteUsLastTittle/update/1`, formData);
  return res;
};
export const getAboutUsParagraph = async () => {
  const res = await api.get(`/abouteUsParagraph/getById/1`);
  return res;
};
export const updateAboutUsParagraph = async (formData) => {
  const res = await api.put(`/abouteUsParagraph/update/1`, formData);
  return res;
};
export const getAboutUsOSPara = async () => {
  const res = await api.get(`/abouteUsStoryPara/getById/2`);
  return res;
};
export const updateAboutUsOSPara = async (formData) => {
  const res = await api.put(`/abouteUsStoryPara/update/2`, formData);
  return res;
};
export const getAboutUsOurStory = async () => {
  const res = await api.get(`/abouteUsStorySection/getbyId/1`);
  return res;
};
export const updateAboutUsOurStory = async (formData) => {
  const res = await api.put(`/abouteUsStorySection/update/1`, formData);
  return res;
};
export const getAboutUsStepCards = async () => {
  const res = await api.get(`/abouteUsStepCard/getById/1`);
  return res;
};
export const updateAboutUsStepCards = async (formData) => {
  const res = await api.put(`/abouteUsStepCard/update/1`, formData);
  return res;
};
export const getAboutUsTeam = async () => {
  const res = await api.get(`/abouteUsTeamSectionNew/getAllTeamSection`);
  return res;
};
export const updateAboutUsTeam = async (formData) => {
  const res = await api.put(`/abouteUsTeamSection/update/2`, formData);
  return res;
};
export const getAboutUsMission = async () => {
  const res = await api.get(`/abouteUsMission/getById/1`);
  return res;
};
export const updateAboutUsMission = async (formData) => {
  const res = await api.put(`/abouteUsMission/update/1`, formData);
  return res;
};

export const getAboutUsTeamParagraph = async () => {
  const res = await api.get(`/abouteUsTeamSection/getAllTeamParagraph`);
  return res;
};

export const updateAboutUsTeamParagraph = async (formData) => {
  const res = await api.put(
    `/abouteUsTeamSection/updateTeamParagraph/1`,
    formData
  );
  return res;
};
