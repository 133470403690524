import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { getAboutUsOurStory } from "../../../api/axios";

export default function OurStoryView() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getAboutUsOurStory();
        setData(result.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <ContentWrapper>
      <HomeTitle>Our Story Section</HomeTitle>
      <TableWrapper>
        {/* Table */}
        <Table>
          <thead>
            <TableRow>
              <TableHeader>Member </TableHeader>
              <TableHeader>Image</TableHeader>
              <TableHeader>Position</TableHeader>
            </TableRow>
          </thead>
          <TableBody>
            {data.map((item) => (
              <>
                <TableRow key={item.id}>
                  <TableCell>{item["name1"]}</TableCell>
                  <TableCell>
                    <img
                      src={item["image1"]}
                      alt={item["name1"]}
                      height="150px"
                    />
                  </TableCell>
                  <TableCell>{item["position1"]}</TableCell>
                </TableRow>
                <TableRow key={item.id}>
                  <TableCell>{item["name2"]}</TableCell>
                  <TableCell>
                    <img
                      src={item["image2"]}
                      alt={item["name2"]}
                      height="150px"
                    />
                  </TableCell>
                  <TableCell>{item["position2"]}</TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
      {/* Button Section */}
      <ButtonWrapper>
        <Link
          to="/aboutus"
          style={{
            width: "20%",
          }}
        >
          <Button variant="secondary">Go Back</Button>
        </Link>
        <Link
          to="/aboutus/ourstory/edit"
          style={{
            width: "20%",
          }}
        >
          <Button>Edit Our Story Section</Button>
        </Link>
      </ButtonWrapper>
    </ContentWrapper>
  );
}

const ContentWrapper = styled.div`
  padding-bottom: 30px;
  justify-content: center;
`;

const HomeTitle = styled.h3`
  text-align: center;
  padding: 10px 0;
`;

const TableWrapper = styled.div`
  margin: 0 auto;
  padding: 10px 0 0 50px;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 10px;
  border: 2px solid #ccc;
`;

const TableBody = styled.tbody``;

const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  padding-top: 40px;
  display: flex;
  gap: 2rem;
  justify-content: center;
`;
