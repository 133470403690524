import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { getAllBusinessParagraph } from "../../api/axios";
const id = 1;

function ForBusiness() {

  const [paragraphid, setParagraphId] = useState(null);
  useEffect(() => {
    fetchParagraphData();
  }, []);

  const fetchParagraphData = async () => {
    try {
      const response = await getAllBusinessParagraph();
      console.log(response.data.data[0])
      if (response.data.data.length > 0) {
        setParagraphId(response.data.data[0].id);
        console.log(response.data.data[0].id);
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ContentWrapper>
      <Title>Select a section</Title>
      <SectionWrapper>
      
        <Link to={`/forbusiness/headerimg/view/${id}`}>
          <Button>Header Paragraph 1 Section</Button>
        </Link>
        <Link to={`/forbusiness/headerparagraphtwo/view/${paragraphid}`}>
          <Button>Header Paragraph 2 Section</Button>
        </Link>
        <Link to={`/forbusiness/card/view/${id}`}>
          <Button>Cards Section</Button>
        </Link>
        <Link to={`/forbusiness/bottomtitle/view/${id}`}>
          <Button>Bottom Section</Button>
        </Link>

        
      </SectionWrapper>
    </ContentWrapper>
  );
}

export default ForBusiness;
const ContentWrapper = styled.div`
  padding:0 50px 30px 50px;
`;

const Title = styled.h3`
  text-align: center;
  padding: 10px 0;
`;

const SectionWrapper = styled.div`
  /* justify-content: center;
  align-items: center;
  gap: 12px; */
  display: flex;
  gap: 6rem;
  justify-content: center;
  padding-top: 5rem;
`;
