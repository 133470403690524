import React,{useEffect,useState} from "react";
import { Button} from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { getForIndividualsPara } from "../../../api/axios";
const id = 2;

export default function ViewPara() {

 const [data, setPara]  = useState ([]);

  useEffect(() => {
    
    getForIndividualsPara().then((res) => {
      console.log(res);
        const paraData = res.data.data[0];
        setPara({ ...paraData, index: res.data.data.indexOf(paraData) });             
        console.log(paraData.headerImage);                     
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <ContentWrapper>
      <HomeTitle>Header Section</HomeTitle>
      <TableWrapper>
        <Table>
          <thead>
            <TableRow>
              <TableHeader>Paragraph</TableHeader>
              <TableHeader>Header Image</TableHeader>
              
            </TableRow>
          </thead>
          <TableBody>
            <TableRow>
              <TableCell>{data.paragraph}</TableCell>
              <TableCell><img src={data.headerImage} alt="headerImage" width="650" height="250" /></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableWrapper>
      <ButtonWrapper>
        <Link to="/forindividuals">
          <Button variant="secondary">Go Back</Button>
        </Link>
        
        <Link to={`/forindividuals/para/edit/${id}`}>
          <Button>Edit header section</Button>
        </Link>
      </ButtonWrapper>
    </ContentWrapper>
  );
}

const ContentWrapper = styled.div`
  padding-bottom: 30px;
  justify-content: center;
`;

const HomeTitle = styled.h3`
  text-align: center;
  padding: 10px 0;
`;

const TableWrapper = styled.div`
  margin: 0 auto;
  padding: 10px 0 0 50px;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 10px;
  border: 2px solid #ccc;
`;

const TableBody = styled.tbody``;

const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  padding-top: 40px;
  display: flex;
  gap: 5rem;
  justify-content: center;
`;
