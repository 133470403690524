import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import {
  getAllHomeHeader,
  getAllHomeOverview,
  getAllHomeParagraph,
  getAllHomeSlider1,
  getAllHomeSlider2,
} from "../../api/axios";
//import { getAllHomeHeader, getAllHomeOverview, getAllHomeParagraph, getAllHomeSlider1, getAllHomeSlider2 } from '../../api/axios';

export default function Home() {
  const [headerid, setHeaderId] = useState(null);
  const [slideroneid, setSliderOneId] = useState(null);
  const [slidertwoid, setSliderTwoId] = useState(null);
  const [paragraphid, setParagraphId] = useState(null);
  const [overviewid, setOverviewId] = useState(null);

  // useEffect hook to fetch the ID from the API
  useEffect(() => {
    fetchHeaderData();
    fetchSilderOneData();
    fetchSilderTwoData();
    fetchParagraphData();
    fetchOverviewData();
  }, []);

  const fetchHeaderData = async () => {
    try {
      const response = await getAllHomeHeader();
      console.log(response.data.data[0]);
      // const data = await response.json();
      if (response.data.data.length > 0) {
        setHeaderId(response.data.data[0].header_id);
        console.log(response.data.data[0]);
        console.log(response.data.data[0].header_id);
      } else {
        console.log(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSilderOneData = async () => {
    try {
      const response = await getAllHomeSlider1();
      if (response.data.data.length > 0) {
        setSliderOneId(response.data.data[0].sliderOne_id);
        console.log(response.data.data[0]);
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSilderTwoData = async () => {
    try {
      const response = await getAllHomeSlider2();
      if (response.data.data.length > 0) {
        setSliderTwoId(response.data.data[0].sliderTwo_id);
        console.log(response.data.data[0]);
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchParagraphData = async () => {
    try {
      const response = await getAllHomeParagraph();
      if (response.data.data.length > 0) {
        setParagraphId(response.data.data[0].paragraph_id);
        console.log(response.data.data[0]);
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchOverviewData = async () => {
    try {
      const response = await getAllHomeOverview();
      if (response.data.data.length > 0) {
        setOverviewId(response.data.data[0].service_id);
        console.log(response.data.data[0]);
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    // <Layout>
    <ContentWrapper>
      <Title>Select a section</Title>
      <SectionWrapper>
        <Link to={`/home/header/view/${headerid}`}>
          <Button>Header Section</Button>
        </Link>
        <Link to={`/home/slider1/view/${slideroneid}`}>
          <Button>Slider 1 Section</Button>
        </Link>
        <Link to={`/home/slider2/view/${slidertwoid}`}>
          <Button>Slider 2 Section</Button>
        </Link>
        <Link to={`/home/paragraph/view/${paragraphid}`}>
          <Button>Paragraph Section</Button>
        </Link>
        <Link to={`/home/overview/view/${overviewid}`}>
          <Button>Service Overview Section</Button>
        </Link>
      </SectionWrapper>
      {/* </Layout> */}
      </ContentWrapper>
  );
}

const ContentWrapper = styled.div`
  padding-bottom: 30px;
`;

const Title = styled.h3`
  text-align: center;
  padding: 10px 0;
`;

const SectionWrapper = styled.div`
  /* justify-content: center;
  align-items: center;
  gap: 12px; */
  display: flex;
  gap: 6rem;
  justify-content: center;
  padding-top: 5rem;
`;
