import React from "react";
import { Outlet } from "react-router-dom";
import Layout from "../../Sidebar/Layout.js";

const WithNav = () => {
  return (
    <>
      <Layout>
        <Outlet />
      </Layout>
    </>
  );
};

export default WithNav;
