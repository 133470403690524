import React, { useState } from "react";

import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import DetailsModal from "../Common/DetailsModal";

// import Header from "../Header/Header";
import { NavData } from "../Header/NavData";
import Header from "../Header/NewHeader";

export default function Sidebar() {
  //   const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const ButtonItem = [
    {
      name: "Yes",
      onClick: () => {
        localStorage.clear();
        setOpen(false);
        navigate("/");
      },
    },
    {
      name: "No",
      onClick: () => {
        setOpen(false);
      },
    },
  ];

  const handleClose = () => {
    setOpen(!open);
  };
  return (
    <>
      <Wrapper className="scroll3">
        <Top>
          <NavLink>
            <Image>
              <img
                src={require("../../Assets/pawprint-icon.png")}
                alt="logo"
                style={{
                  borderBottom: "1px solid white",
                  paddingLeft: "20px",
                }}
              />
            </Image>
          </NavLink>
        </Top>
        <MenuWrapper>
          {NavData.map((item, index) => (
            <NavLink
              to={item.link}
              activeClassName="active"
              key={index}
              // style={item?.icon ? { justifyContent: "start" } : {}}
            >
              <MenuItem>
                <h1 style={{ margin: "0px", padding: "10px" }}>
                  {item.title}
                </h1>
              </MenuItem>
            </NavLink>
          ))}
          <MenuItem onClick={() => setOpen(true)}>
            <h1 style={{ margin: "0px", padding: "10px" }}>Log Out</h1>
          </MenuItem>
        </MenuWrapper>
        <DetailsModal
          label="Do you want to logout?"
          open={open}
          buttonList={ButtonItem}
          severity="warning"
          handleClose={handleClose}
        />
      </Wrapper>
      <Header />
    </>
  );
}

const Top = styled.div`
  display: block;
  margin: auto;
  align-items: center;
  justify-content: center;
`;
const Image = styled.div`
  img {
    width: 219px;
    padding-bottom: 10px;
    @media (max-width: 1130px) {
      width: 180px;
    }
  }
`;

const Wrapper = styled.div`
  ${"" /* margin: 5px 0px 5px 5px; */}
  // padding: 10px;
  width: 330px;
  height: 100vh;
  // position: absolute;
  display: grid;
  grid-template-rows: 1.5fr 10fr;
  background: rgb(252, 132, 51);
  position: sticky;
  top: 0;
  left: 0;
  @media (max-width: 1130px) {
    width: 330px;
  }
  @media (max-width: 890px) {
    display: none;
  }
`;

const MenuWrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 5px 0px;
  // display: grid;
  // grid-template-rows: auto;
  text-align: center;
  padding: 20px 0px 30px 15px;
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px #ffffff;
    background-color: rgb(252, 132, 51);
  }

  h1 {
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: white;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    display: grid;
    align-items: center;
    justify-content: center;
  }
  a {
    text-decoration: none;
  }
  a.active h1 {
    color: black;
  }
  .active {
    height: 48px;
    // width: 267px;
    position: relative;
    background: #fff;
    border-radius: 20px 0px 0px 20px;
    text-align: center;
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 5px;

    ::before,
    ::after {
      content: "";
      width: 20px;
      height: 20px;
      right: 0;
      position: absolute;
    }
    ::before {
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><path fill="white" fill-opacity="1" d="M0 0 Q20 0 20 20 L20 0Z" /></svg>');
      bottom: -20px;
    }
    ::after {
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><path fill="rgb(255, 255, 255)" d="M0 20 Q20 20 20 0 L20 20Z" /></svg>');
      top: -20px;
    }
  }
`;
const MenuItem = styled.div`
  width: 240px;
  color: rgba(255, 255, 255, 0.7);
  align-items: center;
  padding: 0px;
  transition: 0.5s ease-out;
  border-radius: 10px;
  justify-items: center;
  border: 1px solid transparent;
  border-radius: 50px;
  :hover {
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(0, 0, 0);
    border-radius: 10px;
    padding: 0px 0px;
    color: black;
    transition-duration: 2s;
  }
`;
