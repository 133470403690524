import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { getHomeHeader, updateHomeHeader } from "../../../api/axios";
import { Alert } from "react-bootstrap";

export default function HeaderEdit() {
  const { id } = useParams();

  const [header_paragraph_1, setHeaderParagraph1] = useState("");
  const [header_paragraph_2, setHeaderParagraph2] = useState("");
  const [header_paragraph_3, setHeaderParagraph3] = useState("");
  const [header_paragraph_4, setHeaderParagraph4] = useState("");
  const [header_image, setHeaderImage] = useState("");
  const [header, setHeader] = useState({
    header_paragraph_1: "",
    header_paragraph_2: "",
    header_paragraph_3: "",
    header_paragraph_4: "",
    header_image: "",
    index: null,
  });
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    getHomeHeader(id)
      .then((response) => {
        const headerData = response.data.data[0];
        setHeader({
          ...headerData,
          index: response.data.data.indexOf(headerData),
        });
        console.log(response.data.data[0].header_paragraph_1);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  const SendData = (e) => {
    e.preventDefault();
    setSaving(true);

    var formData = new FormData();
    formData.append("header_paragraph_1", header_paragraph_1);
    console.log(header_paragraph_1);
    formData.append("header_paragraph_2", header_paragraph_2);
    formData.append("header_paragraph_3", header_paragraph_3);
    formData.append("header_paragraph_4", header_paragraph_4);

    if (header_image instanceof File) {
      formData.append("header_image", header_image);
    }

    updateHomeHeader(id, formData)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          //    alert("Blog has been updated successfully");
          // toast.success('Role is successfully updated.')
          setShowSuccessMessage(true);
          //   navigate(`/home/header/view/${id}`);
        } else {
          Promise.resolve();
        }
        e.target.reset();
        setSaving(false);
      })
      .catch((err) => {
        console.log(err);
        setSaving(false);
      });
  };

  return (
    <FormWrapper>
      <Form>
        <Title>Edit header section</Title>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label style={{ fontWeight: "bold" }}>Enter a new part 1</Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            defaultValue={header.header_paragraph_1}
            onChange={(e) => setHeaderParagraph1(e.target.value)}
          />
        </Form.Group><br/>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label style={{ fontWeight: "bold" }}>Enter a new part 2</Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            defaultValue={header.header_paragraph_2}
            onChange={(e) => setHeaderParagraph2(e.target.value)}
          />
        </Form.Group><br/>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label style={{ fontWeight: "bold" }}>Enter a new part 3</Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            defaultValue={header.header_paragraph_3}
            onChange={(e) => setHeaderParagraph3(e.target.value)}
          />
        </Form.Group><br/>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label style={{ fontWeight: "bold" }}>Enter a new part 4</Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            defaultValue={header.header_paragraph_4}
            onChange={(e) => setHeaderParagraph4(e.target.value)}
          />
        </Form.Group><br/>
        <Form.Group
          controlId="formBasicName"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Form.Label style={{ fontWeight: "bold" }}>Select a new Image</Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            onChange={(e) => {
              setHeaderImage(e.target.files[0]);
            }}
            style={{ display: "none" }}
            ref={inputRef}
          />
          {header_image ? (
            <>
              <img
                style={{ Width: "15vw" }}
                src={URL.createObjectURL(header_image)}
                alt="header_image"
                onClick={() => {
                  setHeaderImage.current.click();
                }}
              />

              <br />
              <RemoveButtonWrapper>
                <Button
                  variant="outline-danger"
                  onClick={() => setHeaderImage(null)}
                >
                  Remove your image
                </Button>
              </RemoveButtonWrapper>
            </>
          ) : (
            <img
              style={{ Width: "15vw", cursor: "pointer" }}
              src={header.header_image}
              alt="header_image"
              onClick={() => {
                inputRef.current.click();
              }}
              onMouseEnter={(e) => {
                e.target.style.opacity = 0.8;
              }}
              onMouseLeave={(e) => {
                e.target.style.opacity = 1;
              }}
            />
          )}
        </Form.Group>
        <br />
        <ButtonWrapper>
          <Link to={`/home/header/view/${id}`}>
            <Button variant="secondary">Go Back</Button>
          </Link>
          <Button
            variant="primary"
            type="submit"
            onClick={SendData}
            disabled={saving}
          >
            {saving ? "Saving..." : "Save Changes"}
          </Button>
        </ButtonWrapper>
      </Form>
      {showSuccessMessage && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 100,
            }}
          ></div>
          <Alert
            variant="success"
            onClose={() => setShowSuccessMessage(false)}
            dismissible={false}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "25vw",
              height: "auto",
              zIndex: 1000,
            }}
          >
            <Alert.Heading
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "green",
                textAlign: "center",
              }}
            >
              Update Successfull!
            </Alert.Heading>

            <p style={{ textAlign: "center" }}>
              Your header section has been updated.
            </p>
            <br />
            <div className="d-flex justify-content-end">
              <Button
                variant="outline-success"
                onClick={() => {
                  setShowSuccessMessage(false);
                  navigate(`/home/header/view/${id}`);
                }}
              >
                Ok
              </Button>
            </div>
          </Alert>
        </>
      )}
    </FormWrapper>
  );
}

const FormWrapper = styled.div`
  padding: 0rem 22rem 1rem 22rem;
`;

const Title = styled.h3`
  text-align: center;
  padding: 10px 0;
`;

const RemoveButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 50%;
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
`;
